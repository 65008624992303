import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';

import FilterIcon from '@material-ui/icons/FilterList';
import CloseIcon from '@material-ui/icons/Close';
import ExportIcon from '../../assets/icons/DownloadIcon';
import BillingIcon from '../../assets/icons/CaseReportingIcon';

import Table from '../shared/table/Table';
import ActionButton from '../shared/ActionButton';

import { exportBilling } from '../../actions/casesActions';

import { setAllocationStatuses } from '../../constants/enums';

const columns = [
  { title: 'date', field: 'date', type: 'date', sort: true },
  { title: 'surgeon', field: 'surgeonName', type: 'default', sort: true, filter: true, },
  { title: 'hospital', field: 'hospitalName', type: 'default', sort: true, filter: true, },
  { title: 'procedure', field: 'procedureName', type: 'default', sort: true, filter: true },
  { title: 'value', field: 'proformaValue', type: 'default', sort: true },
  { title: 'Freight Cost', field: 'freightCost', type: 'default', sort: true },
  { title: 'closed', field: 'orderClosedAt', type: 'date', sort: true },
  { title: 'reference', field: 'orderReference', type: 'default', sort: true },
  {
    title: '',
    field: 'preview',
    type: 'custom',
    formatter: (value) => {
      if (value && value?.length) {
        return (
          <div className='d-flex'>
            <div className='count-image-preview'>
              <img src={value} alt='preview' />
            </div>
          </div>
        );
      }
    }
  }
];

const isImage = (fileName = '') => {
  const arr = fileName.split('.');
  const extension = arr.length ? arr[arr.length - 1] : '';
  return ['jpg', 'jpeg', 'png', 'svg', 'ico'].includes(extension);
};

const BillingReportingTable = (props) => {
  const {
    cases,
    onRowClick,
    surgeons = [],
    hospitals = [],
    procedures = [],
    filter,
    onRemoveFilter,
    filterOptions = [],
    onFilterChange,
  } = props;

  const dispatch = useDispatch();

  const [exportLoading, setExportLoading] = useState(false);

  const getName = (field, id) => {
    let list = [];

    if (field === 'surgeonName') {
      list = surgeons;
    } else if (field === 'hospitalName') {
      list = hospitals;
    } else if (field === 'procedureName') {
      list = procedures;
    }

    const value = list?.find((item) => item.id === id);

    if (value) {
      return field === 'surgeonName' ? `${value?.firstName} ${value?.lastName}` : value.name;
    }

    return '';
  };

  const getSurgeonName = useCallback((surgeonId) => {
    const caseSurgeon = surgeons.find((surgeon) => surgeon.id === surgeonId);
    return caseSurgeon ? `${caseSurgeon.title} ${caseSurgeon.firstName} ${caseSurgeon.lastName}` : '';
  }, [cases, surgeons]);

  const getHospitalName = useCallback((hospitalId) => {
    const caseHospital = hospitals.find((hospital) => hospital.id === hospitalId);
    return caseHospital && caseHospital.name ? caseHospital.name : '';
  }, [cases, hospitals]);

  const getProcedureName = useCallback((procedureId) => {
    const procedure = procedures.find((procedure) => procedure.id === procedureId);
    return procedure ? procedure.name : '';
  }, [procedures]);

  const getProformaValue = useCallback((value) => {
    if (!value || !Number(value)) {
      return '';
    }

    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    });

    return formatter.format(value);
  }, [cases]);

  const onExportClick = async () => {
    setExportLoading(true);
    await dispatch(exportBilling(cases?.map((item) => item.id)));
    setExportLoading(false);
  };

  return (
    <div className='cases-table__container'>
      <div className='d-flex m-b-lg space-between align-start'>
        <div className='d-flex'>
          <BillingIcon />
          <div className='font-size-bg font-bold m-l-md'>
            Billing
          </div>
        </div>

        <ActionButton
          onClick={onExportClick}
          bgColor={setAllocationStatuses.SHIPPED.color}
          disabled={exportLoading}
          loading={exportLoading}
        >
          <ExportIcon color="#ffffff" />
          Export
        </ActionButton>
      </div>

      {filter && Object.keys(filter).some((key) => filter[key].length) && (
        <div className='filters-list__container'>
          <FilterIcon />

          {Object.keys(filter).map((key) => (
            <div key={key} className='d-flex'>
              {filter[key].map((value) => (
                <div key={`${key}-${value}`} className='filters-list__item'>
                  <div />
                  <div className='filter-list-item-value'>{getName(key, value)}</div>
                  <CloseIcon color='secondary' onClick={() => onRemoveFilter(key, value)} />
                </div>
              ))}
            </div>
          ))}
        </div>
      )}

      <Table
        rows={cases.map((item) => ({
          ...item,
          surgeonName: getSurgeonName(item.surgeon),
          hospitalName: getHospitalName(item.hospital),
          procedureName: getProcedureName(item.procedure),
          proformaValue: getProformaValue(item.proformaValue),
          freightCost: getProformaValue(item.freightCost),
          preview: item.orderAttachments?.length ? item.orderAttachments?.map((attachment) => attachment && isImage(attachment.fileName) ? attachment.downloadUrl : null) : [],
        }))}
        columns={columns}
        onRowClick={onRowClick}
        pagination
        checkboxes={false}
        withoutCheck
        rowColor='#ffffff'
        rowBorderColor='#fafafa'
        currentFilter={filter}
        filterOptions={filterOptions}
        onFilterChange={onFilterChange}
      />
    </div>
  );
};

export default BillingReportingTable;
