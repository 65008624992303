import React from 'react';

import DocumentIcon from '../assets/icons/DocumentIcon';
import VideoIcon from '../assets/icons/VideoIcon';
import LinkIcon from '../assets/icons/LinkIcon';

import { buildParentArray } from './utils';

import { driveItemTypes } from '../constants/drive';

const getFilePath = (fileId, folders, parentId, tenantId, fileName) => {
  const arr = buildParentArray(folders, parentId);
  return `${tenantId}/root/${arr.map((folder) => folder.id).join('/')}/${fileId}/${fileName}`;
};

const getFileIcon = (type, fontSize = 24, outlined, color = '#000000') => {
  switch (type) {
    case driveItemTypes.document:
      return outlined ? <DocumentIcon style={{ fontSize }} color="#ffffff" /> : <DocumentIcon style={{ fontSize }} color={color} />;
    case driveItemTypes.video:
      return outlined ? <VideoIcon style={{ fontSize }} color="#ffffff" /> : <VideoIcon style={{ fontSize }} color={color} />;
    case driveItemTypes.link:
      return outlined ? <LinkIcon style={{ fontSize }} color="#ffffff" /> : <LinkIcon style={{ fontSize }} color={color} />;
    default:
      return outlined ? <DocumentIcon style={{ fontSize }} color="#ffffff" /> : <DocumentIcon style={{ fontSize }} color={color} />;
  }
};

const getFileLabel = (type) => {
  switch (type) {
    case driveItemTypes.document:
      return 'Document';
    case driveItemTypes.video:
      return 'Video';
    case driveItemTypes.link:
      return 'Link';
    default:
      return 'Document';
  }
};

export {
  getFilePath,
  getFileIcon,
  getFileLabel,
};
