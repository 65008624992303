import { sortBy } from 'lodash';

import { GET_SETS, SET_SETS, SET_QUARANTINED_SETS, SET_LABELS, UPDATE_SET_ALLOCATION } from '../actions/actionTypes';

const initialState = {
  list: [],
  quarantined: [],
  labels: [],
  isLoading: false,
  isLoaded: false
};

const setsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_SETS:
      return { ...state, isLoading: true };
    case SET_SETS:
      return {
        ...state,
        list: sortBy(action.sets, ['number']),
        isLoaded: true,
        isLoading: false
      };
    case SET_QUARANTINED_SETS:
      return {
        ...state,
        quarantined: action.sets?.filter((item) => !!item.quarantined),
      };
    case SET_LABELS:
      return {
        ...state,
        labels: action.labels || [],
      };
    case UPDATE_SET_ALLOCATION:
      const copy = [...state?.list] || [];
      const updatedSet = copy.find((s) => s.id === action?.id);
      updatedSet.caseAllocation = action?.caseAllocation;
      return {
        ...state,
        list: copy,
      }
    default:
      return state;
  }
};

export default setsReducer;
