const driveItemTypes = Object.freeze({
  folder: 'FOLDER',
  document: 'DOCUMENT',
  video: 'VIDEO',
  link: 'LINK',
});

export {
  driveItemTypes,
};
