import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { sortBy } from 'lodash';

import {
  FlowsActions,
  FlowsList,
  FlowForm,
  FlowStepForm,
  FlowStepsList,
  FlowBreadcrumbs,
} from '../../../components/flows';
import Input from '../../../components/shared/Input';
import Modal, { ConfirmationModal } from '../../../components/shared/modal';

import {
  subscribeToFlows,
  createFlow,
  updateFlow,
  deleteFlow,
  getSteps,
  createStep,
  updateStep,
  deleteStep,
  reorderSteps,
} from '../../../actions/flowsActions';
import { getProcedures } from '../../../actions/proceduresActions';

import { useLoading, useAlert } from '../../../hooks';

import { roleNames } from '../../../constants/userRoles';

const FlowsPage = () => {
  const dispatch = useDispatch();
  const tenant = useSelector((state) => state.tenant.currentTenant);
  const flows = useSelector((state) => state.flows.list);
  const procedures = useSelector((state) => state.procedures.list);
  const deletePermission = useSelector((state) => state.user?.currentUser?.deletePermission);

  const { loading, startLoading, stopLoading } = useLoading(false);
  const { loading: deleting, startLoading: startDeleting, stopLoading: stopDeleting } = useLoading(false);
  const { showAlert } = useAlert();

  const [search, setSearch] = useState('');

  const [selectedFlow, selectFlow] = useState(null);
  const [selectedStep, selectStep] = useState(null);
  const [steps, setSteps] = useState([]);

  const [flowModal, toggleFlowModal] = useState(false);
  const [stepModal, toggleStepModal] = useState(false);
  const [deleteFlowModal, toggleDeleteFlowModal] = useState(false);
  const [deleteStepModal, toggleDeleteStepModal] = useState(false);

  const [fetchingSteps, setFetchingSteps] = useState(false);

  useEffect(() => {
    const unsubscribe = dispatch(subscribeToFlows());

    onLoad().catch((err) => console.error(err));

    return () => {
      unsubscribe();
    };
  }, []);

  useEffect(() => {
    if (selectedFlow) {
      fetchFlowSteps(selectedFlow?.id).catch((err) => console.error(err));
    } else {
      setSteps([]);
    }
  }, [selectedFlow]);

  const fetchFlowSteps = async (flowId, withoutLoading) => {
    if (!withoutLoading) {
      setFetchingSteps(true);
    }

    try {
      const steps = await dispatch(getSteps(flowId));
      setSteps(sortBy(steps, ['stepNumber']));
    } catch (err) {
      console.error(err);
    } finally {
      setFetchingSteps(false);
    }
  };

  const onLoad = async () => {
    try {
      await Promise.all([
        dispatch(getProcedures()),
      ]);
    } catch (err) {
      console.error(err);
    }
  };

  const onAddClick = () => {
    if (selectedFlow) {
      toggleStepModal(true);
    } else {
      toggleFlowModal(true);
    }
  };

  const onSubmitFlow = async (formObj, mode) => {
    startLoading();

    const flowObj = {
      name: formObj.name,
      subtitle: formObj.subtitle,
      procedures: [],
    };

    if (mode === 'update') {
      flowObj.active = !!formObj.active;
      flowObj.procedures = formObj.procedures || [];
    }

    try {
      if (mode === 'update') {
        await dispatch(updateFlow(formObj.id, flowObj));
        selectFlow(formObj)
      } else {
        await dispatch(createFlow(flowObj));
      }
      stopLoading();
      toggleFlowModal(false);
      showAlert('success', `Flow has been successfully ${mode === 'update' ? 'updated' : 'created'}`);
    } catch (err) {
      console.error(err);
    } finally {
      stopLoading();
    }
  };

  const onDeleteFlow = async () => {
    startDeleting();

    try {
      await Promise.all(steps.map((step) => dispatch(deleteStep(selectedFlow.id, step.id))));
      await dispatch(deleteFlow(selectedFlow.id));
      toggleDeleteFlowModal(false);
      selectFlow(null);
      selectStep(null);
      showAlert('success', 'Flow has been successfully deleted');
    } catch (err) {
      console.error(err);
    } finally {
      stopDeleting();
    }
  };

  // Steps

  const onAddStep = async (formObj) => {
    startLoading();

    const stepObj = {
      stepNumber: steps?.length + 1,
      title: formObj.title || '',
      subtitle: formObj.subtitle || '',
      description: formObj.description || '',
      notesField: !!formObj.notesField,
      notesFieldRequired: !!formObj.notesFieldRequired,
      documentUpload: !!formObj.documentUpload,
      documentUploadRequired: !!formObj.documentUploadRequired,
      imageCapture: !!formObj.imageCapture,
      imageCaptureRequired: !!formObj.imageCaptureRequired,
      urlLink: !!formObj.urlLink,
      urlLinkRequired: !!formObj.urlLinkRequired,
      openInBrowser: !!formObj.openInBrowser,
      dueDate: Number(formObj?.dueDate) || 0,
      allRoles: formObj?.allRoles || false,
      roles: formObj?.allRoles ? Object.values(roleNames) : formObj?.roles,
      allRolesRead: formObj?.allRolesRead || false,
      readAccess: formObj?.allRolesRead ? Object.values(roleNames) : formObj?.readAccess,
    };

    try {
      await dispatch(createStep(selectedFlow?.id, stepObj));
      await fetchFlowSteps(selectedFlow?.id, true);
      stopLoading();
      toggleStepModal(false);
      showAlert('success', 'Step has been successfully created');
    } catch (err) {
      console.error(err);
    } finally {
      stopLoading();
    }
  };

  const onUpdateStep = async (formObj) => {
    startLoading();

    const stepObj = {
      active: !!formObj.active,
      title: formObj.title || '',
      subtitle: formObj.subtitle || '',
      description: formObj.description || '',
      notesField: !!formObj.notesField,
      notesFieldRequired: !!formObj.notesFieldRequired,
      documentUpload: !!formObj.documentUpload,
      documentUploadRequired: !!formObj.documentUploadRequired,
      imageCapture: !!formObj.imageCapture,
      imageCaptureRequired: !!formObj.imageCaptureRequired,
      urlLink: !!formObj.urlLink,
      urlLinkRequired: !!formObj.urlLinkRequired,
      openInBrowser: !!formObj.openInBrowser,
      dueDate: Number(formObj?.dueDate) || 0,
      allRoles: formObj?.allRoles || false,
      roles: formObj?.allRoles ? Object.values(roleNames) : formObj?.roles,
      allRolesRead: formObj?.allRolesRead || false,
      readAccess: formObj?.allRolesRead ? Object.values(roleNames) : formObj?.readAccess,
    };

    try {
      await dispatch(updateStep(selectedFlow?.id, formObj.id, stepObj));
      await fetchFlowSteps(selectedFlow?.id, true);
      selectStep({
        ...selectedStep,
        ...stepObj,
      });
      stopLoading();
      showAlert('success', 'Step has been successfully updated');
    } catch (err) {
      console.error(err);
    } finally {
      stopLoading();
    }
  };

  const onDeleteStep = async () => {
    startDeleting();

    try {
      await dispatch(deleteStep(selectedFlow.id, selectedStep.id));
      await Promise.all(
        steps
          .filter((step) => step.stepNumber > selectedStep.stepNumber)
          .map((step) => dispatch(updateStep(selectedFlow.id, step.id, { stepNumber: step.stepNumber - 1 })))
      );
      await fetchFlowSteps(selectedFlow.id, true);
      toggleDeleteStepModal(false);
      selectStep(null);
      showAlert('success', 'Step has been successfully deleted');
    } catch (err) {
      console.error(err);
    } finally {
      stopDeleting();
    }
  };

  const onDeleteClick = () => {
    if (selectedStep) {
      return toggleDeleteStepModal(true);
    }

    if (selectedFlow) {
      return toggleDeleteFlowModal(true);
    }
  };

  const renderUpdateForm = () => {
    if (selectedStep) {
      return (
        <FlowStepForm
          initialValues={selectedStep}
          onSubmit={onUpdateStep}
          mode='update'
          buttonText='Save Changes'
          loading={loading}
        />
      );
    }

    if (selectedFlow) {
      return (
        <FlowForm
          initialValues={selectedFlow}
          onSubmit={onSubmitFlow}
          mode='update'
          loading={loading}
          procedures={procedures}
        />
      );
    }

    return null;
  };

  const onReorder = async (steps) => {
    dispatch(reorderSteps(selectedFlow.id, steps));
    setSteps(steps);
  };

  return (
    <div className='settings-cmp__main flex-1'>
      <span className='settings-title'>Flows</span>
      <div className='settings-cmp__body flex-1'>
        <div className='settings-block__left'>
          <FlowsActions
            onAddClick={onAddClick}
            onDeleteClick={onDeleteClick}
            selectedFlow={selectedFlow}
            deletePermission={deletePermission}
          />
          <FlowsList
            flows={flows}
            onClick={(flow) => {
              selectFlow(flow);
              selectStep(null);
            }}
            tenantColor={tenant.colorPrimary}
            selectedFlow={selectedFlow}
          />
        </div>
        <div className='settings-block__middle'>
          <FlowBreadcrumbs
            selectedFlow={selectedFlow}
            selectedStep={selectedStep}
            tenantColor={tenant.colorPrimary}
          />
          <FlowStepsList
            loading={fetchingSteps}
            steps={steps}
            onClick={selectStep}
            selectedStep={selectedStep}
            tenantColor={tenant.colorPrimary}
            onReorder={onReorder}
          />
        </div>
        <div className='settings-block__right'>
          <Input
            type='search'
            placeholder='Search'
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
          {renderUpdateForm()}
        </div>
      </div>

      <Modal
        open={!!flowModal}
        onClose={() => toggleFlowModal(null)}
      >
        <FlowForm
          onSubmit={onSubmitFlow}
          onClose={() => toggleFlowModal(null)}
          mode='create'
          buttonText='Add Flow'
          loading={loading}
        />
      </Modal>

      <Modal
        open={!!stepModal}
        onClose={() => toggleStepModal(null)}
      >
        <FlowStepForm
          onSubmit={onAddStep}
          onClose={() => toggleStepModal(null)}
          mode='create'
          buttonText='Add Step'
          loading={loading}
        />
      </Modal>

      <ConfirmationModal
        open={!!deleteFlowModal}
        onClose={() => toggleDeleteFlowModal(false)}
        onSubmit={onDeleteFlow}
        title='Are you sure you want to delete this flow?'
        submitText='Delete'
        loading={deleting}
      />

      <ConfirmationModal
        open={!!deleteStepModal}
        onClose={() => toggleDeleteStepModal(false)}
        onSubmit={onDeleteStep}
        title='Are you sure you want to delete this step?'
        submitText='Delete'
        loading={deleting}
      />
    </div>
  );
};

export default FlowsPage;
