import React, { useCallback } from 'react';
import moment from 'moment';

import Table from '../shared/table/TableRemote';

import { setAllocationStatuses, caseUsageTypes } from '../../constants/enums';

const { ASSIGNED, SHIPPED, RETURNED, TRANSFERRED, USAGE } = setAllocationStatuses;

const columns = [
  { title: 'case date', field: 'date', type: 'date', sort: true },
  { title: 'surgeon', field: 'surgeonName', type: 'default', sort: true },
  { title: 'hospital', field: 'hospitalName', type: 'default', sort: true },
  { title: 'patient ref', field: 'patientReference', type: 'default', sort: true },
  { title: 'approved', field: 'approved', type: 'colored', sort: false },
  { title: '% approved', field: 'approveProgress', type: 'progress', sort: false },
  { title: 'status', field: 'approveStatus', type: 'colored', sort: false },
  { title: 'resolved', field: 'resolved', type: 'colored', sort: false },
  { title: 'proforma', field: 'proformaStatus', type: 'colored', sort: false },
  { title: 'value', field: 'proformaValue', type: 'default', sort: true },
  { title: 'closed', field: 'orderClosedAt', type: 'date', sort: true },
  { title: 'reference', field: 'orderReference', type: 'default', sort: true },
  {
    title: '',
    field: 'preview',
    type: 'custom',
    formatter: (value) => {
      if (value && value?.length) {
        const attachments = value?.map((attachment) => attachment && isImage(attachment.fileName) ? attachment.downloadUrl : null);
        return <div className="d-flex">
          {attachments?.filter((url) => !!url).map((url) => (
            <div className="count-image-preview">
              <img src={url} alt="preview" />
            </div>
          ))}
        </div>;
      }
    }
  }
];

const isImage = (fileName = '') => {
  const arr = fileName.split('.');
  const extension = arr.length ? arr[arr.length - 1] : '';
  return ['jpg', 'jpeg', 'png', 'svg', 'ico'].includes(extension);
};

const UsageTable = (props) => {
  const {
    cases,
    onRowClick,
    surgeons,
    hospitals,
    order,
    setOrder,
    orderBy,
    setOrderBy,
    page,
    setPage,
    rowsPerPage,
    setRowsPerPage
  } = props;

  const getSurgeonName = useCallback((surgeonId) => {
    const caseSurgeon = surgeons.find((surgeon) => surgeon.id === surgeonId);
    return caseSurgeon ? `${caseSurgeon.title} ${caseSurgeon.firstName} ${caseSurgeon.lastName}` : '';
  }, [cases, surgeons]);

  const getHospitalName = useCallback((hospitalId) => {
    const caseHospital = hospitals.find((hospital) => hospital.id === hospitalId);
    return caseHospital && caseHospital.name ? caseHospital.name : '';
  }, [cases, hospitals]);

  const getTransferredSets = useCallback((allocations) => {
    const transferred = allocations.filter((item) => item.status === TRANSFERRED.value);
    return { value: transferred.length, color: ASSIGNED.color };
  }, [cases]);

  const getReturnedSets = useCallback((allocations) => {
    const total = allocations.filter((item) => [ASSIGNED.value, SHIPPED.value, RETURNED.value].includes(item.status));
    const returned = allocations.filter((item) => item.status === RETURNED.value);
    return { value: returned.length / total.length * 100, color: RETURNED.color };
  }, [cases]);

  const getApproved = useCallback((usage) => {
    const implants = usage.filter((item) => item.type === caseUsageTypes.implants);
    const total = implants.length;
    const approved = implants.filter((item) => !!item.approved).length;

    if (!total) {
      return '';
    }

    return { value: `${approved} / ${total}`, color: total > 0 && total === approved ? SHIPPED.color : TRANSFERRED.color};
  }, [cases]);

  const getResolved = useCallback((allocations) => {
    const total = allocations.length;
    const resolved = allocations.filter((item) => [RETURNED.value, USAGE.value, TRANSFERRED.value].includes(item.status)).length;

    if (!total) {
      return '';
    }

    return { value: `${resolved} / ${total}`, color: resolved < total ? TRANSFERRED.color : USAGE.color };
  }, [cases]);

  const getProgress = useCallback((usage) => {
    const implants = usage.filter((item) => item.type === caseUsageTypes.implants);
    const total = implants.length;
    const approved = implants.filter((item) => !!item.approved).length;

    return { value: approved / total * 100, color: total > 0 && total === approved ? SHIPPED.color : TRANSFERRED.color};
  }, [cases]);

  const getStatus = useCallback((caseObj, usage = []) => {
    const implants = usage.filter((item) => item.type === caseUsageTypes.implants);
    const total = implants.length;
    const approved = implants.filter((item) => !!item.approved).length;

    if (caseObj.orderClosed) {
      return { value: 'Closed', color: RETURNED.color };
    }

    if (total === approved) {
      return { value: 'Approved', color: SHIPPED.color };
    }

    return { value: 'Approve', color: TRANSFERRED.color };
  }, [cases]);

  const getProformaStatus = useCallback((caseObj) => {
    if (caseObj.proformaSent) {
      if (!caseObj.proformaSentAt) {
        return { value: 'Sent', color: SHIPPED.color };
      }

      return {
        value: `Sent ${moment(caseObj.proformaSentAt).fromNow()}`,
        color: SHIPPED.color,
      }
    }

    return { value: '', color: '' };
  }, [cases]);

  const getProformaValue = useCallback((value) => {
    if (!value || !Number(value)) {
      return '';
    }

    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    });

    return formatter.format(value);
  }, [cases]);

  return (
    <div style={{ width: '100%' }}>
      <Table
        rows={cases.map((item) => ({
          ...item,
          surgeonName: getSurgeonName(item.surgeon),
          hospitalName: getHospitalName(item.hospital),
          transferredSets: getTransferredSets(item.setsAllocation || []),
          returnedSets: getReturnedSets(item.setsAllocation || []),
          approved: getApproved(item?.usage || []),
          approveProgress: getProgress(item.usage || []),
          approveStatus: getStatus(item,item.usage || []),
          proformaStatus: getProformaStatus(item),
          proformaValue: getProformaValue(item.proformaValue),
          preview: item.orderAttachments?.length ? item.orderAttachments : (item.orderAttachment ? [item.orderAttachment] : []),
          resolved: getResolved(item?.setsAllocation || []),
        }))}
        columns={columns}
        onRowClick={onRowClick}
        pagination
        checkboxes={false}
        order={order}
        setOrder={setOrder}
        orderBy={orderBy}
        setOrderBy={setOrderBy}
        page={page}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
      />
    </div>
  );
};

export default UsageTable;
