import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

import { CaseDateRange } from '../../../components/reporting';
import {
  ManagementReportsForm, StatesPieChart, TypesStackedBarChart, TypesLineChart,
  HospitalsBarChart, UsersBarChart, ProceduresBarChart, SurgeonsBarChart,
  SurgeonsStackedBarChart, SurgeonsProformaBarChart, SurgeonsProcedureStackedBarChart,
  SetsShippedLineChart, SetTurnsBarChart, KitDistributionPieChart, HospitalsSetsBarChart,
  ForecastingCasesLineChart, ForecastingStatesPieChart, ForecastingHospitalsBarChart,
  ForecastingSurgeonsBarChart, ForecastingProceduresPieChart, BillingProformaPieChart,
  BillingHospitalsProformaBarChart, BillingStatesProformaBarChart, FreightCostPieChart,
  FreightCostHospitalsBarChart, CountsSubmittedLineChart, ExpiringItemsPieChart
} from '../../../components/reporting/management-reports';
import LoadScreen from '../../../components/load-screen';

import { useLoading } from '../../../hooks';

import { getSurgeons } from '../../../actions/surgeonsActions';
import { getHospitals } from '../../../actions/hospitalsActions';
import { getProcedures } from '../../../actions/proceduresActions';
import { getSetLogs, getSets } from '../../../actions/setsActions';
import { getKits } from '../../../actions/kitsActions';
import { getCounts } from '../../../actions/countsActions';

import { presets } from '../../../constants/analytics';
import { caseStatusOptions, countStatusOptions } from '../../../constants/enums';

import './reporting-page.scss';
import { getCasesWithSetsAllocation } from '../../../actions/casesActions';
import { setLogTypes } from '../../../constants/setTurnsReporting';

const presetOptions = [
  { label: 'This month', value: presets.THIS_MONTH },
  { label: 'This quarter', value: presets.THIS_QUARTER },
  { label: 'This year', value: presets.THIS_YEAR },
  { label: 'Last month', value: presets.LAST_MONTH },
  { label: 'Last quarter', value: presets.LAST_QUARTER },
  { label: 'Last year', value: presets.LAST_YEAR },
];

const reportingSummaryOptions = {
  COMPANY_ACTIVITY: 'COMPANY_ACTIVITY',
  SURGEON_ACTIVITY: 'SURGEON_ACTIVITY',
  KIT_SET_ACTIVITY: 'KIT_SET_ACTIVITY',
  FORECASTING: 'FORECASTING',
  BILLING: 'BILLING',
  OPERATIONS: 'OPERATIONS',
};

const options = [
  { label: 'Company Activity', value: reportingSummaryOptions.COMPANY_ACTIVITY },
  { label: 'Surgeon Activity', value: reportingSummaryOptions.SURGEON_ACTIVITY },
  { label: 'Kit & Set Activity', value: reportingSummaryOptions.KIT_SET_ACTIVITY },
  { label: 'Forecasting (90 Days)', value: reportingSummaryOptions.FORECASTING },
  { label: 'Billing', value: reportingSummaryOptions.BILLING },
  { label: 'Operations', value: reportingSummaryOptions.OPERATIONS },
];

const ManagementReportsPage = () => {
  const dispatch = useDispatch();
  const tenantPrimaryColor = useSelector((state) => state?.tenant.currentTenant.colorPrimary);
  const casesList = useSelector((state) => state?.cases.list);
  const hospitals = useSelector((state) => state?.hospitals.list);
  const procedures = useSelector((state) => state?.procedures.list);
  const surgeons = useSelector((state) => state?.surgeons.list);
  const users = useSelector((state) => state?.users.list);
  const setsList = useSelector((state) => state?.sets.list);
  const kitsList = useSelector((state) => state?.kits.list);
  const countsList = useSelector((state) => state?.counts.list);

  const { loading: fetching, startLoading, stopLoading } = useLoading();

  const [cases, setCases] = useState([]);
  const [sets, setSets] = useState([]);
  const [compareCases, setCompareCases] = useState([]);
  const [setTurns, setSetTurns] = useState([]);
  const [counts, setCounts] = useState([]);
  const [compareCounts, setCompareCounts] = useState([]);

  const [reportingSummary, setReportingSummary] = useState(reportingSummaryOptions.COMPANY_ACTIVITY);
  const [loading, setLoading] = useState(false);

  const [preset, setPreset] = useState(presets.THIS_MONTH);
  const [minDate, setMinDate] = useState(moment().startOf('month'));
  const [maxDate, setMaxDate] = useState(moment().endOf('month'));

  const [presetCompare, setPresetCompare] = useState('');
  const [minDateCompare, setMinDateCompare] = useState(moment().subtract(1, 'month').startOf('month'));
  const [maxDateCompare, setMaxDateCompare] = useState(moment().subtract(1, 'month').endOf('month'));

  useEffect(() => {
    onLoad().catch((err) => console.error(err));
  }, []);

  useEffect(() => {
    setPresetCompare('');
    onPresetChange(preset, { onMinDateChange: setMinDate, onMaxDateChange: setMaxDate });
  }, [preset]);

  useEffect(() => {
    if (!presetCompare) {
      return;
    }

    if (preset === presets.THIS_MONTH) {
      onPresetChange(presets.LAST_MONTH, {
        onMinDateChange: setMinDateCompare,
        onMaxDateChange: setMaxDateCompare,
      }, presetCompare === presets.YEAR_AGO);
    }
    if (preset === presets.THIS_QUARTER) {
      onPresetChange(presets.LAST_QUARTER, {
        onMinDateChange: setMinDateCompare,
        onMaxDateChange: setMaxDateCompare,
      }, presetCompare === presets.YEAR_AGO);
    }
    if (preset === presets.THIS_YEAR) {
      onPresetChange(presets.LAST_YEAR, { onMinDateChange: setMinDateCompare, onMaxDateChange: setMaxDateCompare });
    }
  }, [presetCompare]);

  const onLoad = async () => {
    startLoading()
    try {
      await Promise.all([
        dispatch(getSurgeons()),
        dispatch(getHospitals()),
        dispatch(getProcedures()),
        dispatch(getSets()),
        dispatch(getKits()),
        dispatch(getCounts()),
      ]);
    } catch (err) {
      console.error(err);
    } finally {
      stopLoading();
    }
  };

  const loadSets = async () => {
    const list = [];
    const resp = await dispatch(getCasesWithSetsAllocation());
    const casesWithSets = resp?.filter((item) => !!item.setsAllocation.length);

    casesWithSets?.forEach((item) => {
      item?.setsAllocation?.forEach((allocation) => {
        if (!!allocation.shippingDate) {
          list?.push({
            ...allocation,
            caseDate: item.date,
            id: `${item.id}_${allocation.kit || allocation.itemId}`,
            caseId: item.id,
            hospital: item.hospital,
            surgeon: item.surgeon,
            kit: allocation.kit || null,
            item: allocation.itemId || null,
            date: allocation.shippingDate,
            quantity: allocation.quantity,
          });
        }
      });
    });

    setSets(list);
    return resp;
  };

  // const loadSetTurns = async () => {
  //   const list = [];
  //
  //   for (const set of setsList) {
  //     const logs = await dispatch(getSetLogs(set.id));
  //     const turns = logs?.filter((log) => log.type === setLogTypes.SET_TURNS && minDate.isBefore(log.createdAt) && maxDate.isAfter(log.createdAt))?.length;
  //     const kit = kitsList?.find((k) => k.id === set.kit);
  //
  //     list?.push({
  //       id: set.id,
  //       kitId: kit?.kitId || '',
  //       kitName: kit?.name || '',
  //       setId: set.id,
  //       setNumber: set.number,
  //       labels: set.labels || [],
  //       turns,
  //     });
  //   }
  //
  //   setSetTurns(list);
  // };

  const onSubmit = async () => {
    try {
      setLoading(true);

      if (reportingSummary === reportingSummaryOptions.KIT_SET_ACTIVITY || reportingSummary === reportingSummaryOptions.OPERATIONS) {
        await loadSets({ minDate, maxDate, minDateCompare, maxDateCompare }); // ToDo: Request cases only in date range
      }

      if (reportingSummary === reportingSummaryOptions.KIT_SET_ACTIVITY) {
        // await loadSetTurns({ minDate, maxDate, minDateCompare, maxDateCompare }); // ToDo: Request cases only in date range
      }

      setCases(
        casesList?.filter((item) => minDate.isBefore(item.date) && maxDate.isAfter(item.date)
          && !!item.active && item.status !== caseStatusOptions.request),
      );
      setCounts(
        countsList?.filter((item) => minDate.isBefore(item.completedAt) && maxDate.isAfter(item.completedAt)
          && !!item.active && item.status === countStatusOptions.completed),
      );

      if (presetCompare) {
        setCompareCases(
          casesList?.filter((item) => minDateCompare.isBefore(item.date) && maxDateCompare.isAfter(item.date)
            && !!item.active && item.status !== caseStatusOptions.request),
        );
        setCompareCounts(
          countsList?.filter((item) => minDateCompare.isBefore(item.completedAt) && maxDateCompare.isAfter(item.completedAt)
            && !!item.active && item.status === countStatusOptions.completed),
        );
      }
    } finally {
      setLoading(false);
    }
  };

  const onPresetChange = (value, { onMinDateChange, onMaxDateChange }, yearAgo = false) => {
    // setCases([]);
    // setSets([]);
    switch (value) {
      case presets.THIS_MONTH:
        onMinDateChange(moment().startOf('month'));
        onMaxDateChange(moment().endOf('month'));
        return;
      case presets.THIS_QUARTER:
        onMinDateChange(moment().startOf('quarter'));
        onMaxDateChange(moment().endOf('quarter'));
        return;
      case presets.THIS_YEAR:
        onMinDateChange(moment().startOf('year'));
        onMaxDateChange(moment().endOf('year'));
        return;
      case presets.LAST_MONTH:
        if (yearAgo) {
          onMinDateChange(moment().subtract(1, 'year').subtract(1, 'month').startOf('month'));
          onMaxDateChange(moment().subtract(1, 'year').subtract(1, 'month').endOf('month'));
          return;
        }
        onMinDateChange(moment().subtract(1, 'month').startOf('month'));
        onMaxDateChange(moment().subtract(1, 'month').endOf('month'));
        return;
      case presets.LAST_QUARTER:
        if (yearAgo) {
          onMinDateChange(moment().subtract(1, 'year').subtract(1, 'quarter').startOf('quarter'));
          onMaxDateChange(moment().subtract(1, 'year').subtract(1, 'quarter').endOf('quarter'));
          return;
        }
        onMinDateChange(moment().subtract(1, 'quarter').startOf('quarter'));
        onMaxDateChange(moment().subtract(1, 'quarter').endOf('quarter'));
        return;
      case presets.LAST_YEAR:
        onMinDateChange(moment().subtract(1, 'year').startOf('year'));
        onMaxDateChange(moment().subtract(1, 'year').endOf('year'));
        return;
      default:
        return;
    }
  };

  const presetCompareOptions = useMemo(() => {
    const options = [
      { label: 'Don\'t compare', value: '' },
    ];

    if ([presets.THIS_YEAR, presets.THIS_QUARTER, presets.THIS_MONTH].includes(preset)) {
      options?.push({ label: 'Previous period', value: presets.PREVIOUS_PERIOD });
    }

    if ([presets.THIS_QUARTER, presets.THIS_MONTH].includes(preset)) {
      options?.push({ label: 'Year ago', value: presets.YEAR_AGO });
    }

    if (reportingSummary === reportingSummaryOptions.FORECASTING) {
      return [ { label: 'Don\'t compare', value: '' }, { label: 'This period year ago', value: presets.YEAR_AGO }];
    }

    return options;
  }, [preset, reportingSummary]);

  const renderCharts = () => {
    const completedCases = cases.filter((item) => item.status === caseStatusOptions.completed && !!item.active);
    const completedCompareCases = compareCases.filter((item) => item.status === caseStatusOptions.completed && !!item.active);

    switch (reportingSummary) {
      case reportingSummaryOptions.COMPANY_ACTIVITY:
        return <>
          <div className="d-flex m-b-lg">
            <StatesPieChart cases={completedCases} hospitals={hospitals} tenantColor={tenantPrimaryColor} />
            <TypesLineChart cases={completedCases} tenantColor={tenantPrimaryColor} minDate={minDate} maxDate={maxDate} preset={preset} />
          </div>
          <div className="d-flex m-t-lg m-b-lg">
            <TypesStackedBarChart cases={completedCases} hospitals={hospitals} tenantColor={tenantPrimaryColor} compareCases={completedCompareCases} presetCompare={presetCompare} />
            <HospitalsBarChart cases={completedCases} hospitals={hospitals} tenantColor={tenantPrimaryColor} />
          </div>
          <div className="d-flex m-t-lg">
            <UsersBarChart cases={completedCases} users={users} tenantColor={tenantPrimaryColor} compareCases={completedCompareCases} presetCompare={presetCompare} />
            <ProceduresBarChart cases={completedCases} procedures={procedures} tenantColor={tenantPrimaryColor} />
          </div>
        </>;
      case reportingSummaryOptions.SURGEON_ACTIVITY:
        return <>
          <div className="d-flex m-b-lg">
            <SurgeonsBarChart cases={completedCases} surgeons={surgeons} tenantColor={tenantPrimaryColor} compareCases={completedCompareCases} presetCompare={presetCompare} />
            <SurgeonsStackedBarChart cases={completedCases} surgeons={surgeons} tenantColor={tenantPrimaryColor} compareCases={completedCompareCases} presetCompare={presetCompare} procedures={procedures} />
          </div>
          <div className="d-flex m-b-lg">
            <SurgeonsProformaBarChart cases={completedCases} surgeons={surgeons} tenantColor={tenantPrimaryColor} compareCases={completedCompareCases} presetCompare={presetCompare} />
            <SurgeonsProcedureStackedBarChart cases={completedCases} surgeons={surgeons} procedures={procedures} tenantColor={tenantPrimaryColor} />
          </div>
        </>;
      case reportingSummaryOptions.KIT_SET_ACTIVITY:
        return <>
          <div className="d-flex m-b-lg">
            <SetsShippedLineChart sets={sets?.filter((set) => !!set.kit)} tenantColor={tenantPrimaryColor} minDate={minDate} maxDate={maxDate} preset={preset} minDateCompare={minDateCompare} maxDateCompare={maxDateCompare} presetCompare={presetCompare} />
            <SetTurnsBarChart sets={sets?.filter((set) => !!set.kit)} kits={kitsList?.filter((k) => !!k.active)} tenantColor={tenantPrimaryColor} minDate={minDate} maxDate={maxDate} />
          </div>
          <div className="d-flex m-b-lg">
            <KitDistributionPieChart kits={kitsList?.filter((k) => !!k.active)} sets={setsList?.filter((s) => !!s.active)} />
            <HospitalsSetsBarChart sets={sets?.filter((set) => !!set.kit)} hospitals={hospitals} minDate={minDate} maxDate={maxDate} />
          </div>
        </>;
      case reportingSummaryOptions.FORECASTING:
        const start = moment().add(1, 'day').startOf('day');
        const end = moment().add(90, 'day').endOf('day');
        const next90 = casesList.filter((item) => start.isBefore(item.date) && end.isAfter(item.date) && item.status === caseStatusOptions.booked && !!item.active);

        return <>
          <div className="m-b-lg">
            <ForecastingCasesLineChart cases={next90} tenantColor={tenantPrimaryColor} presetCompare={presetCompare} />
          </div>
          <div className="d-flex m-b-lg">
            <ForecastingStatesPieChart cases={next90} hospitals={hospitals} tenantColor={tenantPrimaryColor} />
            <ForecastingHospitalsBarChart cases={next90} hospitals={hospitals} tenantColor={tenantPrimaryColor} />
          </div>
          <div className="d-flex m-b-lg">
            <ForecastingSurgeonsBarChart cases={next90} surgeons={surgeons} tenantColor={tenantPrimaryColor} />
            <ForecastingProceduresPieChart cases={next90} procedures={procedures} tenantColor={tenantPrimaryColor} />
          </div>
        </>;
      case reportingSummaryOptions.BILLING:
        const billingCases = casesList.filter((item) => item.status === caseStatusOptions.completed && !item.orderClosed && !!item.active);

        return <>
          <div className="d-flex m-b-lg">
            <BillingProformaPieChart cases={billingCases} tenantColor={tenantPrimaryColor} procedures={procedures} />
            <BillingHospitalsProformaBarChart cases={billingCases} tenantColor={tenantPrimaryColor} hospitals={hospitals} />
          </div>
          <div className="d-flex m-b-lg">
            <BillingStatesProformaBarChart cases={billingCases} tenantColor={tenantPrimaryColor} hospitals={hospitals} />
            <div style={{ width: '50%' }} />
          </div>
        </>;
      case reportingSummaryOptions.OPERATIONS:
        return <>
          <div className="d-flex m-b-lg">
            <FreightCostPieChart sets={sets} tenantColor={tenantPrimaryColor} hospitals={hospitals} minDate={minDate} maxDate={maxDate} />
            <FreightCostHospitalsBarChart sets={sets} tenantColor={tenantPrimaryColor} hospitals={hospitals} minDate={minDate} maxDate={maxDate} />
          </div>
          <div className="d-flex m-b-lg">
            <CountsSubmittedLineChart
              counts={counts?.map((count) => ({ ...count, date: count.completedAt }))}
              compareCounts={compareCounts?.map((count) => ({ ...count, date: count.completedAt }))}
              tenantColor={tenantPrimaryColor}
              minDate={minDate}
              maxDate={maxDate}
              preset={preset}
              minDateCompare={minDateCompare}
              maxDateCompare={maxDateCompare}
              presetCompare={presetCompare}
            />
            <ExpiringItemsPieChart sets={setsList} tenantColor={tenantPrimaryColor} kits={kitsList?.filter((k) => !!k.active)} />
          </div>
        </>;
      default:
        return null;
    }
  };

  return (
    <div className="page-container reporting-page__container">
      {fetching && <LoadScreen />}
      <div className="page-title">Management Reports</div>
      <div className="reporting-page__body">
        <div className="case-reporting-chart__container">
          <ManagementReportsForm
            reportingSummary={reportingSummary}
            setReportingSummary={(value) => {
              setCases([]);
              setReportingSummary(value);
            }}
            reportingSummaryOptions={reportingSummaryOptions}
            options={options}
            onSubmit={onSubmit}
            loading={loading}
          />
          <CaseDateRange
            minDate={minDate}
            onMinDateChange={(date) => {
              setMinDate(date);
              setPreset(null);
            }}
            maxDate={maxDate}
            onMaxDateChange={(date) => {
              setMaxDate(date);
              setPreset(null);
            }}
            preset={preset}
            onPresetChange={setPreset}
            presetCompare={presetCompare}
            onPresetChangeCompare={setPresetCompare}
            options={presetOptions}
            compareOptions={presetCompareOptions}
            disabled={reportingSummary === reportingSummaryOptions.FORECASTING || reportingSummary === reportingSummaryOptions.BILLING}
            compareDisabled={reportingSummary === reportingSummaryOptions.BILLING}
          />
        </div>

        {!!cases?.length && (
          <div className="managing-reports-charts__container">
            {renderCharts()}
          </div>
        )}
      </div>
    </div>
  );
};

export default ManagementReportsPage;
