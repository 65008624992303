import { orderBy } from 'lodash';
import { v4 as uuid } from 'uuid';
import moment from 'moment';

import {
  GET_COUNT, SET_COUNT,
  GET_COUNTS, SET_COUNTS,
  GET_COUNT_NOTES, SET_COUNT_NOTES,
  GET_COUNT_SCANS, SET_COUNT_SCANS,
  CLEAR_COUNT,
} from './actionTypes';

import firebase, { collections, storageRefs } from '../firebase';

import { fromMomentToTimestampDay, nowTimestampUTC } from '../utils/date';
import { remove, getFile, getFileWithParameters } from '../utils/api';

import { countStatuses, countStatusOptions, implantScanTypes, caseUsageTypes } from '../constants/enums';
import urls from '../constants/urls';
import userRoles, { territoryRoles } from '../constants/userRoles';

const getStatus = (status, timestamp) => {
  const dueDate = timestamp ? timestamp.toDate() : null;

  if (status === countStatuses.COMPLETED.value) {
    return countStatuses.COMPLETED.value;
  }

  if (status === countStatuses.PENDING.value && !dueDate) {
    return countStatuses.PENDING.value;
  }

  const today = moment().startOf('day');
  return moment(dueDate).isBefore(today) ? countStatuses.OVERDUE.value : countStatuses.PENDING.value;
};

export const setCounts = (counts) => ({ type: SET_COUNTS, counts });

export const getCounts = () => async (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;
  const role = state.user.currentUser?.role;
  const currentUser = state.user.currentUser;
  const hospitals = state.hospitals?.list || [];
  const uid = currentUser?.uid;
  const groups = state.groups?.list?.filter((group) => group?.members?.includes(uid));
  const path = collections.COUNTS_COLLECTION(tenantId);

  dispatch({ type: GET_COUNTS });

  const snapshot = await firebase.db.collection(path)
    .where('active', '==', true)
    .get();
  let counts = snapshot.docs.map(doc => {
    const data = doc.data();
    return {
      ...data,
      id: doc.id,
      createdAt: data.createdAt ? data.createdAt.toDate() : null,
      updatedAt: data.updatedAt ? data.updatedAt.toDate() : null,
      dueDate: data.dueDate ? data.dueDate.toDate() : null,
      completedAt: data.completedAt ? data.completedAt.toDate() : null,
      status: data.status ? getStatus(data.status, data.dueDate) : ''
    };
  });

  if (role !== userRoles.ADMIN.name) {
    counts = counts?.filter((count) => {
      let filter = true;

      if (territoryRoles?.includes(currentUser.role) && !!currentUser.territoryVisibility) {
        const userHospitals = hospitals?.filter((h) => h.territories?.some((territory) => currentUser?.assignedTerritories?.includes(territory)));
        filter = userHospitals?.map((h) => h.id)?.includes(count.hospitalId);
      }
      return filter && (count?.taggedUsers?.includes(uid) || count?.groups?.some((g) => groups?.includes(g)));
    });
  }

  return dispatch(setCounts(orderBy(counts, 'dueDate', 'asc')));
};

export const createCount = (countData) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;
  const path = collections.COUNTS_COLLECTION(tenantId);
  const id = uuid().substring(0, 8).toUpperCase();

  if (tenantId) {
    const doc = {
      ...countData,
      active: true,
      createdAt: nowTimestampUTC(),
      updatedAt: nowTimestampUTC(),
      dueDate: countData.dueDate ? fromMomentToTimestampDay(countData.dueDate) : null,
      completedBy: null,
      status: countStatusOptions.pending,
    };
    await firebase.db.collection(path).doc(id).set(doc, { merge: true });
    return id;
  } else {
    throw new Error('Invalid tenant');
  }
};

export const updateCount = (countId, countData) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;
  const path = collections.COUNTS_COLLECTION(tenantId);

  if (tenantId) {
    const doc = {
      ...countData,
      updatedAt: nowTimestampUTC(),
    };

    if (countData.dueDate) {
      doc.dueDate = fromMomentToTimestampDay(countData.dueDate);
    }
    await firebase.db.collection(path).doc(countId).set(doc, { merge: true });
  } else {
    throw new Error('Invalid tenant');
  }
};

export const submitCount = (countId) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;
  const userId = state.user.currentUser.uid;
  const path = collections.COUNTS_COLLECTION(tenantId);

  if (tenantId) {
    const doc = {
      completedAt: nowTimestampUTC(),
      completedBy: userId,
      status: countStatuses.COMPLETED.value,
    };
    await firebase.db.collection(path).doc(countId).set(doc, { merge: true });
  } else {
    throw new Error('Invalid tenant');
  }
};

export const deleteCount = (countId) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;
  const idToken = await firebase.auth.currentUser.getIdToken();

  if (tenantId) {
    await remove(urls.counts.delete(countId, tenantId), idToken);
  } else {
    throw new Error('Invalid tenant');
  }
};

// Scans

export const addScanImplants = (countId, usageData) => async (dispatch, getState) => {
  const state = getState();
  const userId = state.user.currentUser.uid;
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;
  const path = collections.COUNT_SCANS_COLLECTION(tenantId, countId);

  const values = [
    { label: 'REF', value: usageData.ref || '' },
    { label: 'LOT', value: usageData.lot || '' },
    { label: 'EXP', value: usageData.exp || '' },
    { label: 'DESC', value: usageData.desc || '' }
  ];

  if (usageData.gtin) {
    values.push({ label: 'GTIN', value: usageData.gtin });
  }

  const usageObj = {
    active: true,
    createdAt: nowTimestampUTC(),
    type: caseUsageTypes.implants,
    values: values.map((value) => ({ ...value, generated: false, readonly: false, placeholder: null })),
    quantity: usageData.quantity || 0,
    image: null,
    scanType: implantScanTypes.text,
    approved: false,
    tag: usageData?.tag || null,
    userId,
  };

  await firebase.db.collection(path).add(usageObj);
};

export const updateScanImplants = (countId, usageData) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;
  const path = collections.COUNT_SCANS_COLLECTION(tenantId, countId);

  const values = usageData.values.map((value) => {
    if (value.label === 'REF') {
      value.value = usageData.ref;
    }
    if (value.label === 'LOT') {
      value.value = usageData.lot;
    }
    if (value.label === 'EXP') {
      value.value = usageData.exp;
    }
    if (value.label === 'GTIN' && usageData.gtin) {
      value.value = usageData.gtin;
    }
    if (value.label === 'DESC' && usageData.desc) {
      value.value = usageData.desc;
    }
    return value;
  });

  const keys = ['ref', 'lot', 'exp', 'gtin', 'desc'];
  keys?.forEach((key) => {
    if (usageData[key] && !usageData.values?.find((value) => value.label === key?.toUpperCase())) {
      values?.push({
        generated: false,
        readonly: false,
        placeholder: null,
        label: key?.toUpperCase(),
        value: usageData[key],
      });
    }
  });

  if (usageData.gtin && !usageData.values.find((usage) => usage.label === 'GTIN')) {
    values.push({
      generated: false,
      readonly: false,
      placeholder: null,
      label: 'GTIN',
      value: usageData.gtin,
    });
  }

  const usageObj = {
    updatedAt: nowTimestampUTC(),
    quantity: usageData.quantity || 0,
    values,
    tag: usageData?.tag || null,
  };

  await firebase.db.collection(path).doc(usageData.id).set(usageObj, { merge: true });
};

export const addScanImage = (countId, usageData, image) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;
  const user = state.user.currentUser;
  const path = collections.COUNT_SCANS_COLLECTION(tenantId, countId);

  const storageRef = firebase.storage.ref();
  const storagePath = storageRefs.COUNT_SCANS_IMAGES_REF(tenantId, countId);
  const filePath = `${storagePath}/${image.name}`;
  const ref = storageRef.child(filePath);

  const snap = await ref.put(image);
  const downloadUrl = await snap.ref.getDownloadURL();

  const fileObj = {
    active: true,
    fileName: image.name,
    path: filePath,
    downloadUrl,
    createdAt: nowTimestampUTC(),
  };

  const values = [
    { label: 'USER', value: `${user.firstName} ${user.lastName}` },
    { label: 'DESC', value: usageData.description || '' },
  ];
  const usageObj = {
    active: true,
    createdAt: nowTimestampUTC(),
    type: caseUsageTypes.images,
    scanType: null,
    values: values.map((value) => ({ ...value, generated: false, readonly: false, placeholder: null })),
    image: fileObj,
    quantity: usageData.quantity || 1,
  };

  await firebase.db.collection(path).add(usageObj);
};

export const updateScanImage = (countId, usageData) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;
  const path = collections.COUNT_SCANS_COLLECTION(tenantId, countId);

  const values = [...usageData.values];
  const desc = values.find((value) => value.label === 'DESC');

  desc.value = usageData.description || '';

  const usageObj = {
    updatedAt: nowTimestampUTC(),
    quantity: usageData.quantity || 1,
    values,
  };

  await firebase.db.collection(path).doc(usageData.id).set(usageObj, { merge: true });
};

export const deleteScan = (countId, scanId) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;
  const path = collections.COUNT_SCANS_COLLECTION(tenantId, countId);

  await firebase.db.collection(path).doc(scanId).delete();
};

export const addBOMItems = (countId, items) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;
  const userId = state.user.currentUser ? state.user.currentUser.uid : null;
  const path = collections.COUNT_SCANS_COLLECTION(tenantId, countId);

  if (tenantId) {
    const promises = [];
    items?.forEach((item) => {
      const doc = {
        active: true,
        createdAt: nowTimestampUTC(),
        image: null,
        quantity: item.count || 1,
        scanType: implantScanTypes.billOfMaterial,
        type: caseUsageTypes.implants,
        userId,
        itemId: item.id || '',
        values: [
          { generated: false, label: 'REF', placeholder: null, readonly: false, value: item.code },
          { generated: false, label: 'DESC', placeholder: null, readonly: false, value: item.description },
        ],
      };
      promises?.push(firebase.db.collection(path).add(doc));
    });
    await Promise.all(promises);
  } else {
    throw new Error('Invalid tenant');
  }
};

export const updateBOMItems = (countId, items) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;
  const path = collections.COUNT_SCANS_COLLECTION(tenantId, countId);

  if (tenantId) {
    const promises = [];
    items?.forEach((item) => {
      if (item.scanId) {
        promises?.push(firebase.db.collection(path).doc(item.scanId).set({ quantity: item.count }, { merge: true }));
      }
    });
    await Promise.all(promises);
  } else {
    throw new Error('Invalid tenant');
  }
};

export const deleteBOMItems = (countId, items) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;
  const path = collections.COUNT_SCANS_COLLECTION(tenantId, countId);

  if (tenantId) {
    const promises = [];
    items?.forEach((item) => {
      if (item.scanId) {
        promises?.push(firebase.db.collection(path).doc(item.scanId).delete());
      }
    });
    await Promise.all(promises);
  } else {
    throw new Error('Invalid tenant');
  }
};

// Active count

export const setCount = (count) => ({ type: SET_COUNT, count });

export const getCount = (countId, noReducer) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;
  const path = collections.COUNTS_COLLECTION(tenantId);

  dispatch({ type: GET_COUNT });

  const doc = await firebase.db.collection(path).doc(countId).get();
  const data = doc.data();
  const count = {
    ...data,
    id: doc.id,
    createdAt: data.createdAt ? data.createdAt.toDate() : '',
    dueDate: data.dueDate ? data.dueDate.toDate() : '',
  };

  if (noReducer) {
    return count;
  }

  return dispatch(setCount(count));
};

export const clearCount = () => ({ type: CLEAR_COUNT });

export const exportCount = (countId) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;
  const idToken = await firebase.auth.currentUser.getIdToken();

  if (tenantId) {
    await getFile(urls.counts.export(countId, tenantId), idToken, countId);
  } else {
    throw new Error('Invalid tenant');
  }
};

// Count notes

export const setCountNotes = (notes) => ({ type: SET_COUNT_NOTES, notes });

export const getCountNotes = (countId) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;
  const path = collections.COUNT_NOTES_COLLECTION(tenantId, countId);

  dispatch({ type: GET_COUNT_NOTES });

  const snapshot = await firebase.db.collection(path).get();
  const notes = snapshot.docs.map(doc => {
    const data = doc.data();
    return {
      ...data,
      id: doc.id,
      createdAt: data.createdAt ? data.createdAt.toDate() : '',
    };
  });

  return dispatch(setCountNotes(notes));
};

export const createCountNote = (countId, noteData) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;
  const currentUser = state.user.currentUser;
  const path = collections.COUNT_NOTES_COLLECTION(tenantId, countId);

  if (tenantId) {
    await firebase.db.collection(path).add({
      ...noteData,
      active: true,
      createdAt: nowTimestampUTC(),
      authorFullName: `${currentUser.firstName} ${currentUser.lastName}`,
      authorId: currentUser.uid,
      countId,
    });
  } else {
    throw new Error('Invalid tenant');
  }
};

export const setCountScans = (scans) => ({ type: SET_COUNT_SCANS, scans });

export const getCountScans = (countId, noReducer) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;
  const path = collections.COUNT_SCANS_COLLECTION(tenantId, countId);

  dispatch({ type: GET_COUNT_SCANS });

  const snapshot = await firebase.db.collection(path).get();
  const scans = snapshot.docs?.map(doc => {
    const data = doc.data();
    return {
      ...data,
      id: doc.id,
      createdAt: data.createdAt ? data.createdAt.toDate() : '',
    };
  });

  if (noReducer) {
    return scans;
  }

  return dispatch(setCountScans(scans));
};

export const getCountReportingData = (data) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;
  const counts = state.counts.list || [];

  const {
    location,
    subLocation,
    user,
    type,
    minDate,
    maxDate,
  } = data;

  let result = [];
  const filteredCounts = counts?.filter((count) => {
    let persistence = true;
    if (location) {
      persistence = count.hospitalId === location;
    }
    if (subLocation) {
      persistence = count.location === subLocation;
    }
    return count.status === 'COMPLETED' && persistence
      && moment(count.completedAt).isAfter(moment(minDate).startOf('day'))
      && moment(count.completedAt).isBefore(moment(maxDate).endOf('day'));
  });

  for (const count of filteredCounts) {
    const path = collections.COUNT_SCANS_COLLECTION(tenantId, count.id);
    let snapshot = await firebase.db.collection(path).get();
    let scans = snapshot.docs?.map((doc) => {
      const data = doc.data();
      return {
        ...data,
        id: doc.id,
        countId: count.id,
        createdAt: data.createdAt ? data.createdAt.toDate() : '',
        completedAt: count.completedAt || '',
        hospitalId: count.hospitalId,
        location: count.location,
      };
    });
    scans = scans.filter((scan) => {
      let persistence = true;
      if (user) {
        persistence = scan.userId === user;
      }
      if (type) {
        persistence = scan.scanType === type;
      }
      return persistence;
    });
    result = [...result, ...scans];
  }

  return result;
};

export const exportCounts = (counts) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;
  const idToken = await firebase.auth.currentUser.getIdToken();

  try {
    await getFileWithParameters(urls.counts.exportReport(tenantId), idToken, 'Counts report', 'xlsx', { counts });
  } catch (err) {
    throw new Error(err);
  }
};

export const exportCountItems = (items) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;
  const idToken = await firebase.auth.currentUser.getIdToken();

  try {
    await getFileWithParameters(urls.counts.exportItemsReport(tenantId), idToken, 'Count Items report', 'xlsx', { items });
  } catch (err) {
    throw new Error(err);
  }
};

// Subscriptions

export const subscribeToCounts = () => (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;
  const role = state.user.currentUser?.role;
  const currentUser = state.user.currentUser;
  const hospitals = state.hospitals?.list || [];
  const uid = currentUser?.uid;
  const groups = state.groups?.list?.filter((group) => group?.members?.includes(uid));
  const path = collections.COUNTS_COLLECTION(tenantId);

  return firebase
    .db
    .collection(path)
    .where('active', '==', true)
    .onSnapshot({
      error: (e) => console.error(e),
      next: (querySnapshot) => {
        let counts = [];
        querySnapshot.forEach((documentSnapshot) => {
          const id = documentSnapshot.id;
          const data = documentSnapshot.data();

          counts = [...counts, {
            ...data,
            id,
            createdAt: data.createdAt ? data.createdAt.toDate() : null,
            updatedAt: data.updatedAt ? data.updatedAt.toDate() : null,
            completedAt: data.completedAt ? data.completedAt.toDate() : null,
            dueDate: data.dueDate ? data.dueDate.toDate() : null,
            status: data.status ? getStatus(data.status, data.dueDate) : ''
          }];
        });

        if (role !== userRoles.ADMIN.name) {
          counts = counts?.filter((count) => {
            let filter = true;

            if (territoryRoles?.includes(currentUser.role) && !!currentUser.territoryVisibility) {
              const userHospitals = hospitals?.filter((h) => h.territories?.some((territory) => currentUser?.assignedTerritories?.includes(territory)));
              filter = userHospitals?.map((h) => h.id)?.includes(count.hospitalId);
            }
            return filter && (count?.taggedUsers?.includes(uid) || count?.groups?.some((g) => groups?.includes(g)));
          });
        }

        return dispatch(setCounts(orderBy(counts, 'dueDate', 'asc')));
      },
    });
};

export const subscribeToCount = (countId) => (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;
  const hospitals = state.hospitals?.list || [];
  const currentUser = state.user.currentUser;
  const path = collections.COUNTS_COLLECTION(tenantId);

  return firebase
    .db
    .collection(path)
    .doc(countId)
    .onSnapshot({
      error: (e) => console.error(e),
      next: (doc) => {
        const data = doc.data();
        const count = {
          ...data,
          id: doc.exists ? doc.id : null,
          createdAt: data && data.createdAt ? data.createdAt.toDate() : '',
          updatedAt: data && data.updatedAt ? data.updatedAt.toDate() : '',
          completedAt: data && data.completedAt ? data.completedAt.toDate() : '',
          dueDate: data && data.dueDate ? data.dueDate.toDate() : '',
          status: data.status ? getStatus(data.status, data.dueDate) : ''
        };


        if (territoryRoles?.includes(currentUser.role) && !!currentUser.territoryVisibility) {
          const userHospitals = hospitals?.filter((h) => h.territories?.some((territory) => currentUser?.assignedTerritories?.includes(territory)));
          if (!userHospitals?.map((h) => h.id)?.includes(count.hospitalId)) {
            return dispatch(setCount({ id: null }));
          }
        }

        return dispatch(setCount(count));
      },
    });
};

export const subscribeToCountNotes = (countId) => (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;
  const path = collections.COUNT_NOTES_COLLECTION(tenantId, countId);

  return firebase
    .db
    .collection(path)
    .onSnapshot({
      error: (e) => console.error(e),
      next: (querySnapshot) => {
        let notes = [];
        querySnapshot.forEach((documentSnapshot) => {
          const id = documentSnapshot.id;
          const data = documentSnapshot.data();

          notes = [...notes, {
            ...data,
            id,
            createdAt: data.createdAt ? data.createdAt.toDate() : '',
          }];
        });
        const sortedNotes = orderBy(notes, 'createdAt', 'desc');

        return dispatch(setCountNotes(sortedNotes));
      },
    });
};

export const subscribeToCountScans = (countId) => (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;
  const path = collections.COUNT_SCANS_COLLECTION(tenantId, countId);

  return firebase
    .db
    .collection(path)
    .onSnapshot({
      error: (e) => console.error(e),
      next: (querySnapshot) => {
        let scans = [];
        querySnapshot.forEach((documentSnapshot) => {
          const id = documentSnapshot.id;
          const data = documentSnapshot.data();

          scans = [...scans, {
            ...data,
            id,
            createdAt: data.createdAt ? data.createdAt.toDate() : '',
          }];
        });
        const sortedScans = orderBy(scans, 'createdAt', 'desc');

        return dispatch(setCountScans(sortedScans));
      },
    });
};
