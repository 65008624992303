import React from 'react';

import DeleteIcon from '../../assets/icons/DeleteIcon';
import MoveIcon from '../../assets/icons/MoveIcon';
import DocumentIcon from '../../assets/icons/DocumentIcon';
import FolderIcon from '../../assets/icons/FolderIcon';
import VideoIcon from '../../assets/icons/VideoIcon';
import LinkIcon from '../../assets/icons/LinkIcon';

import ExpandIcon from '@material-ui/icons/ExpandMore';

import Button from '../shared/Button';
import DropdownButton from '../shared/DropdownButton';

import { driveConstants } from '../../constants';

const { driveItemTypes } = driveConstants;

const dropdownOptions = [
  { id: driveItemTypes.folder, label: 'Add Folder', icon: <FolderIcon /> },
  { id: driveItemTypes.document, label: 'Add Document', icon: <DocumentIcon /> },
  { id: driveItemTypes.video, label: 'Add Video', icon: <VideoIcon /> },
  { id: driveItemTypes.link, label: 'Add Link', icon: <LinkIcon /> },
];

const DriveActions = ({ activeFolder, activeFile, onAddClick, onDeleteClick, deletePermission, onMoveFileClick }) => {
  return (
    <div className='settings-cmp__filters--main'>
      <div className='settings-cmp__actions'>
        <DropdownButton
          onClick={(option) => onAddClick(option.id)}
          options={!activeFolder || activeFolder === 'root' ? [dropdownOptions[0]] : dropdownOptions}
        >
          <div className='d-flex m-l-lg'>
            Add
            <ExpandIcon />
          </div>
        </DropdownButton>

        <Button
          type='icon'
          onClick={onDeleteClick}
          disabled={!deletePermission}
        >
          <DeleteIcon style={{ color: '#000000' }}/>
        </Button>

        {activeFile ? (
          <Button
            type='icon'
            onClick={onMoveFileClick}
          >
            <MoveIcon style={{ color: '#000000' }}/>
          </Button>
        ) : (<div style={{ width: '64px' }} />)}
      </div>
    </div>
  );
};

export default DriveActions;
