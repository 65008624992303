import React from 'react';
import moment from 'moment';

import PreferenceIcon from '../../assets/icons/PreferenceIcon';
import CaseIcon from '../../assets/icons/CaseIcon';
import NotificationsIcon from '../../assets/icons/NotificationsIcon';
import TransferIcon from '../../assets/icons/TransferIcon';
import DeleteIcon from '../../assets/icons/DeleteIcon';
import NotesIcon from '../../assets/icons/NotesIcon';
import UsageIcon from '../../assets/icons/UsageIcon';
import FormIcon from '../../assets/icons/FormIcon';
import CountIcon from '../../assets/icons/CountIcon';

import { getStatusIcon } from '../../utils/cases';

import { notificationTypes, caseStatusOptions } from '../../constants/enums';

import './notifications.scss';

const {
  caseBooked,
  caseDateUpdated,
  caseNoteAdded,
  caseOverdue,
  caseRequested,
  caseUsageSubmitted,
  caseCancelled,
  caseDeleted,
  countAdded,
  countCompleted,
  preferenceAdded,
  taskDue,
  transferRequested,
  transferAccepted,
  transferDeclined,
  formSubmitted,
} = notificationTypes;

const NotificationItem = (props) => {
  const {
    title,
    subtitle,
    payload,
    createdAt,
    type,
    tenantColor,
    arguments: args,
    onClick,
  } = props;

  const renderIcon = () => {
    switch (type) {
      case caseBooked:
        return getStatusIcon(caseStatusOptions.booked, true);
      case caseRequested:
        return getStatusIcon(caseStatusOptions.request, true);
      case caseOverdue:
        return getStatusIcon(caseStatusOptions.overdue, true);
      case caseDateUpdated:
        return <CaseIcon color={tenantColor} />;
      case caseNoteAdded:
        return <NotesIcon color={tenantColor} />;
      case caseUsageSubmitted:
        return <UsageIcon color={tenantColor} />;
      case caseCancelled:
        return getStatusIcon(caseStatusOptions.overdue, true, true);
      case caseDeleted:
        return <DeleteIcon color="#d32f2f" />;
      case countAdded:
        return <CountIcon color={tenantColor} />;
      case countCompleted:
        return getStatusIcon(caseStatusOptions.completed, true);
      case preferenceAdded:
        return <PreferenceIcon color={tenantColor} />;
      case taskDue:
        return getStatusIcon(caseStatusOptions.overdue, true);
      case transferRequested:
        return <TransferIcon color={tenantColor} />;
      case transferAccepted:
        return <TransferIcon color={tenantColor} />;
      case transferDeclined:
        return <TransferIcon color={tenantColor} />;
      case formSubmitted:
        return <FormIcon color={tenantColor} />;
      default:
        return <NotificationsIcon color={tenantColor} />;
    }
  };

  const renderSubtitle = () => {
    if (subtitle) {
      return subtitle;
    }

    if ([
      caseBooked, caseDateUpdated, caseNoteAdded, caseOverdue, caseRequested, caseUsageSubmitted,
      transferRequested, transferAccepted, transferDeclined, caseCancelled,
    ].includes(type)) {
      return args.caseId || '';
    }
    if (type === countCompleted || type === countAdded) {
      return args.countId || '';
    }
    if (type === preferenceAdded) {
      return args.surgeonId || '';
    }
    if (type === taskDue) {
      return args.taskId || '';
    }

    return '';
  };

  return (
    <div className='notification-item' onClick={() => onClick(type, args)}>
      <div className='notification-item__icon'>
        {renderIcon()}
      </div>
      <div className='flex-1'>
        <div className='d-flex space-between'>
          <span className='font-bold'>{title}</span>
          <span className='secondary font-size-sm'>{moment(createdAt).fromNow()}</span>
        </div>
        <div className='m-t-sm'>
          {payload}
        </div>

        <div className='m-t-sm font-size-sm secondary'>
          {renderSubtitle()}
        </div>
      </div>
    </div>
  );
};

export default NotificationItem;
