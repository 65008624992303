import { orderBy } from 'lodash';
import moment from 'moment';

import { GET_SETS, SET_SETS, SET_QUARANTINED_SETS, SET_LABELS, UPDATE_SET_ALLOCATION } from './actionTypes';

import firebase, { collections, storageRefs } from '../firebase';

import { setAllocationStatuses } from '../constants/enums';

import { fromMomentToTimestampDay, nowTimestampUTC } from '../utils/date';
import { getFileWithParameters } from '../utils/api';
import urls from '../constants/urls';

export const setSets = sets => ({ type: SET_SETS, sets });

export const setQuarantinedSets = sets => ({ type: SET_QUARANTINED_SETS, sets });

export const setLabels = labels => ({ type: SET_LABELS, labels });

const formatBatchItems = (batchItems) => {
  if (!batchItems) {
    return {};
  }

  for (const key in batchItems) {
    if (batchItems.hasOwnProperty(key)) {
      const items = batchItems[key];
      for (const item of items) {
        item.expDate = item.expDate ? item.expDate.toDate() : null;
      }
    }
  }

  return batchItems;
}

export const getSets = (tenant, withReducer) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = tenant || state?.tenant?.currentTenant?.id;
  const path = collections.SETS_COLLECTION(tenantId);

  dispatch({ type: GET_SETS });

  const snapshot = await firebase.db.collection(path).get();
  const sets = snapshot.docs.map((doc) => {
    const data = doc.data();
    return {
      id: doc.id,
      ...data,
      batchItems: formatBatchItems(data.batchItems),
    }
  });

  if (tenant && !withReducer) {
    return sets;
  }

  return dispatch(setSets(sets));
};

export const getSet = (id) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;
  const path = collections.SETS_COLLECTION(tenantId);

  const doc = await firebase.db.collection(path).doc(id).get();
  const data = doc?.data();

  return {
    id: doc?.exists ? doc?.id : null,
    ...data,
    batchItems: formatBatchItems(data.batchItems),
  };
};

export const getQuarantinedSets = () => async (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;
  const path = collections.SETS_COLLECTION(tenantId);

  dispatch({ type: GET_SETS });

  const snapshot = await firebase.db.collection(path).where('quarantined', '==', true).get();
  const sets = snapshot.docs.map(doc => ({
    id: doc.id,
    ...doc.data(),
  }));

  return dispatch(setQuarantinedSets(sets));
};

export const createSet = (setData) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;
  const path = collections.SETS_COLLECTION(tenantId);

  const doc = {
    ...setData,
    active: true,
  };

  if (tenantId) {
    await firebase.db.collection(path).add(doc);
  } else {
    throw new Error('Invalid tenant');
  }
};

export const updateSet = (setId, setData) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;
  const path = collections.SETS_COLLECTION(tenantId);

  const doc = setData;

  if (setData.consignment) {
    doc.consignment = {
      ...setData.consignment,
      consignedDate: fromMomentToTimestampDay(setData?.consignment?.consignedDate),
      reviewDate: fromMomentToTimestampDay(setData?.consignment?.reviewDate),
    };
  }

  if (tenantId) {
    await firebase.db.collection(path).doc(setId).set(doc, { merge: true });
  } else {
    throw new Error('Invalid tenant');
  }
};

export const updateCaseAllocation = (setId, caseAllocation) => async (dispatch) => {
  return dispatch({ type: UPDATE_SET_ALLOCATION, id: setId, caseAllocation });
};

export const updateBatchItems = (set, itemId, batchItems) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;
  const path = collections.SETS_COLLECTION(tenantId);

  const doc = {
    batchItems: {
      ...set.batchItems,
      [itemId]: batchItems?.filter((item) => !!item.code)?.map((item) => ({
        ...item,
        expDate: item?.expDate ? fromMomentToTimestampDay(item.expDate) : null
      }))
    }
  };

  if (tenantId) {
    await firebase.db.collection(path).doc(set.id).set(doc, { merge: true });
  } else {
    throw new Error('Invalid tenant');
  }
};

export const updateItemQuantity = (set, itemId, quantity) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;
  const path = collections.SETS_COLLECTION(tenantId);

  const doc = {
    itemsQuantity: {
      ...set.itemsQuantity,
      [itemId]: quantity
    }
  };

  if (tenantId) {
    await firebase.db.collection(path).doc(set.id).set(doc, { merge: true });
  } else {
    throw new Error('Invalid tenant');
  }
};

export const deleteSet = (setId) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;
  const path = collections.SETS_COLLECTION(tenantId);

  if (tenantId) {
    await firebase.db.collection(path).doc(setId).delete();
  } else {
    throw new Error('Invalid tenant');
  }
};

export const bulkDeleteSets = (sets) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;
  const path = collections.SETS_COLLECTION(tenantId);
  const batch = firebase.db.batch();

  if (tenantId) {
    sets.forEach((setId) => {
      const ref = firebase.db.collection(path).doc(setId);
      batch.delete(ref);
    });

    await batch.commit();
  } else {
    throw new Error('Invalid tenant');
  }
};

export const uploadSets = (sets) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;
  const path = collections.SETS_COLLECTION(tenantId);

  if (tenantId) {
    const promises = [];

    sets.forEach((set) => {
      const number = set[0] || '';
      const containerSize = set[1] || 0;

      const doc = {
        active: true,
        number,
        containerSize,
        status: setAllocationStatuses.AVAILABLE.value,
        caseAllocation: null,
        kit: null,
      };

      promises.push(firebase.db.collection(path).add(doc));
    });

    await Promise.all(promises);
  } else {
    throw new Error('Invalid tenant');
  }
};

export const getLabels = (tenant, withReducer) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = tenant || state?.tenant?.currentTenant?.id;
  const path = collections.SET_LABELS_COLLECTION(tenantId);

  const snapshot = await firebase.db.collection(path).get();
  const labels = snapshot.docs.map(doc => ({
    id: doc.id,
    ...doc.data(),
  }));

  return dispatch(setLabels(labels));
};

export const addLabel = (label) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;
  const path = collections.SET_LABELS_COLLECTION(tenantId);

  if (tenantId) {
    await firebase.db.collection(path).add({ name: label });
  } else {
    throw new Error('Invalid tenant');
  }
};

export const updateLabel = (labelId, label) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;
  const path = collections.SET_LABELS_COLLECTION(tenantId);

  if (tenantId) {
    await firebase.db.collection(path).doc(labelId).set({ name: label }, { merge: true });
  } else {
    throw new Error('Invalid tenant');
  }
};

export const deleteLabel = (labelId) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;
  const path = collections.SET_LABELS_COLLECTION(tenantId);

  if (tenantId) {
    await firebase.db.collection(path).doc(labelId).delete();
  } else {
    throw new Error('Invalid tenant');
  }
};

export const resetTargetTurns = (setId, resetNote) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;
  const resetBy = state.user.currentUser.uid;
  const resetAt = nowTimestampUTC();
  const path = collections.SETS_COLLECTION(tenantId);

  if (tenantId) {
    const doc = {
      resetNote,
      resetBy,
      resetAt,
      targetTurns: 0,
    };
    await firebase.db.collection(path).doc(setId).set(doc, { merge: true });
  } else {
    throw new Error('Invalid tenant');
  }
};

export const onUploadComplete = (setId, file) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;
  const storagePath = storageRefs.SET_DOCUMENTS_REF(tenantId, setId);
  const filePath = `${storagePath}/${file.name}`;
  const fileObj = {
    active: true,
    fileName: file.name,
    path: filePath,
    downloadUrl: file.downloadUrl,
    createdAt: nowTimestampUTC(),
  };
  const path = collections.SET_DOCUMENTS_COLLECTION(tenantId, setId);
  await firebase.db.collection(path).add(fileObj);
};

export const uploadDocument = (setId, file) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;
  const path = collections.SETS_COLLECTION(tenantId);
  const storageRef = firebase.storage.ref();
  const storagePath = storageRefs.SET_DOCUMENTS_REF(tenantId, setId);
  const filePath = `${storagePath}/${file.name}`;
  const ref = storageRef.child(filePath);

  const snap = await ref.put(file);
  const downloadUrl = await snap.ref.getDownloadURL();

  const documentObj = {
    fileName: file.name,
    path: filePath,
    downloadUrl
  };

  await firebase.db.collection(path).doc(setId).set({ consignmentDocument: documentObj }, { merge: true });
  return documentObj;
};

export const getSetLogs = (setId, range) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;
  const path = collections.SET_LOGS_COLLECTION(tenantId, setId);

  let date;

  if (range) {
    date = moment().subtract(range, 'months').startOf('day').toDate();
  }

  let query = firebase.db.collection(path);

  if (range) {
    query = query.where('createdAt', '>', date);
  }

  const snapshot = await query.get();

  return snapshot.docs?.map((doc) => {
    const data = doc.data();
    return {
      ...data,
      id: doc.id,
      createdAt: data.createdAt ? data.createdAt.toDate() : '',
    }
  });
};

export const exportReporting = (items) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;
  const idToken = await firebase.auth.currentUser.getIdToken();

  try {
    await getFileWithParameters(urls.sets.exportTurnsReport(tenantId), idToken, 'Set turns report', 'xlsx', { items });
  } catch (err) {
    throw new Error(err);
  }
};

export const exportLookupItems = (items) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;
  const idToken = await firebase.auth.currentUser.getIdToken();

  try {
    await getFileWithParameters(urls.sets.exportLookupItems(tenantId), idToken, 'BOM lookup report', 'xlsx', { items });
  } catch (err) {
    throw new Error(err);
  }
};

export const exportExpiringItems = (items) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;
  const idToken = await firebase.auth.currentUser.getIdToken();

  try {
    await getFileWithParameters(urls.sets.exportExpiringItems(tenantId), idToken, 'Expiring items report', 'xlsx', { items });
  } catch (err) {
    throw new Error(err);
  }
};

export const exportBatchItems = (items, setId) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;
  const idToken = await firebase.auth.currentUser.getIdToken();

  try {
    await getFileWithParameters(urls.sets.exportBatchItems(tenantId), idToken, setId, 'xlsx', { items });
  } catch (err) {
    throw new Error(err);
  }
};

// Subscriptions
export const subscribeToSets = () => (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;
  const path = collections.SETS_COLLECTION(tenantId);

  return firebase
    .db
    .collection(path)
    .onSnapshot({
      error: (e) => console.error(e),
      next: (querySnapshot) => {
        let sets = [];
        querySnapshot.forEach((documentSnapshot) => {
          const id = documentSnapshot.id;
          const data = documentSnapshot.data();
          sets = [...sets, {
            ...data,
            id,
            batchItems: formatBatchItems(data.batchItems),
          }];
        });

        return dispatch(setSets(sets));
      },
    });
};

export const subscribeToSetLabels = () => (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;
  const path = collections.SET_LABELS_COLLECTION(tenantId);

  return firebase
    .db
    .collection(path)
    .onSnapshot({
      error: (e) => console.error(e),
      next: (querySnapshot) => {
        let labels = [];
        querySnapshot.forEach((documentSnapshot) => {
          const id = documentSnapshot.id;
          labels = [...labels, {
            ...documentSnapshot.data(),
            id,
          }];
        });

        return dispatch(setLabels(orderBy(labels, 'name', 'asc')));
      }
    });
};
