import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { v4 as uuid } from 'uuid';

import FormControl from '@material-ui/core/FormControl';
import InputBase from '@material-ui/core/InputBase/InputBase';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Switch from '@material-ui/core/Switch';

import ClearIcon from '@material-ui/icons/Clear';
import Visibility from '../../assets/icons/VisibilityIcon';
import VisibilityOff from '../../assets/icons/VisibilityOffIcon';

import { makeStyles, withStyles } from '@material-ui/core/styles';

const textColor = '#0b0b0b';
const placeholderColor = '#a7a7a7';

const useStyles = makeStyles((theme) => ({
  inputContainer: {
    width: '100%',
    background: '#ffffff',
    border: '1px solid #EEEEEE',
    borderRadius: '16px',
    outline: 'none',
    marginBottom: '16px',
  },
  textareaContainer: {
    width: '100%',
    background: '#ffffff',
    border: '1px solid #EEEEEE',
    borderRadius: '16px',
    outline: 'none',
    marginBottom: '16px',
  },
  inputColor: {
    width: '100%',
    background: '#ffffff',
    height: 60,
    border: '1px solid #EEEEEE',
    borderRadius: '16px',
    outline: 'none',
    marginBottom: '16px',
    '&:active': {
      outline: 'none',
      borderRadius: '16px',
    },
    '&:focus': {
      outline: 'none !important',
      borderRadius: '16px',
    },
    '&:before': {
      outline: 'none',
      borderRadius: '16px',
    }
  },
  inputContainerBorderless: {
    width: '100%',
    background: '#ffffff',
    borderRadius: '16px',
    border: '0',
    outline: 'none',
    height: '45px'
  },
  input: {
    width: '100%',
    height: '60px',
    // height: '58px',
    outline: 'none',
    paddingLeft: '16px',
  },
  textarea: {
    width: '100%',
    outline: 'none',
    padding: '12px 16px',
    lineHeight: '1.5rem',
  },
  search: {
    width: '240px',
    height: '60px',
    outline: 'none',
    border: '1.5px solid #EEEEEE',
    borderRadius: '16px',
    paddingLeft: '20px',
    paddingRight: '20px',
    fontWeight: 'normal !important',
  },
  startAdornment: {
    color: placeholderColor,
  },
  endAdornment: {
    color: placeholderColor,
  }
}));

const IOSSwitch = withStyles((theme) => ({
  root: {
    width: 42,
    height: 26,
    padding: 0,
    margin: theme.spacing(1),
  },
  switchBase: {
    padding: 1,
    '&$checked': {
      transform: 'translateX(16px)',
      color: theme.palette.common.white,
      '& + $track': {
        backgroundColor: theme.palette.primary.main,
        opacity: 1,
        border: 'none',
      },
    },
    '&$focusVisible $thumb': {
      color: theme.palette.primary.main,
      border: '6px solid #fff',
    },
  },
  thumb: {
    width: 24,
    height: 24,
  },
  track: {
    borderRadius: 26 / 2,
    //border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: theme.palette.grey[300],
    opacity: 1,
    transition: theme.transitions.create(['background-color', 'border']),
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});

const Input = (props) => {
  const {
    type,
    value,
    disabled = false,
    borderless = false,
    placeholder,
    onChange = () => {},
    handleMouseDownPassword = () => {},
    startAdornment = '',
    endAdornment = '',
    width,
    multiline = false,
    numbersOnly = false,
    maxLength,
    error = false,
    warning = false,
  } = props;

  const classes = useStyles();

  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  switch (type) {
    case 'text':
      return <FormControl
        className={borderless ? classes.inputContainerBorderless : classes.inputContainer}
        style={error ? { borderColor: '#F53434' } : (warning ? { borderColor: '#F58634' } : {})}
      >
        <InputBase
          id={`text_${uuid()}`}
          type={numbersOnly ? 'number' : 'text'}
          className={classes.input}
          style={{ color: value ? textColor : placeholderColor }}
          value={value}
          placeholder={placeholder}
          onChange={onChange}
          disabled={disabled}
          width={width}
          multiline={multiline}
          startAdornment={
            startAdornment && (
              <InputAdornment position='start'>
                <span className={classes.startAdornment}>
                  {startAdornment}
                </span>
              </InputAdornment>
            )
          }
          endAdornment={
            endAdornment && (
              <InputAdornment position='end'>
                <span className={classes.endAdornment}>
                  {endAdornment}
                </span>
              </InputAdornment>
            )
          }
          inputProps={maxLength ? { maxLength } : {}}
        />
      </FormControl>;
    case 'email':
      return <FormControl
        className={borderless ? classes.inputContainerBorderless : classes.inputContainer}
        style={error ? { borderColor: '#F53434' } : {}}
      >
        <InputBase
          id={`email_${uuid()}`}
          type='email'
          className={classes.input}
          style={{ color: value ? textColor : placeholderColor }}
          value={value}
          placeholder={placeholder}
          onChange={onChange}
          disabled={disabled}
          startAdornment={
            startAdornment && (
              <InputAdornment position='start'>
                <span className={classes.startAdornment}>
                  {startAdornment}
                </span>
              </InputAdornment>
            )
          }
        />
      </FormControl>;
    case 'password':
      return <FormControl className={borderless ? classes.inputContainerBorderless : classes.inputContainer}>
        <InputBase
          id={`password${uuid()}`}
          type={showPassword ? 'text' : 'password'}
          className={classes.input}
          style={{ color: value ? textColor : placeholderColor }}
          value={value}
          placeholder={placeholder}
          onChange={onChange}
          startAdornment={
            startAdornment && (
              <InputAdornment position='start'>
                <span className={classes.startAdornment}>
                  {startAdornment}
                </span>
              </InputAdornment>
            )
          }
          endAdornment={
            <InputAdornment position='end'>
              <IconButton
                aria-label='toggle password visibility'
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
              >
                {showPassword ? <Visibility color='secondary' /> : <VisibilityOff color='secondary' />}
              </IconButton>
            </InputAdornment>
          }
        />
      </FormControl>;
    case 'search':
      return <FormControl
        className={borderless ? classes.inputContainerBorderless : classes.inputContainer}
      >
        <InputBase
          id={`search${uuid()}`}
          type='text'
          className={classes.input}
          style={{ color: value ? textColor : placeholderColor }}
          value={value}
          placeholder={placeholder || 'Search'}
          onChange={onChange}
          disabled={disabled}
          endAdornment={
            value && (
              <InputAdornment position='end' style={{ marginRight: '8px' }}>
                <IconButton
                  size='small'
                  aria-label='toggle clear button'
                  onClick={() => onChange({ target: { value: '' } })}
                >
                  <ClearIcon fontSize='small' />
                </IconButton>
              </InputAdornment>
            )
          }
        />
      </FormControl>;
    case 'switch':
      return <FormControl
        className={borderless ? classes.inputContainerBorderless : classes.inputContainer}
      >
        <InputBase
          id={`switch_${uuid()}`}
          type='text'
          className={classes.input}
          style={{ color: value ? textColor : placeholderColor }}
          placeholder={placeholder}
          disabled
          endAdornment={(
            <InputAdornment position='start'>
              <IOSSwitch name='checkedB' onChange={(e) => onChange(e.target.checked)} checked={!!value} />
            </InputAdornment>
          )}
        />
      </FormControl>;
    case 'textarea':
      return <FormControl className={classes.textareaContainer}>
        <InputBase
          multiline
          rows={4}
          id={`text_${uuid()}`}
          type='text'
          className={classes.textarea}
          style={{ color: value ? textColor : placeholderColor }}
          value={value}
          placeholder={placeholder}
          onChange={onChange}
          disabled={disabled}
          width={width}
          inputProps={maxLength ? { maxLength } : {}}
        />
      </FormControl>;
    default:
      return <FormControl
        className={borderless ? classes.inputContainerBorderless : classes.inputContainer}
      >
        <InputBase
          id={`text_${uuid()}`}
          type='text'
          className={classes.input}
          style={{ color: value ? textColor : placeholderColor }}
          value={value}
          placeholder={placeholder}
          onChange={onChange}
          disabled={disabled}
          multiline={multiline}
          startAdornment={
            startAdornment && (
              <InputAdornment position='start'>
                <span className={classes.startAdornment}>
                  {startAdornment}
                </span>
              </InputAdornment>
            )
          }
          inputProps={maxLength ? { maxLength } : {}}
        />
      </FormControl>;
  }
};

Input.propTypes = {
  placeholder: PropTypes.string,
  handleChange: PropTypes.func,
  handleClickShowPassword: PropTypes.func,
  handleMouseDownPassword: PropTypes.func,
  showPassword: PropTypes.bool,
  type: PropTypes.oneOf(['text', 'email', 'password', 'search', 'switch', 'textarea']),
  width: PropTypes.number,
  multiline: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default React.memo(Input);
