import React from 'react';
import moment from 'moment';

import TimeIcon from '../../assets/icons/TimeIcon';

import { driveUtils } from '../../utils';

const { getFileIcon, getFileLabel } = driveUtils;

const DriveFile = (props) => {
  const {
    title,
    subtitle,
    type,
    createdAt = new Date(),
    file = {},
    previewImage = {},
  } = props;

  const onClick = () => {
    window.open(file.downloadUrl, '_blank');
  };

  return (
    <div
      className='drive-file__container'
      onClick={onClick}
    >
      <div className='drive-file-icon'>
        { previewImage && previewImage.downloadUrl ? (
          <img
            src={previewImage.downloadUrl}
            alt='preview_image'
          />
        ) : getFileIcon(type, 48)}

        <div className='drive-file-label'>
          <div className='m-r-sm d-flex'>
            {getFileIcon(type, 16, true)}
          </div>
          {getFileLabel(type)}
        </div>
      </div>
      <div className='drive-file-name__container'>
        <div className='font-size-bg font-bold'>
          {title}
        </div>
        <div className='secondary font-bold m-t-sm m-b-md'>
          {subtitle}
        </div>
        <div className='d-flex secondary font-size-sm'>
          <TimeIcon color="#c6c8c9" />
          <span className='m-l-sm'>{moment(createdAt).fromNow()}</span>
        </div>
      </div>
    </div>
  );
};

export default DriveFile;
