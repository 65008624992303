import {
  GET_CASES, SET_CASES, TOGGLE_SHIPPED, TOGGLE_RETURNED, SET_STATUS_FILTERS, SET_USAGE_FILTERS,
  TOGGLE_CLOSED, TOGGLE_CANCELED, TOGGLE_CONSIGNMENT, TOGGLE_LOAN, SET_FILTERS, SET_OVERDUE_SETS_NUMBER,
  SET_ORDER, SET_ORDER_BY, SET_PAGE, SET_ROWS_PER_PAGE, SET_SEARCH
} from '../actions/actionTypes';

const initialState = {
  list: [],
  isLoading: false,
  isLoaded: false,
  hideShipped: false,
  hideReturned: false,
  hideClosed: false,
  hideConsignment: false,
  hideLoan: false,
  showCanceled: false,
  filters: {
    surgeon: '',
    hospital: '',
  },
  usageFilters: {
    surgeon: '',
    hospital: '',
  },
  statusFilter: {
    request: true,
    booked: true,
    overdue: true,
    completed: false
  },
  overdueSets: 0,
  order: '',
  orderBy: '',
  page: 0,
  rowsPerPage: 10,
  search: '',
};

const casesReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_CASES:
      return { ...state, isLoading: true };
    case SET_CASES:
      return {
        ...state,
        list: action.cases,
        isLoaded: true,
        isLoading: false
      };
    case TOGGLE_SHIPPED:
      return {
        ...state,
        hideShipped: !state.hideShipped,
      };
    case TOGGLE_RETURNED:
      return {
        ...state,
        hideReturned: !state.hideReturned,
      };
    case TOGGLE_CLOSED:
      return {
        ...state,
        hideClosed: !state.hideClosed,
      };
    case TOGGLE_CONSIGNMENT:
      return {
        ...state,
        hideConsignment: !state.hideConsignment,
      };
    case TOGGLE_LOAN:
      return {
        ...state,
        hideLoan: !state.hideLoan,
      };
    case TOGGLE_CANCELED:
      return {
        ...state,
        showCanceled: action.value,
      };
    case SET_FILTERS:
      return {
        ...state,
        filters: {
          ...state.filters,
          ...action.filters,
        },
      };
    case SET_STATUS_FILTERS:
      return {
        ...state,
        statusFilter: {
          ...state.statusFilter,
          ...action.filter
        },
      };
    case SET_USAGE_FILTERS:
      return {
        ...state,
        usageFilters: {
          ...state.usageFilters,
          ...action.filters,
        },
      };
    case SET_OVERDUE_SETS_NUMBER:
      return {
        ...state,
        overdueSets: action.value,
      };
    case SET_ORDER:
      return {
        ...state,
        order: action.value,
      };
    case SET_ORDER_BY:
      return {
        ...state,
        orderBy: action.value,
      };
    case SET_PAGE:
      return {
        ...state,
        page: action.value,
      };
    case SET_ROWS_PER_PAGE:
      return {
        ...state,
        rowsPerPage: action.value,
      };
    case SET_SEARCH:
      return {
        ...state,
        search: action.value,
      };
    default:
      return state;
  }
};

export default casesReducer;
