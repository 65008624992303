import React, { useState, useMemo, useCallback } from 'react';
import { useDispatch } from 'react-redux';

import WarningIcon from '@material-ui/icons/Error';
import UsageIcon from '../../assets/icons/UsageIcon';
import CheckIcon from '../../assets/icons/CheckIcon';
import LinkIcon from '../../assets/icons/LinkIcon';
import EditIcon from '../../assets/icons/EditIcon';

import ProformaModal from './modals/ProformaModal';
import EditOrderModal from './modals/EditOrderModal';

import { changeProformaStatus, updateOrder, getCase } from '../../actions/casesActions';

import { setAllocationStatuses, caseUsageTypes } from '../../constants/enums';
import userRoles from '../../constants/userRoles';

const { SHIPPED, RETURNED, TRANSFERRED } = setAllocationStatuses;

const isImage = (fileName = '') => {
  const arr = fileName.split('.');
  const extension = arr.length ? arr[arr.length - 1] : '';
  return ['jpg', 'jpeg', 'png', 'svg', 'ico'].includes(extension);
};

const CaseUsageInfo = (props) => {
  const {
    caseId,
    usage,
    orderClosed,
    orderReference,
    orderAttachments = [],
    proformaSent,
    proformaValue,
    freightCost,
    editable,
    userRole,
    connectedTenantId,
    proforma,
    defaultSalesTax,
    proformaApproved,
    setsAllocation,
    referenceNumber,
    tenantColor,
  } = props;

  const dispatch = useDispatch();

  const [isProformaModalOpen, toggleProformaModal] = useState(false);
  const [isOrderModalOpen, toggleOrderModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const onProformaSendClick = () => {
    if (![userRoles.ADMIN.name, userRoles.CUSTOMER_SERVICE.name, userRoles.OPERATIONS.name, userRoles.FINANCE.name].includes(userRole) && (!editable || proformaSent || orderClosed)) {
      return;
    }

    toggleProformaModal(true);
  };

  const onProformaSend = async (value, frCost, referenceNumber) => {
    if ((!proformaSent && !orderClosed) || [userRoles.ADMIN.name, userRoles.CUSTOMER_SERVICE.name, userRoles.OPERATIONS.name, userRoles.FINANCE.name].includes(userRole)) {
      await dispatch(changeProformaStatus(caseId, true, value, connectedTenantId, frCost, referenceNumber));
      await dispatch(getCase(caseId));
      toggleProformaModal(false);
    }
  };

  const implants = useMemo(() => (
    usage.filter((item) => item.type === caseUsageTypes.implants)
  ), [usage]);

  const isApproved = useMemo(() => (
    implants.every((item) => !!item.approved)
  ), [implants]);

  const renderWarning = useCallback(() => {
    if (orderClosed) {
      return (
        <div className='d-flex'>
          <CheckIcon style={{ marginRight: 4 }} color={SHIPPED.color} />
          <span className='font-bold' style={{ color: SHIPPED.color }}>
            Closed
          </span>
        </div>
      );
    }
    return (
      <div className='d-flex'>
        {isApproved ? (
          <CheckIcon color={SHIPPED.color} style={{ marginRight: 4 }} />
        ) : (
          <WarningIcon style={{ color: TRANSFERRED.color, marginRight: 4 }} />
        )}
        <span className='font-bold' style={{ color: isApproved ? SHIPPED.color : TRANSFERRED.color }}>
          {isApproved ? 'Approved' : 'Approve Usage'}
        </span>
      </div>
    );
  }, [isApproved, orderClosed]);

  const onAttachmentClick = (attachment) => {
    if (attachment && attachment.downloadUrl) {
      window.open(attachment.downloadUrl, '_blank');
    }
  };

  const onEditOrder = async (reference, files) => {
    if (!editable) {
      return;
    }

    setLoading(true);

    try {
      const currentFiles = orderAttachments?.filter((file) => files?.find((f) => f.uploaded && f.fileName === file.fileName && f.path === file.path));
      const newFiles = files?.filter((file) => !file.uploaded);
      await dispatch(updateOrder(caseId, reference, currentFiles, newFiles, connectedTenantId));
      await dispatch(getCase(caseId));
      toggleOrderModal(false);
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const getProformaValue = useCallback((value) => {
    if (!value || !Number(value)) {
      return '';
    }

    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    });

    return formatter.format(value);
  }, [proformaSent, proformaValue]);

  const priceWithoutTax = useMemo(() => (
    proforma
      ?.filter((item) => !!item.value)?.map((item) => {
      const quantity = item.quantity || 1;
      const value = quantity * Number(item.value);

      return item.discount ? value - (value * item.discount / 100) : value;
    })
      ?.reduce((a, b) => a + b, 0)
  ), [proforma]);

  const totalTax = useMemo(() => {
    if (!defaultSalesTax) {
      return 0;
    }
    return priceWithoutTax * defaultSalesTax / 100;
  }, [priceWithoutTax, defaultSalesTax]);

  const totalValue = useMemo(() => priceWithoutTax + totalTax, [priceWithoutTax, totalTax]);

  const totalFreightCost = useMemo(() => {
    if (setsAllocation && setsAllocation.length) {
      const uniqueShipments = setsAllocation.filter((setItem, idx, arr) => arr.findIndex((item) => (item.trackingNumber === setItem.trackingNumber)) === idx);
      return uniqueShipments?.map((item) => item.freightCost || 0)?.reduce((a, b) => a + b);
    }
    return 0;
  }, [setsAllocation]);

  return (
    <div className='case-usage__container'>
      <div className='d-flex space-between'>
        <div className='d-flex'>
          <UsageIcon />
          <div className='font-size-bg font-bold m-l-md'>
            Usage Info
          </div>
        </div>

        {renderWarning()}
      </div>

      <div className='m-t-lg'>
        <div className='case-usage-info__field'>
          <div className={isApproved ? 'font-bold' : 'secondary'}>{referenceNumber || 'Proforma Status'}</div>

          {isApproved && (
            <div
              className='font-bold pointer p-l-md'
              style={{ color: proformaSent ? SHIPPED.color : RETURNED.color }}
              onClick={onProformaSendClick}
            >
              {proformaSent ?
                `Sent${proformaValue && Number(proformaValue) > 0 ? ` ${getProformaValue(proformaValue)}` : ''}` : 'Confirm Sent'}
            </div>
          )}
        </div>
      </div>

      <div className='m-t-lg'>
        <div className='case-usage-info__field--combined'>
          <div className='d-flex space-between'>
            <div className={orderReference ? 'font-bold' : 'secondary'}>
              {orderReference ? orderReference : 'Order Reference'}
            </div>
            {!!orderClosed && (
              <EditIcon color={tenantColor} onClick={() => toggleOrderModal(true)} className='pointer' />
            )}
          </div>
          {!!orderAttachments?.length && (
            <div className='d-flex'>
              {orderAttachments?.map((attachment) => (
                <div
                  style={{
                    height: 40,
                    width: 40,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginRight: 16,
                    borderRadius: 4,
                    background: '#eeeeee',
                  }}
                  key={attachment.id}
                  className={attachment && attachment.fileName ? 'font-bold pointer' : 'secondary'}
                  onClick={() => onAttachmentClick(attachment)}
                >
                  {isImage(attachment.fileName) ? (
                    <img
                      src={attachment.downloadUrl}
                      alt='preview'
                      style={{
                        height: 40,
                        width: 40,
                        borderRadius: 4,
                      }}
                    />
                  ) : (
                    <LinkIcon />
                  )}
                </div>
              ))}
            </div>
          )}
        </div>
      </div>

      <ProformaModal
        open={isProformaModalOpen}
        onClose={() => toggleProformaModal(false)}
        onSubmit={onProformaSend}
        mode={proformaSent ? 'update' : 'create'}
        initialValue={proformaValue}
        initialFreightCost={freightCost}
        initialReferenceNumber={referenceNumber}
        totalValue={proformaApproved ? totalValue : 0}
        totalFreightCost={totalFreightCost}
      />

      {!!orderReference && (
        <EditOrderModal
          open={isOrderModalOpen}
          onClose={() => toggleOrderModal(false)}
          onSubmit={onEditOrder}
          loading={loading}
          tenantColor={tenantColor}
          initialValues={{
            orderReference,
            orderAttachments,
          }}
        />
      )}
    </div>
  );
};

export default CaseUsageInfo;
