import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import moment from 'moment';

import IconButton from '@material-ui/core/IconButton';
import ArrowIcon from '@material-ui/icons/KeyboardArrowLeft';

import OverviewIcon from '../../../assets/icons/OverviewIcon';
import NotesIcon from '../../../assets/icons/NotesIcon';
import FlowIcon from '../../../assets/icons/FlowIcon';
import UsageIcon from '../../../assets/icons/UsageIcon';
import DocumentsIcon from '../../../assets/icons/DocumentIcon';
import ShippingIcon from '../../../assets/icons/ShippingIcon';
import SetIcon from '../../../assets/icons/SetIcon';
import LockOutlinedIcon from '../../../assets/icons/LockIcon';
import FormIcon from '../../../assets/icons/FormIcon';
import ProformaIcon from '../../../assets/icons/ProformaIcon';

import LoadScreen from '../../../components/load-screen';
import {
  CaseInfo, CaseNotes, CaseUsage, CaseDocuments, CaseSetsPreview,
  CaseSets, CaseUsageContainer, CaseUsageInfo, CaseHistory, CaseBookingNote
} from '../../../components/cases';
import CaseSetsChart from '../../../components/cases/sets/CaseSetsChart';
import CaseShippingInfo from '../../../components/cases/sets/CaseShippingInfo';
import CaseFlowContainer from '../../../components/cases/flow/CaseFlowContainer';
import CaseFlowChart from '../../../components/cases/flow/CaseFlowChart';
import CaseFlowOverview from '../../../components/cases/flow/CaseFlowOverview';
import CaseModal from '../../../components/cases/form/CaseModal';
import CaseFormsContainer from '../../../components/cases/forms/CaseFormsContainer';
import CaseProformaContainer from '../../../components/cases/proforma/CaseProformaContainer';
import ProformaCustomerContainer from '../../../components/cases/proforma/ProformaCustomerContainer';
import ProformaReceiptContainer from '../../../components/cases/proforma/ProformaReceiptContainer';
import ConfirmationModal from '../../../components/shared/modal/ConfirmationModal';

import {
  subscribeToCase, subscribeToCaseNotes, subscribeToCaseUsageNotes, subscribeToCaseUsage, subscribeToCaseDocuments,
  subscribeToSetsAllocation, subscribeToCaseActivity, subscribeToCaseFlow, subscribeToCaseChecklists,
  subscribeToCaseForms, updateCase, clearCase, deleteCase, getCaseGuidance, getCasePreferences, uploadDocuments,
  deleteDocument, setCaseView, setFormStep, cancelCase, turnOnEditingState, turnOffEditingState, kickOut,
  checkKitAvailability, getDispatchDocuments, subscribeToCaseProforma, getCase,
} from '../../../actions/casesActions';
import { getSurgeons } from '../../../actions/surgeonsActions';
import { getHospitals } from '../../../actions/hospitalsActions';
import { getProcedures, getProcedureOptions } from '../../../actions/proceduresActions';
import { getProducts } from '../../../actions/productsActions';
import { getKits, getSections } from '../../../actions/kitsActions';
import { getSets, getLabels } from '../../../actions/setsActions';
import { getFlows } from '../../../actions/flowsActions';
import { getForms } from '../../../actions/formsActions';
import { getItems } from '../../../actions/itemsActions';
import { subscribeToGtins } from '../../../actions/gtinReferenceActions';
import { getTags } from '../../../actions/tagsActions';

import { useAlert, useLoading, useGoogleCalendar } from '../../../hooks';

import { routes, enums, alertTypes } from '../../../constants';
import { caseStatusOptions, kitVariantTypes } from '../../../constants/enums';
import { roleNames } from '../../../constants/userRoles';

import config from '../../../config/firebase';

import './cases-page.scss';

const { CUSTOMER_SERVICE, SALES_MANAGER, MARKETING, FINANCE, SALES_REP, ADMIN, OPERATIONS, LOGISTICS } = roleNames;

const CasePage = () => {
  const history = useHistory();
  const { id } = useParams();
  const location = useLocation();
  const { search } = location;

  const queryParams = new URLSearchParams(search);

  const from = queryParams.get('from');
  const connectedTenant = queryParams.get('tenantId');
  const caseId = id.replace(':', '');

  const dispatch = useDispatch();
  const userRole = useSelector((state) => state.user.currentUser.role);
  const userId = useSelector((state) => state.user.currentUser.uid);
  const userAddresses = useSelector((state) => state.user.currentUser.deliveryAddresses);
  const users = useSelector((state) => [...state?.users?.list, ...state?.users?.connectedUsers]);

  const tenantName = useSelector((state) => state.tenant.currentTenant.businessName);
  const tenantColor = useSelector((state) => state.tenant.currentTenant.colorPrimary);
  const tenantId = useSelector((state) => state.tenant.currentTenant.id);
  const financialDetails = useSelector((state) => state.tenant.currentTenant?.financialDetails);
  const defaultSalesTax = useSelector((state) => state.tenant.currentTenant?.financialDetails?.defaultSalesTax) || 0;
  const currentTenant = useSelector((state) => state.tenant.currentTenant);
  const connectedTenantId = connectedTenant && userRole === SALES_REP ? connectedTenant : null;
  const caseWriteDisabled = useSelector((state) => state.user.currentUser.caseWriteDisabled) || !!connectedTenantId;

  const activeCase = useSelector((state) => state.activeCase.data);
  const caseNotes = useSelector((state) => state.activeCase.notes);
  const usageNotes = useSelector((state) => state.activeCase.usageNotes);
  const caseUsage = useSelector((state) => state.activeCase.usage);
  const caseDocuments = useSelector((state) => state.activeCase.documents);
  const caseSetsAllocation = useSelector((state) => state.activeCase.setsAllocation);
  const caseActivity = useSelector((state) => state.activeCase.activity);
  const view = useSelector((state) => state.activeCase.view);
  const flowSteps = useSelector((state) => state.activeCase.flow);
  const caseChecklists = useSelector((state) => state.activeCase.checklists);
  const caseForms = useSelector((state) => state.activeCase.forms);
  const caseProforma = useSelector((state) => state.activeCase.proforma);

  const surgeons = useSelector((state) => state.surgeons.list);
  const hospitals = useSelector((state) => state.hospitals.list);
  const procedures = useSelector((state) => state.procedures.list);
  const procedureOptions = useSelector((state) => state.procedures.procedureOptions);
  const products = useSelector((state) => state.products.list);
  const kits = useSelector((state) => state.kits.list);
  const sets = useSelector((state) => state.sets.list?.filter((set) => !!set.active));
  const gtinReference = useSelector((state) => state.gtinReference.list);
  const flows = useSelector((state) => state.flows.list);
  const forms = useSelector((state) => state.forms.list);
  const items = useSelector((state) => state.items.list);
  const labels = useSelector((state) => state.sets.labels);
  const tags = useSelector((state) => state.tags.list);

  const { loading, startLoading, stopLoading } = useLoading();
  const { showAlert } = useAlert();
  const { createEvent } = useGoogleCalendar();

  const [caseGuidance, setCaseGuidance] = useState([]);
  const [casePreferences, setCasePreferences] = useState([]);
  const [billOfMaterial, setBillOfMaterial] = useState({});

  const [fetching, setFetching] = useState(false);
  const [isDeleting, toggleDeleting] = useState(false);

  const [caseModalOpen, toggleCaseModal] = useState(false);
  const [kickModalOpen, toggleKickModal] = useState(false);

  const [dispatchDocuments, setDispatchDocuments] = useState([]);

  const setView = (view) => {
    dispatch(setCaseView(view));
    checkIsEditable();
  };

  const fetchCaseData = async () => {
    const data = await dispatch(getCase(caseId));
    if (data && data.active && !data.editingBy && !connectedTenantId) {
      dispatch(turnOnEditingState(caseId));
    }

    const user = users?.find((u) => u.uid === activeCase.editingBy);
    const userName = user ? `${user.firstName} ${user.lastName}` : 'Someone';

    if (data.editingBy && data?.editingBy && data.editingBy !== userId) {
      showAlert('warning', `${userName} is editing this case at the moment`);
    }
  };

  useEffect(() => {
    fetchCaseData().catch();

    // const unsubscribeToCase = dispatch(subscribeToCase(caseId, connectedTenantId));
    const unsubscribeToCaseNotes = dispatch(subscribeToCaseNotes(caseId, connectedTenantId));
    const unsubscribeToCaseUsageNotes = dispatch(subscribeToCaseUsageNotes(caseId, connectedTenantId));
    const unsubscribeToCaseUsage = dispatch(subscribeToCaseUsage(caseId, connectedTenantId));
    const unsubscribeToCaseDocuments = dispatch(subscribeToCaseDocuments(caseId, connectedTenantId));
    const unsubscribeToSetsAllocation = dispatch(subscribeToSetsAllocation(caseId, connectedTenantId));
    const unsubscribeToCaseActivity = dispatch(subscribeToCaseActivity(caseId, connectedTenantId));
    const unsubscribeToCaseFlow = dispatch(subscribeToCaseFlow(caseId, connectedTenantId));
    const unsubscribeToGtins = dispatch(subscribeToGtins(connectedTenantId));
    const unsubscribeToCaseChecklists = dispatch(subscribeToCaseChecklists(caseId, connectedTenantId));
    const unsubscribeToCaseForms = dispatch(subscribeToCaseForms(caseId));
    const unsubscribeToCaseProforma = dispatch(subscribeToCaseProforma(caseId));

    onLoad().catch(console.error);

    if (!connectedTenantId) {
      window.addEventListener('beforeunload', removeEditingBy);
      window.addEventListener('unload', removeEditingBy);

      dispatch(checkKitAvailability(caseId)).catch(console.error);
    }

    return () => {
      dispatch(clearCase());

      if (!connectedTenantId) {
        dispatch(turnOffEditingState(caseId)).catch(console.error);
        window.removeEventListener('beforeunload', removeEditingBy);
        window.removeEventListener('unload', removeEditingBy);
      }

      unsubscribeToCaseNotes();
      unsubscribeToCaseUsageNotes();
      unsubscribeToCaseUsage();
      unsubscribeToCaseDocuments();
      unsubscribeToSetsAllocation();
      unsubscribeToCaseActivity();
      unsubscribeToCaseFlow();
      unsubscribeToGtins();
      unsubscribeToCaseChecklists();
      unsubscribeToCaseForms();
      unsubscribeToCaseProforma();

      // if (unsubscribeToCase && typeof unsubscribeToCase === 'function') {
      //   unsubscribeToCase();
      // }
    };
  }, []);

  // useEffect(() => {
  //   if (activeCase && activeCase.active && activeCase.id && !activeCase.editingBy && !connectedTenantId) {
  //     dispatch(turnOnEditingState(caseId)).catch(console.error);
  //   }
  // }, [activeCase]);

  // useEffect(() => {
  //   if (!connectedTenantId) {
  //     dispatch(turnOffEditingState(caseId)).catch(console.error);
  //   }
  // }, [location]);

  const removeEditingBy = () => {
    if (!connectedTenantId) {
      dispatch(turnOffEditingState(caseId)).catch(console.error);
    }
  };

  const onLoad = async () => {
    startLoading()
    try {
      if (connectedTenantId || !surgeons?.length || !procedures?.length || !kits?.length || !sets?.length) {
        await Promise.all([
          dispatch(getSurgeons(connectedTenantId, true)),
          dispatch(getProcedures(connectedTenantId, true)),
          dispatch(getProcedureOptions(connectedTenantId, true)),
          dispatch(getProducts(connectedTenantId, true)),
          dispatch(getKits(connectedTenantId, true)),
          dispatch(getSets(connectedTenantId, true)),
          dispatch(getForms()),
        ]);
      }

      if (!tags?.length) {
        dispatch(getTags(connectedTenantId, true));
      }
      if (!labels?.length) {
        dispatch(getLabels(connectedTenantId, true));
      }
      if (!flows?.length) {
        dispatch(getFlows(connectedTenantId, true));
      }

      if (connectedTenantId) {
        await dispatch(getHospitals(connectedTenantId, true));
        await dispatch(getItems(false, connectedTenantId));
      }

      const docs = await dispatch(getDispatchDocuments(caseId));
      if (docs && docs.length) {
        setDispatchDocuments(docs?.map((doc) => ({ ...doc.file, id: doc.id, title: doc.title })));
      }
    } catch (err) {
      console.error(err);
    } finally {
      stopLoading();
    }
  };

  useEffect(() => {
    getBillOfMaterial(caseSetsAllocation).catch(console.error);
  }, [caseSetsAllocation]);

  const getBillOfMaterial = async (sets) => {
    const result = {};

    for (const set of sets) {
      if (set.kit) {
        const response = await dispatch(getSections(set.kit));
        result[set.kit] = response || [];
      }
    }

    setBillOfMaterial(result);
  }

  const datePassed = useMemo(() => {
    const today = moment();
    const date = moment(activeCase.date).endOf('day');

    return today.isAfter(date);
  }, [activeCase]);

  const onUpdate = async (formData) => {
    try {
      const isEditable = await checkIsEditable(true);
      if (isEditable) {
        await dispatch(updateCase(formData.id, formData));
        await dispatch(getCase(caseId));
        showAlert(alertTypes.SUCCESS, 'Case has been successfully updated');
        dispatch(setFormStep(1));
      }
    } catch (err) {
      console.error(err);
    }
  };

  const onDelete = async () => {
    toggleDeleting(true);

    try {
      const isEditable = await checkIsEditable(true);
      if (isEditable) {
        await dispatch(deleteCase(id));
        history.push(routes.CASES);
      }
    } catch (err) {
      console.error(err);
    } finally {
      toggleDeleting(false);
    }
  };

  const onCancelCase = async () => {
    toggleDeleting(true);

    try {
      const isEditable = await checkIsEditable(true);
      if (isEditable) {
        await dispatch(cancelCase(id));
        history.push(routes.CASES);
      }
    } catch (err) {
      console.error(err);
    } finally {
      toggleDeleting(false);
    }
  };

  const onCaseGuidanceModalOpen = async () => {
    setFetching(true);

    try {
      const files = await dispatch(getCaseGuidance());
      setCaseGuidance(files);
    } catch (err) {
      console.error(err);
    } finally {
      setFetching(false);
    }
  };

  const onCasePreferencesModalOpen = async () => {
    setFetching(true);

    try {
      const preferences = await dispatch(getCasePreferences());
      setCasePreferences(preferences);
    } catch (err) {
      console.error(err);
    } finally {
      setFetching(false);
    }
  };

  const onUploadDocuments = (files) => {
    dispatch(uploadDocuments(id, files));
  };

  const onDeleteDocument = (fileId) => {
    dispatch(deleteDocument(id, fileId));
  };

  const onEditClick = (step = 2) => {
    dispatch(setFormStep(step));
    toggleCaseModal(true);
  };

  const onClose = () => {
    toggleCaseModal(false);
    dispatch(setFormStep(0));
  };

  const handleEditClick = (callback) => {
    if (editingBy) {
      showAlert('warning', `${editingBy} is editing this case at the moment`);
    } else {
      callback();
    }
  };

  const onBackClick = () => {
    if (from === 'shipping') {
      return history.push(routes.KITS);
    } else if (from === 'usage') {
      return history.push(routes.USAGE);
    }
    history.push(routes.CASES);
  };

  const onAddToCalendar = async () => {
    const date = moment(activeCase?.date)?.format('YYYY-MM-DD');

    const arr = activeCase?.id?.split('-');
    const caseId = arr.length > 1 ? `${arr[0]}-${arr[1]}` : activeCase?.id;

    const hospital = hospitals?.find((h) => h.id === activeCase?.hospital);
    const procedure = procedures?.find((p) => p.id === activeCase?.procedure);
    const surgeon = surgeons?.find((s) => s.id === activeCase?.surgeon);
    const surgeonName = `${surgeon?.title} ${surgeon?.firstName} ${surgeon?.lastName}`;
    const url = `${config.url}${routes.CASES}/${activeCase?.id}`;
    const description = `Booked case from ${tenantName}.\nHospital: ${hospital?.name}.\nProcedure: ${procedure?.name}.\nCase ID: ${caseId}\n\n${url}`;
    const location = [hospital?.street, hospital?.city, hospital?.state, hospital?.postCode, hospital?.country].filter((v) => !!v).join(', ');

    const event = {
      summary: surgeonName,
      description,
      location,
      start: {
        date: date,
      },
      end: {
        date: date,
      }
    };

    await createEvent(event);
  };

  const shipDate = useMemo(() => {
    const hospital = hospitals?.find((h) => h.id === activeCase.hospital);
    const leadDays = hospital?.leadDays || 3;
    return moment(activeCase.date).subtract(leadDays, 'days');
  }, [activeCase, hospitals]);

  const viewOptions = useMemo(() => ({
    overview: { label: 'Overview', value: 'OVERVIEW', icon: <OverviewIcon /> },
    notes: { label: 'Notes', value: 'NOTES', icon: <NotesIcon /> },
    usage: { label: 'Usage', value: 'USAGE', icon: <UsageIcon /> },
    documents: { label: 'Documents', value: 'DOCUMENTS', icon: <DocumentsIcon /> },
    sets: {
      label: activeCase?.kitVariant === kitVariantTypes.consignment ? 'Sets' : 'Shipping',
      value: 'SETS',
      icon: activeCase?.kitVariant === kitVariantTypes.consignment ? <SetIcon /> : <ShippingIcon />
    },
    flows: { label: 'Flow', value: 'FLOW', icon: <FlowIcon /> },
    forms: { label: 'Forms', value: 'FORMS', icon: <FormIcon /> },
    proforma: { label: 'Proforma', value: 'PROFORMA', icon: <ProformaIcon /> }
  }), [activeCase?.kitVariant]);

  const renderView = () => {
    switch (view) {
      case viewOptions.notes.value:
        return (
          <CaseNotes
            notes={caseNotes}
            caseId={activeCase.id}
            connectedTenantId={connectedTenantId}
            tenantColor={tenantColor}
            userId={userId}
            userRole={userRole}
          />
        );
      case viewOptions.usage.value:
        return (
          <CaseUsage
            usage={caseUsage}
            caseId={activeCase.id}
            status={activeCase.status}
            orderClosed={activeCase.orderClosed}
            usageNotes={usageNotes}
            connectedTenantId={connectedTenantId}
            userId={userId}
            userRole={userRole}
            tenantColor={tenantColor}
          />
        );
      case viewOptions.documents.value:
        return (
          <CaseDocuments
            documents={caseDocuments || []}
            onUpload={onUploadDocuments}
            onDelete={onDeleteDocument}
            caseId={activeCase.id}
            tenantId={tenantId}
            tenantColor={tenantColor}
            connectedTenantId={connectedTenantId}
          />
        );
      case viewOptions.sets.value:
        return (
          <CaseSetsChart
            date={activeCase.date}
            datePassed={datePassed}
            setsAllocation={caseSetsAllocation}
            shipDate={shipDate}
            noEquipmentToShip={activeCase.noEquipmentToShip}
            status={activeCase.status}
            deliveryAddress={activeCase.deliveryAddress}
            kitVariant={activeCase?.kitVariant}
          />
        );
      case viewOptions.flows.value:
        if (
          (activeCase?.status === caseStatusOptions.request || activeCase?.status === caseStatusOptions.booked)
          && activeCase.flow
        ) {
          return (
            <CaseFlowChart
              activeCase={activeCase}
              steps={flowSteps}
            />
          );
        }
        return null;
      case viewOptions.proforma.value:
        return (
          <ProformaCustomerContainer
            caseId={caseId}
            deliveryAddress={activeCase?.deliveryAddress}
            billingAddress={activeCase?.billingAddress}
            tenantColor={tenantColor}
          />
        );
      case viewOptions.forms.value:
        return null;
      default:
        return null;
    }
  };

  const getInitialValues = useCallback((data) => {
    if (!data || !data.id) {
      return {};
    }

    const kits = data.kits ? (
      data.kits.map((kitId) => {
        const allocation = caseSetsAllocation.find((item) => item.kit === kitId && !item.additional);
        return {
          id: kitId,
          quantity: allocation && allocation.quantity ? allocation.quantity : 1,
          status: allocation && allocation.status ? allocation.status : '',
        };
      })
    ) : [];

    const additionalKits = data.additionalKits ? (
      data.additionalKits.map((kitId) => {
        const allocation = caseSetsAllocation.find((item) => item.kit === kitId && item.additional);
        return {
          id: kitId,
          quantity: allocation && allocation.quantity ? allocation.quantity : 1,
          status: allocation && allocation.status ? allocation.status : '',
        };
      })
    ) : [];

    const items = data.items ? (
      data.items.map((itemId) => {
        const allocation = caseSetsAllocation.find((item) => item.itemId === itemId && !item.additional);
        return {
          id: itemId,
          quantity: allocation && allocation.quantity ? allocation.quantity : 1,
          status: allocation && allocation.status ? allocation.status : '',
        };
      })
    ) : [];

    const additionalItems = data.additionalItems ? (
      data.additionalItems.map((itemId) => {
        const allocation = caseSetsAllocation.find((item) => item.itemId === itemId && item.additional);
        return {
          id: itemId,
          quantity: allocation && allocation.quantity ? allocation.quantity : 1,
          status: allocation && allocation.status ? allocation.status : '',
        };
      })
    ) : [];

    return { ...data, kits, items, additionalKits, additionalItems };
  }, [activeCase, caseSetsAllocation]);

  const formattedId = useMemo(() => {
    if (!activeCase) {
      return ''
    }
    const arr = activeCase?.id?.split('-');
    return arr?.length > 1 ? `${arr[0]}-${arr[1]}` : activeCase?.id;
  }, [activeCase]);

  const editingBy = useMemo(() => {
    if (!activeCase?.editingBy || activeCase?.editingBy === userId) {
      return null;
    }
    const user = users?.find((u) => u.uid === activeCase.editingBy);
    return user ? `${user.firstName} ${user.lastName}` : 'Someone';
  }, [activeCase.editingBy, userId, users]);

  // useEffect(() => {
  //   if (editingBy && activeCase?.editingBy && activeCase.editingBy !== userId) {
  //     showAlert('warning', `${editingBy} is editing this case at the moment`);
  //   }
  // }, [editingBy, activeCase]);

  const onKickOut = async () => {
    await dispatch(kickOut(caseId, connectedTenantId));
    await fetchCaseData();
    toggleKickModal(false);
  };

  const checkIsEditable = async (withAlert) => {
    const data = await dispatch(getCase(caseId));
    const user = users?.find((u) => u.uid === activeCase.editingBy);
    const userName = user ? `${user.firstName} ${user.lastName}` : '';

    if (withAlert && data?.editingBy && data?.editingBy !== userId) {
      showAlert('warning', `${userName || 'Someone'} is editing this case at the moment`);
    }

    if (!withAlert && data?.editingBy && data?.editingBy !== userId && activeCase?.editingBy === userId) {
      showAlert('warning', `Edit permissions have been passed to ${userName || 'another user'} for this case`);
    }

    return !data?.editingBy || data?.editingBy === userId;
  };

  return (
    <div className='page-container'>
      {loading && <LoadScreen />}
      {activeCase && activeCase.id && (
        <>
          <div className='d-flex space-between'>
            <div className='page-title'>
              <IconButton
                color='primary'
                className='m-r-md'
                onClick={onBackClick}
                size='small'
              >
                <ArrowIcon fontSize='large' color='primary' />
              </IconButton>
              Case
              <span className='page-subtitle'>{formattedId}</span>
            </div>
            {editingBy && (
              <div
                className='editing-by-badge pointer'
                onClick={() => {
                  toggleKickModal(true);
                }}
              >
                <LockOutlinedIcon color='#F97032' />
                {editingBy === 'Someone' ? 'Someone is editing this case' : editingBy}
              </div>
            )}
          </div>
          <div className='case-page__body'>
            <div>
              <CaseInfo
                activeCase={activeCase}
                surgeons={surgeons}
                hospitals={hospitals}
                procedures={procedures}
                procedureOptions={procedureOptions}
                products={products}
                kits={kits}
                onDelete={onDelete}
                onCancelCase={onCancelCase}
                fetching={fetching}
                isDeleting={isDeleting}
                caseGuidance={caseGuidance}
                casePreferences={casePreferences}
                onCaseGuidanceModalOpen={onCaseGuidanceModalOpen}
                onCasePreferencesModalOpen={onCasePreferencesModalOpen}
                view={view}
                setView={setView}
                viewOptions={viewOptions}
                minimized={[viewOptions.sets.value, viewOptions.usage.value, viewOptions.flows.value, viewOptions.forms.value, viewOptions.proforma.value].includes(view)}
                caseSetsAllocation={caseSetsAllocation}
                onEditClick={() => handleEditClick(() => toggleCaseModal(true))}
                caseWriteDisabled={caseWriteDisabled}
                tenantColor={tenantColor}
                onAddKitsClick={() => handleEditClick(() => onEditClick(2))}
                users={users}
                userRole={userRole}
                onAddToCalendar={onAddToCalendar}
                additional={activeCase?.kitVariant === kitVariantTypes.consignment && !!caseSetsAllocation?.filter((allocation) => !!allocation.additional)?.length}
              />
            </div>

            {view === viewOptions.overview.value ? (
              <>
                <div className='d-flex direction-column m-l-md m-r-md'>
                  <CaseNotes
                    notes={caseNotes}
                    preview
                    onPreviewClick={() => setView(viewOptions.notes.value)}
                    caseId={activeCase.id}
                    bookingNote={activeCase?.bookingNote}
                    connectedTenantId={connectedTenantId}
                    tenantColor={tenantColor}
                    userId={userId}
                    userRole={userRole}
                  />
                  <CaseDocuments
                    documents={caseDocuments || []}
                    onUpload={onUploadDocuments}
                    onDelete={onDeleteDocument}
                    caseId={activeCase.id}
                    tenantId={tenantId}
                    tenantColor={tenantColor}
                    preview
                    onPreviewClick={() => setView(viewOptions.documents.value)}
                    connectedTenantId={connectedTenantId}
                  />
                </div>
                <div className='d-flex direction-column m-l-md'>
                  <CaseUsage
                    usage={caseUsage}
                    usageNotes={usageNotes}
                    caseId={activeCase.id}
                    status={activeCase.status}
                    preview
                    onPreviewClick={() => setView(viewOptions.usage.value)}
                    userId={userId}
                    userRole={userRole}
                    tenantColor={tenantColor}
                  />
                  <CaseSetsPreview
                    sets={caseSetsAllocation}
                    onPreviewClick={() => setView(viewOptions.sets.value)}
                    shipDate={shipDate}
                    datePassed={datePassed}
                    kitVariant={activeCase?.kitVariant}
                    isWarning={activeCase?.warningKits && !!activeCase?.warningKits?.length}
                  />
                </div>
              </>
            ) : (
              <>
                <div
                  className='d-flex direction-column m-l-md m-r-md'
                  style={{
                    flex: view === viewOptions.sets.value || view === viewOptions.flows.value ? 0.4 : 0.3
                  }}
                >
                  {renderView()}
                </div>
                <div className='d-flex direction-column m-l-md'>
                  {view === viewOptions.usage.value && activeCase.status === caseStatusOptions.completed && (
                    <CaseUsageInfo
                      caseId={caseId}
                      usage={caseUsage}
                      orderClosed={activeCase.orderClosed}
                      orderReference={activeCase.orderReference}
                      orderAttachments={activeCase.orderAttachment ? [activeCase.orderAttachment] : activeCase.orderAttachments}
                      proformaSent={activeCase.proformaSent}
                      proformaValue={activeCase.proformaValue}
                      freightCost={activeCase.freightCost}
                      editable={![SALES_MANAGER, MARKETING, FINANCE].includes(userRole)}
                      userRole={userRole}
                      connectedTenantId={connectedTenantId}
                      proforma={caseProforma}
                      defaultSalesTax={defaultSalesTax}
                      proformaApproved={activeCase?.proformaApproved}
                      referenceNumber={activeCase?.referenceNumber}
                      setsAllocation={caseSetsAllocation}
                      tenantColor={tenantColor}
                    />
                  )}

                  {view === viewOptions.proforma.value && activeCase?.proformaGenerated && (
                    <ProformaReceiptContainer
                      proforma={caseProforma}
                      defaultSalesTax={defaultSalesTax}
                      proformaApproved={activeCase?.proformaApproved}
                      currency={financialDetails?.currency}
                    />
                  )}

                  {view === viewOptions.sets.value && (
                    <CaseShippingInfo
                      activeCase={activeCase}
                      tenantColor={tenantColor}
                      editable={![CUSTOMER_SERVICE, SALES_MANAGER, MARKETING, FINANCE].includes(userRole) && !editingBy && !connectedTenantId}
                    />
                  )}
                </div>
              </>
            )}
          </div>

          {view === viewOptions.sets.value && activeCase.status !== enums.caseStatusOptions.request && (
            <>
              {activeCase?.bookingNote && (
                <CaseBookingNote bookingNote={activeCase.bookingNote} />
              )}
              <CaseSets
                setsAllocation={caseSetsAllocation}
                kits={kits}
                sets={sets}
                activeCase={activeCase}
                datePassed={datePassed}
                onEditClick={() => onEditClick(2)}
                editable={[ADMIN, OPERATIONS, LOGISTICS].includes(userRole) && !editingBy && !connectedTenantId}
                caseChecklists={caseChecklists}
                billOfMaterial={billOfMaterial}
                items={items}
                labels={labels}
                editingBy={editingBy}
                dispatchDocuments={dispatchDocuments}
                tenant={currentTenant}
                hospitals={hospitals}
                surgeons={surgeons}
                procedures={procedures}
                procedureOptions={procedureOptions}
                caseActivity={caseActivity}
                users={users}
                title={activeCase.kitVariant === kitVariantTypes.consignment ? 'Consignment' : (!!activeCase.kitPreference ? 'Preferences' : 'Shipping List')}
                connectedTenantId={connectedTenantId}
                userRole={userRole}
              />
              {activeCase.kitVariant === kitVariantTypes.loan && (!!activeCase.kitPreference || !!caseSetsAllocation?.filter((allocation) => !!allocation.additional)?.length) && (
                <CaseSets
                  additional
                  setsAllocation={caseSetsAllocation?.filter((allocation) => !!allocation.additional)}
                  kits={kits}
                  sets={sets}
                  activeCase={activeCase}
                  datePassed={datePassed}
                  onEditClick={() => onEditClick(3)}
                  editable={[ADMIN, OPERATIONS, LOGISTICS].includes(userRole) && !editingBy && !connectedTenantId}
                  caseChecklists={caseChecklists}
                  billOfMaterial={billOfMaterial}
                  items={items}
                  labels={labels}
                  editingBy={editingBy}
                  dispatchDocuments={dispatchDocuments}
                  tenant={currentTenant}
                  hospitals={hospitals}
                  surgeons={surgeons}
                  procedures={procedures}
                  procedureOptions={procedureOptions}
                  caseActivity={caseActivity}
                  users={users}
                  title='Additional'
                  connectedTenantId={connectedTenantId}
                  userRole={userRole}
                />
              )}
              {activeCase.kitVariant === kitVariantTypes.consignment && !!caseSetsAllocation?.filter((allocation) => !!allocation.additional)?.length && (
                <CaseSets
                  additional
                  setsAllocation={caseSetsAllocation?.filter((allocation) => !!allocation.additional)}
                  kits={kits}
                  sets={sets}
                  activeCase={activeCase}
                  datePassed={datePassed}
                  onEditClick={(step) => onEditClick(3)}
                  editable={[ADMIN, OPERATIONS, LOGISTICS].includes(userRole) && !editingBy && !connectedTenantId}
                  caseChecklists={caseChecklists}
                  billOfMaterial={billOfMaterial}
                  items={items}
                  labels={labels}
                  editingBy={editingBy}
                  dispatchDocuments={dispatchDocuments}
                  tenant={currentTenant}
                  hospitals={hospitals}
                  surgeons={surgeons}
                  procedures={procedures}
                  procedureOptions={procedureOptions}
                  title='Additional'
                  connectedTenantId={connectedTenantId}
                  userRole={userRole}
                />
              )}
            </>
          )}

          {view === viewOptions.usage.value && (
            <CaseUsageContainer
              activeCase={activeCase}
              hospitals={hospitals}
              surgeons={surgeons}
              scans={caseUsage}
              gtinReference={gtinReference}
              users={users}
              caseId={caseId}
              status={activeCase.status}
              orderClosed={activeCase.orderClosed}
              orderReference={activeCase.orderReference}
              editable={![SALES_MANAGER, MARKETING, FINANCE].includes(userRole) && !editingBy}
              checklists={caseChecklists}
              setsAllocation={caseSetsAllocation}
              kits={kits}
              items={items}
              connectedTenantId={connectedTenantId}
              proformaSent={activeCase?.proformaSent}
              proformaGenerated={activeCase?.proformaGenerated}
              goToProforma={() => setView(viewOptions.proforma.value)}
              tags={tags}
            />
          )}

          {view === viewOptions.overview.value && !!activeCase?.bookingNote && (
            <CaseBookingNote bookingNote={activeCase?.bookingNote} />
          )}

          {view === viewOptions.overview.value && !!activeCase.flow && (
            <CaseFlowOverview
              activeCase={activeCase}
              steps={flowSteps}
              flows={flows}
              procedures={procedures}
              tenantId={tenantId}
              tenantColor={tenantColor}
              users={users}
              userRole={userRole}
            />
          )}

          {view === viewOptions.overview.value && (
            <CaseHistory
              activity={caseActivity}
              users={users}
            />
          )}

          {view === viewOptions.flows.value && (
            <CaseFlowContainer
              activeCase={activeCase}
              steps={flowSteps}
              flows={flows}
              procedures={procedures}
              tenantId={tenantId}
              tenantColor={tenantColor}
              users={users}
              userRole={userRole}
            />
          )}

          {view === viewOptions.forms.value && (
            <CaseFormsContainer
              activeCase={activeCase}
              caseForms={connectedTenantId ? [] : caseForms}
              users={users}
              forms={forms}
            />
          )}

          {view === viewOptions.proforma.value && (
            <CaseProformaContainer
              activeCase={activeCase}
              proforma={caseProforma}
              caseId={caseId}
              status={activeCase?.status}
              proformaApproved={activeCase?.proformaApproved}
              hospitals={hospitals}
              surgeons={surgeons}
              deliveryAddress={activeCase?.deliveryAddress}
              billingAddress={activeCase?.billingAddress}
            />
          )}
        </>
      )}

      <CaseModal
        open={caseModalOpen}
        onClose={onClose}
        mode='update'
        activeCase={getInitialValues(activeCase)}
        hospitals={hospitals}
        surgeons={surgeons}
        procedures={procedures}
        procedureOptions={procedureOptions}
        products={products}
        kits={kits}
        onSubmit={onUpdate}
        userRole={userRole}
        userAddresses={userAddresses}
        flows={flows}
        items={items}
        sets={sets}
        editingBy={editingBy}
        currentTenant={currentTenant}
      />

      <ConfirmationModal
        open={kickModalOpen}
        onClose={() => toggleKickModal(false)}
        onSubmit={onKickOut}
        title='Takeover editing control of this case'
        text='Are you sure you want to takeover editing control and disable the current user from editing this case? Please note that any unconfirmed changes they have made will be lost.'
        submitText='Confirm'
      />
    </div>
  );
};

export default CasePage;
