import React, { useState } from 'react';

import Modal from '../../shared/modal';
import Input from '../../shared/Input';
import Dropzone from '../../shared/dropzone';

const CloseOrderModal = ({ open, onClose, onSubmit, loading, tenantColor }) => {
  const [reference, setReference] = useState('');
  const [files, setFiles] = useState([]);

  const onUpload = (newFiles) => {
    setFiles([
      ...files,
      ...newFiles.filter((file) => !files.map((f) => f.name).includes(file.name)),
    ]);
  };

  const onRemove = (fileName) => {
    setFiles([...files].filter((item) => item.name !== fileName));
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      title='Close Order'
      fullWidth
      closeButton='Cancel'
      submitButton='Close Order'
      onSubmit={() => onSubmit(reference, files)}
      disabled={loading || !reference}
      loading={loading}
    >
      <div className='close-order-modal__container'>
        <div className='p-b-md'>
          <div>
            With all usage approved you can now set this order to closed.
          </div>
          <div>
            You can provide an order reference below.
          </div>
        </div>

        <div className='m-t-lg'>
          <div className='secondary m-b-sm'>Order Reference</div>
          <Input
            value={reference}
            placeholder='Add order reference'
            type='text'
            onChange={(e) => setReference(e.target.value)}
          />
        </div>

        <div>
          <div className='secondary m-b-sm'>Order Attachments</div>
          <Dropzone
            files={files}
            onUpload={onUpload}
            onRemove={onRemove}
            tenantColor={tenantColor}
            withoutProgress
          />
        </div>
      </div>
    </Modal>
  );
};

export default CloseOrderModal;
