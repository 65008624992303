import { orderBy } from 'lodash';
import { v4 as uuid } from 'uuid';
import moment, { isMoment } from 'moment';

import {
  GET_CASE, GET_CASES,
  SET_CASE, SET_CASES,
  GET_CASE_NOTES, SET_CASE_NOTES,
  GET_CASE_USAGE, SET_CASE_USAGE, SET_CASE_USAGE_NOTES,
  GET_CASE_DOCUMENTS, SET_CASE_DOCUMENTS,
  GET_CASE_SETS_ALLOCATION, SET_CASE_SETS_ALLOCATION,
  GET_CASE_ACTIVITY, SET_CASE_ACTIVITY,
  GET_CASE_CHECKLISTS, SET_CASE_CHECKLISTS,
  CLEAR_CASE, TOGGLE_SHIPPED, TOGGLE_RETURNED, SET_VIEW, SET_STEP, TOGGLE_LOAN,
  TOGGLE_CLOSED, TOGGLE_CANCELED, TOGGLE_CONSIGNMENT, SET_FILTERS, SET_USAGE_FILTERS,
  GET_CASE_FLOW, SET_CASE_FLOW, SET_STATUS_FILTERS, SET_OVERDUE_SETS_NUMBER, SET_CASE_FORMS,
  SET_CASE_PROFORMA, SET_ORDER, SET_ORDER_BY, SET_PAGE, SET_ROWS_PER_PAGE, SET_SEARCH
} from './actionTypes';

import firebase, { collections, storageRefs } from '../firebase';

import { getSteps } from './flowsActions';

import { nowTimestampUTC, fromMomentToTimestampDay } from '../utils/date';
import { getFile, getFileWithParameters, remove } from '../utils/api';
import { chunkArray } from '../utils/utils';

import urls from '../constants/urls';
import {
  caseStatusOptions, caseUsageTypes, implantScanTypes, notificationTypes,
  setAllocationStatuses, flowStepStatuses, kitVariantTypes, formStatuses,
} from '../constants/enums';
import { roleNames, territoryRoles } from '../constants/userRoles';
import { getHospitals } from './hospitalsActions';

const { ADMIN, OPERATIONS, CUSTOMER_SERVICE, LOGISTICS, SALES_MANAGER, MARKETING, FINANCE } = roleNames;

// Cases

export const setCases = (cases) => ({ type: SET_CASES, cases });

const mapCases = (doc) => {
  const data = doc.data();
  return {
    ...data,
    id: doc.id,
    createdAt: data && data.createdAt ? data.createdAt.toDate() : '',
    updatedAt: data && data.updatedAt ? data.updatedAt.toDate() : '',
    completedAt: data && data.completedAt ? data.completedAt.toDate() : '',
    date: data && data.date ? data.date.toDate() : '',
    proformaSentAt: data && data.proformaSentAt ? data.proformaSentAt.toDate() : '',
    proformaDate: data && data.proformaDate ? data.proformaDate.toDate() : '',
    collectionDate: data && data.collectionDate ? data.collectionDate.toDate() : '',
  };
};

export const getCases = () => async (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;
  const currentUser = state.user.currentUser;
  const path = collections.CASES_COLLECTION(tenantId);

  const hospitals = state.hospitals?.list?.length ? state.hospitals?.list : await dispatch(getHospitals(tenantId));

  dispatch({ type: GET_CASES });

  let query;
  let cases = [];

  if ([ADMIN, OPERATIONS, CUSTOMER_SERVICE, LOGISTICS, SALES_MANAGER, MARKETING, FINANCE].includes(currentUser.role)) {
    query = firebase
      .db
      .collection(path)

    const snapshot = await query.get();
    cases = snapshot?.docs.map(mapCases);
  } else {
    if (!currentUser.assignedSurgeons || !currentUser.assignedSurgeons.length) {
      return dispatch(setCases([]));
    }

    const assignedSurgeons = currentUser.assignedSurgeons || ['empty'];
    const chunks = chunkArray(assignedSurgeons);
    const snapshots = [];

    for await (const snap of chunks?.map(
      async (chunk) =>
        await firebase
          .db
          .collection(path)
          .where('surgeon', 'in', chunk)
          .get()
    )) {
      snapshots?.push(snap);
    }

    let docs = [];
    snapshots?.forEach((snap) => {
      docs = [...docs, ...snap?.docs];
    });

    if (territoryRoles?.includes(currentUser.role) && !!currentUser.territoryVisibility) {
      const userHospitals = hospitals?.filter((h) => h.territories?.some((territory) => currentUser?.assignedTerritories?.includes(territory)));
      cases = cases?.filter((item) => userHospitals?.map((h) => h.id)?.includes(item.hospital));
    }

    cases = docs?.map(mapCases);
  }

  return dispatch(setCases(orderBy(cases, 'date', 'asc')));
};

export const getConnectedCases = () => async (dispatch, getState) => {
  const state = getState();
  const currentUser = state.user.currentUser;
  const connectedTenants = currentUser?.connectedTenants && currentUser?.connectedTenants?.length ? currentUser?.connectedTenants : ['empty'];

  const querySnapshot = await firebase
    .db
    .collectionGroup('cases')
    .where('createdBy', '==', currentUser.uid)
    .where('tenantId', 'in', connectedTenants)
    .get();

  const cases = querySnapshot?.docs?.map(doc => {
    const data = doc?.data();
    return {
      ...data,
      id: doc.id,
      docId: doc.id,
      parentId: doc.ref.parent.parent.id,
      createdAt: data && data.createdAt ? data.createdAt.toDate() : '',
      updatedAt: data && data.updatedAt ? data.updatedAt.toDate() : '',
      date: data && data.date ? data.date.toDate() : '',
      proformaSentAt: data && data.proformaSentAt ? data.proformaSentAt.toDate() : '',
      proformaDate: data && data.proformaDate ? data.proformaDate.toDate() : '',
      collectionDate: data && data.collectionDate ? data.collectionDate.toDate() : '',
    };
  });

  return orderBy(cases, 'date', 'asc');
};

export const getCasesWithSetsAllocation = (dateRange) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;
  const currentUser = state.user.currentUser;
  const path = collections.CASES_COLLECTION(tenantId);

  dispatch({ type: GET_CASES });

  let query;
  let docs = [];

  if ([ADMIN, OPERATIONS, CUSTOMER_SERVICE, LOGISTICS, SALES_MANAGER, MARKETING, FINANCE].includes(currentUser.role)) {
    query = firebase
      .db
      .collection(path)

    if (dateRange?.minDate) {
      query = query.where('date', '>', fromMomentToTimestampDay(dateRange.minDate));
    }
    if (dateRange?.maxDate) {
      query = query.where('date', '<', fromMomentToTimestampDay(dateRange.maxDate));
    }

    const snapshot = await query.get();
    docs = snapshot?.docs;
  } else {
    if (!currentUser.assignedSurgeons || !currentUser.assignedSurgeons.length) {
      return dispatch(setCases([]));
    }

    const assignedSurgeons = currentUser.assignedSurgeons || ['empty'];
    const chunks = chunkArray(assignedSurgeons);
    const snapshots = [];

    for await (const snap of chunks?.map(
      async (chunk) => {
        let query = firebase
          .db
          .collection(path)
          .where('surgeon', 'in', chunk);

        if (dateRange?.minDate) {
          query = query.where('date', '>', fromMomentToTimestampDay(dateRange.minDate));
        }
        if (dateRange?.maxDate) {
          query = query.where('date', '<', fromMomentToTimestampDay(dateRange.maxDate));
        }

        await query.get();
      }
    )) {
      snapshots?.push(snap);
    }

    let temp = [];
    snapshots?.forEach((snap) => {
      temp = [...temp, ...snap?.docs];
    });
    docs = temp;
  }

  if (territoryRoles?.includes(currentUser.role) && !!currentUser.territoryVisibility) {
    const hospitals = state.hospitals?.list?.length ? state.hospitals?.list : await dispatch(getHospitals(tenantId));
    const userHospitals = hospitals?.filter((h) => h.territories?.some((territory) => currentUser?.assignedTerritories?.includes(territory)));
    docs = docs?.filter((item) => userHospitals?.map((h) => h.id)?.includes(item?.data()?.hospital));
  }

  const promises = docs.map(async (doc) => {
    const data = doc.data();

    const setsAllocation = await dispatch(getSetsAllocation(doc.id, true));

    return {
      ...data,
      id: doc.id,
      createdAt: data && data.createdAt ? data.createdAt.toDate() : '',
      updatedAt: data && data.updatedAt ? data.updatedAt.toDate() : '',
      date: data && data.date ? data.date.toDate() : '',
      setsAllocation: setsAllocation || [],
      proformaSentAt: data && data.proformaSentAt ? data.proformaSentAt.toDate() : '',
      proformaDate: data && data.proformaDate ? data.proformaDate.toDate() : '',
      collectionDate: data && data.collectionDate ? data.collectionDate.toDate() : '',
    };
  });

  const cases = await Promise.all(promises);
  return cases ? orderBy(cases, 'date', 'asc') : [];
};

export const getCasesWithUsage = () => async (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;
  const currentUser = state.user.currentUser;
  const path = collections.CASES_COLLECTION(tenantId);

  dispatch({ type: GET_CASES });

  let query;
  let docs = [];

  if ([ADMIN, OPERATIONS, CUSTOMER_SERVICE, LOGISTICS, SALES_MANAGER, MARKETING, FINANCE].includes(currentUser.role)) {
    query = firebase
      .db
      .collection(path)

    const snapshot = await query.get();
    docs = snapshot?.docs;
  } else {
    if (!currentUser.assignedSurgeons || !currentUser.assignedSurgeons.length) {
      return dispatch(setCases([]));
    }

    const assignedSurgeons = currentUser.assignedSurgeons || ['empty'];
    const chunks = chunkArray(assignedSurgeons);
    const snapshots = [];

    for await (const snap of chunks?.map(
      async (chunk) =>
        await firebase
          .db
          .collection(path)
          .where('surgeon', 'in', chunk)
          .get()
    )) {
      snapshots?.push(snap);
    }

    let temp = [];
    snapshots?.forEach((snap) => {
      temp = [...temp, ...snap?.docs];
    });
    docs = temp;
  }

  if (territoryRoles?.includes(currentUser.role) && !!currentUser.territoryVisibility) {
    const hospitals = state.hospitals?.list?.length ? state.hospitals?.list : await dispatch(getHospitals(tenantId));
    const userHospitals = hospitals?.filter((h) => h.territories?.some((territory) => currentUser?.assignedTerritories?.includes(territory)));
    docs = docs?.filter((item) => userHospitals?.map((h) => h.id)?.includes(item?.data()?.hospital));
  }

  const promises = docs?.map(async (doc) => {
    const data = doc.data();

    const usage = await dispatch(getCaseUsage(doc.id, true));
    let setsAllocation = [];

    if (usage?.length) {
      setsAllocation = await dispatch(getSetsAllocation(doc.id, true));
    }

    return {
      ...data,
      id: doc.id,
      createdAt: data && data.createdAt ? data.createdAt.toDate() : '',
      updatedAt: data && data.updatedAt ? data.updatedAt.toDate() : '',
      date: data && data.date ? data.date.toDate() : '',
      orderClosedAt: data && data.orderClosedAt ? data.orderClosedAt.toDate() : '',
      usage: usage || [],
      proformaSentAt: data && data.proformaSentAt ? data.proformaSentAt.toDate() : '',
      proformaDate: data && data.proformaDate ? data.proformaDate.toDate() : '',
      collectionDate: data && data.collectionDate ? data.collectionDate.toDate() : '',
      setsAllocation: setsAllocation || [],
    };
  });

  const cases = await Promise.all(promises);
  return cases ? orderBy(cases, 'date', 'asc') : [];
};

export const getCasesByKitId = (kitId) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;
  const currentUser = state.user.currentUser;
  const path = collections.CASES_COLLECTION(tenantId);

  dispatch({ type: GET_CASES });

  const snapshotKits = await firebase.db.collection(path)
    .where('kits', 'array-contains', kitId)
    .get();
  const kitsDocs = snapshotKits?.docs || [];

  const snapshotAdditionalKits = await firebase.db.collection(path)
    .where('additionalKits', 'array-contains', kitId)
    .get();
  const additionalKitsDocs = snapshotAdditionalKits?.docs || [];

  let cases = [...kitsDocs, ...additionalKitsDocs].map(doc => {
    const data = doc.data();
    return {
      ...data,
      id: doc.id,
      createdAt: data.createdAt ? data.createdAt.toDate() : '',
      updatedAt: data.updatedAt ? data.updatedAt.toDate() : '',
      date: data.date ? data.date.toDate() : '',
      proformaSentAt: data && data.proformaSentAt ? data.proformaSentAt.toDate() : '',
      proformaDate: data && data.proformaDate ? data.proformaDate.toDate() : '',
      collectionDate: data && data.collectionDate ? data.collectionDate.toDate() : '',
    };
  });

  if (territoryRoles?.includes(currentUser.role) && !!currentUser.territoryVisibility) {
    const hospitals = state.hospitals?.list?.length ? state.hospitals?.list : await dispatch(getHospitals(tenantId));
    const userHospitals = hospitals?.filter((h) => h.territories?.some((territory) => currentUser?.assignedTerritories?.includes(territory)));
    cases = cases?.filter((item) => userHospitals?.map((h) => h.id)?.includes(item.hospital));
  }

  return cases;
};

export const createCase = (caseData, connectedTenant) => async (dispatch, getState) => {
  const state = getState();
  const userId = state.user.currentUser.uid;

  let tenant = {};
  let hospitals = [];

  if (connectedTenant) {
    const tenantPath = collections.TENANTS_COLLECTION;
    const document = await firebase.db.collection(tenantPath).doc(connectedTenant).get();
    tenant = { ...document.data(), docId: document.id };

    const path = collections.HOSPITALS_COLLECTION(document.id);
    const snapshot = await firebase.db.collection(path).get();
    hospitals = snapshot.docs?.map(doc => {
      const data = doc.data();
      return ({
        ...data,
        id: doc.id,
        locations: data.locations ? orderBy(data.locations) : [],
      });
    });
  } else {
    tenant = state.tenant.currentTenant;
    hospitals = state.hospitals.list || [];
  }

  const tenantId = tenant ? tenant.id : null;
  const caseCount = tenant && tenant.caseCount ? tenant.caseCount : 0;
  const path = collections.CASES_COLLECTION(tenantId);

  if (tenantId) {
    let id = '';
    const prefix = `${tenant.businessName[0].toUpperCase()}${tenant.businessName[1].toUpperCase()}`;
    const snap = await firebase.db.collection(path)
      .orderBy('id', 'desc')
      .limit(1)
      .get();

    if (snap && snap.docs && snap.docs[0]) {
      let str = `${caseCount + 1}`;
      const length = str.length;
      for (let i = 0; i < 7 - length; i++) {
        str = `0${str}`;
      }
      id = `${prefix}-${str}`;
    } else {
      id = `${prefix}-0000001`;
    }
    const uniq = uuid()?.substring(0, 4)?.toUpperCase();
    id = `${id}-${uniq}`;

    const setAllocationsPath = collections.CASE_SETS_ALLOCATION_COLLECTION(tenantId, id);

    const doc = {
      ...caseData,
      id,
      kits: caseData.kits && !caseData.noEquipmentToShip ? caseData.kits.map((kit) => kit.id) : [],
      items: caseData.items && !caseData.noEquipmentToShip ? caseData.items.map((item) => item.id) : [],
      active: true,
      assignedUsers: [],
      createdAt: nowTimestampUTC(),
      date: caseData.date ? fromMomentToTimestampDay(caseData.date) : nowTimestampUTC(),
      status: (
        (caseData.kits && caseData.kits.length) || (caseData.items && caseData.items.length) || caseData.noEquipmentToShip
      ) ? caseStatusOptions.booked : caseStatusOptions.request,
      time: 'AM',
      transferredRequests: [],
      createdBy: userId,
      updatedBy: userId,
      tenantId,
    };

    if (caseData?.additionalKits?.length) {
      doc.additionalKits = caseData?.additionalKits?.map((kit) => kit.id) || [];
    }
    if (caseData?.additionalItems?.length) {
      doc.additionalItems = caseData?.additionalItems?.map((item) => item.id) || [];
    }

    const hospital = hospitals?.find((item) => item.id === caseData.hospital);

    if (caseData.deliveryAddress) {
      const address = hospital?.deliveryAddresses?.find((item) => item.id === caseData.deliveryAddress);
      doc.deliveryAddress = address || null;
    }

    if (hospital.customBillingAddress && hospital.billingAddress) {
      doc.billingAddress = hospital.billingAddress;
    } else {
      doc.billingAddress = {
        street: hospital.street || '',
        city: hospital.city || '',
        country: hospital.country || '',
        state: hospital.state || '',
        postCode: hospital.postCode || '',
        apartment: '',
        attn: '',
        buildingName: '',
        department: '',
        neighborhood: ''
      };
    }

    await firebase.db.collection(path).doc(id).set(doc, { merge: true });

    if (caseData.kits && !!caseData.kits.length) {
      for (let kit of caseData.kits) {
        await firebase.db.collection(setAllocationsPath).doc(kit.id).set({
          active: true,
          kit: kit.id,
          sets: [],
          status: setAllocationStatuses.AVAILABLE.value,
          quantity: kit.quantity || 1,
        });
      }
    }

    if (caseData.additionalKits && !!caseData.additionalKits.length) {
      for (let kit of caseData.additionalKits) {
        await firebase.db.collection(setAllocationsPath).doc(`${kit.id}_additional`).set({
          active: true,
          additional: true,
          kit: kit.id,
          sets: [],
          status: setAllocationStatuses.AVAILABLE.value,
          quantity: kit.quantity || 1,
        });
      }
    }

    if (caseData.items && !!caseData.items.length) {
      for (let item of caseData.items) {
        await firebase.db.collection(setAllocationsPath).doc(item.id).set({
          active: true,
          itemId: item.id,
          sets: [],
          status: setAllocationStatuses.AVAILABLE.value,
          quantity: item.quantity || 1,
          consumables: [],
        });
      }
    }

    if (caseData.additionalItems && !!caseData.additionalItems.length) {
      for (let item of caseData.additionalItems) {
        await firebase.db.collection(setAllocationsPath).doc(`${item.id}_additional`).set({
          active: true,
          additional: true,
          itemId: item.id,
          sets: [],
          status: setAllocationStatuses.AVAILABLE.value,
          quantity: item.quantity || 1,
          consumables: [],
        });
      }
    }

    if (caseData.flow) {
      await dispatch(addFlow(id, caseData.flow, [], connectedTenant));
    }

    return id;
  } else {
    throw new Error('Invalid tenant');
  }
};

const getStatus = (caseData) => {
  if (caseData?.status === caseStatusOptions.completed) {
    return caseStatusOptions.completed;
  }

  if (caseData?.status === caseStatusOptions.overdue) {
    return moment().startOf('day').isAfter(caseData.date) ? caseStatusOptions.overdue : caseStatusOptions.booked;
  }

  return (
    (
      (caseData.kits && caseData.kits.length) || (caseData.items && caseData.items.length) || caseData.noEquipmentToShip
    ) ? caseStatusOptions.booked : caseStatusOptions.request
  );
}

export const updateCase = (caseId, caseData) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;
  const userId = state.user.currentUser.uid;
  const currentKits = state.activeCase?.data?.kits || [];
  const currentItems = state.activeCase?.data?.items || [];
  const currentAdditionalKits = state.activeCase?.data?.additionalKits || [];
  const currentAdditionalItems = state.activeCase?.data?.additionalItems || [];
  const hospitals = state.hospitals.list || [];

  const path = collections.CASES_COLLECTION(tenantId);
  const setAllocationsPath = collections.CASE_SETS_ALLOCATION_COLLECTION(tenantId, caseId);

  if (tenantId) {
    const doc = {
      ...caseData,
      kits: caseData.kits.map((kit) => kit.id),
      items: caseData.items ? caseData.items.map((item) => item.id) : [],
      updatedAt: nowTimestampUTC(),
      date: fromMomentToTimestampDay(caseData.date),
      status: getStatus(caseData),
      updatedBy: userId,
    };

    if (caseData.deliveryAddress) {
      const hospital = hospitals?.find((item) => item.id === caseData.hospital);
      const address = hospital?.deliveryAddresses?.find((item) => item.id === caseData.deliveryAddress);

      doc.deliveryAddress = address || null;
    }

    if (caseData?.additionalKits?.length) {
      doc.additionalKits = caseData?.additionalKits?.map((kit) => kit.id) || [];
    }
    if (caseData?.additionalItems?.length) {
      doc.additionalItems = caseData?.additionalItems?.map((item) => item.id) || [];
    }

    await firebase.db.collection(path).doc(caseId).set(doc, { merge: true });

    // Kits
    if (caseData.kits && !!caseData.kits.length) {
      for (let kit of caseData.kits) {
        let allocation = {
          quantity: kit.quantity || 1,
        };

        if (!currentKits.includes(kit.id)) {
          allocation = {
            ...allocation,
            active: true,
            kit: kit.id,
            sets: [],
            status: setAllocationStatuses.AVAILABLE.value,
          }
        }

        await firebase.db.collection(setAllocationsPath).doc(kit.id).set(allocation, { merge: true });
      }
    }
    if (currentKits && !!currentKits?.length) {
      for (let kit of currentKits) {
        if (!caseData?.kits?.map((k) => k.id)?.includes(kit)) {
          await firebase.db.collection(setAllocationsPath).doc(kit).delete();
        }
      }
    }
    // Items
    if (caseData.items && !!caseData.items.length) {
      for (let item of caseData.items) {
        let allocation = {
          quantity: item.quantity || 1,
        };

        if (!currentItems.includes(item.id)) {
          allocation = {
            ...allocation,
            active: true,
            itemId: item.id,
            sets: [],
            consumables: [],
            status: setAllocationStatuses.AVAILABLE.value,
          }
        }

        await firebase.db.collection(setAllocationsPath).doc(item.id).set(allocation, { merge: true });
      }
    }
    if (currentItems && !!currentItems?.length) {
      for (let item of currentItems) {
        if (!caseData?.items?.map((i) => i.id)?.includes(item)) {
          await firebase.db.collection(setAllocationsPath).doc(item).delete();
        }
      }
    }
    // Additional Kits
    if (caseData?.additionalKits?.length) {
      for (let kit of caseData.additionalKits) {
        let allocation = {
          quantity: kit.quantity || 1,
        };

        if (!currentAdditionalKits.includes(kit.id)) {
          allocation = {
            ...allocation,
            additional: true,
            active: true,
            kit: kit.id,
            sets: [],
            status: setAllocationStatuses.AVAILABLE.value,
          }
        }

        await firebase.db.collection(setAllocationsPath).doc(`${kit.id}_additional`).set(allocation, { merge: true });
      }
    }
    if (currentAdditionalKits && !!currentAdditionalKits?.length) {
      for (let kit of currentAdditionalKits) {
        if (!caseData?.additionalKits?.map((k) => k.id)?.includes(kit)) {
          await firebase.db.collection(setAllocationsPath).doc(`${kit}_additional`).delete();
        }
      }
    }
    // Additional Items
    if (caseData.additionalItems?.length) {
      for (let item of caseData.additionalItems) {
        let allocation = {
          quantity: item.quantity || 1,
        };

        if (!currentAdditionalItems.includes(item.id)) {
          allocation = {
            ...allocation,
            additional: true,
            active: true,
            itemId: item.id,
            sets: [],
            consumables: [],
            status: setAllocationStatuses.AVAILABLE.value,
          }
        }

        await firebase.db.collection(setAllocationsPath).doc(`${item.id}_additional`).set(allocation, { merge: true });
      }
    }
    if (currentAdditionalItems && !!currentAdditionalItems?.length) {
      for (let item of currentAdditionalItems) {
        if (!caseData?.additionalItems?.map((i) => i.id)?.includes(item)) {
          await firebase.db.collection(setAllocationsPath).doc(`${item}_additional`).delete();
        }
      }
    }
  } else {
    throw new Error('Invalid tenant');
  }
};

export const simpleUpdateCase = (caseId, caseData) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;
  const path = collections.CASES_COLLECTION(tenantId);

  if (tenantId) {
    await firebase.db.collection(path).doc(caseId).set(caseData, { merge: true });
  } else {
    throw new Error('Invalid tenant');
  }
};

export const turnOnEditingState = (caseId) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;
  const path = collections.CASES_COLLECTION(tenantId);
  const userId = state.user.currentUser.uid;

  if (tenantId) {
    await firebase.db.collection(path).doc(caseId).set({ editingBy: userId }, { merge: true });
  } else {
    throw new Error('Invalid tenant');
  }
};

export const turnOffEditingState = (caseId) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;
  const path = collections.CASES_COLLECTION(tenantId);
  const userId = state.user.currentUser.uid;

  if (tenantId) {
    const doc = await firebase.db.collection(path).doc(caseId).get();
    const data = doc?.data();
    if (data?.editingBy === userId) {
      await firebase.db.collection(path).doc(caseId).set({ editingBy: null }, { merge: true });
    }
  } else {
    throw new Error('Invalid tenant');
  }
};

export const kickOut = (caseId, connectedTenantId) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = connectedTenantId || state?.tenant?.currentTenant?.id;
  const path = collections.CASES_COLLECTION(tenantId);
  const userId = state.user.currentUser.uid;

  if (tenantId) {
    await firebase.db.collection(path).doc(caseId).set({ editingBy: userId }, { merge: true });
  } else {
    throw new Error('Invalid tenant');
  }
};

export const checkKitAvailability = (caseId) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;
  const path = collections.CASES_COLLECTION(tenantId);
  const setPath = collections.SETS_COLLECTION(tenantId);

  const doc = await firebase.db.collection(path).doc(caseId).get();
  const data = {
    ...doc?.data(),
    id: doc?.id,
    date: doc?.data() && doc?.data().date ? doc?.data().date.toDate() : '',
  };

  const snapshot = await firebase.db.collection(setPath).get();
  const sets = snapshot?.docs?.map(doc => ({
    id: doc.id,
    ...doc.data(),
  }));

  const today = moment().startOf('day');
  const jsDate = new Date(today)

  const casesSnap = await firebase.db.collection(path)
    .where('date', '>', firebase.timestamp.fromDate(jsDate))
    .where('status', '==', caseStatusOptions.booked)
    .get();
  const cases = casesSnap?.docs?.map(mapCases);
  const caseKits = data?.kits || [];

  let list = cases.filter((item) => {
    const kits = item?.kits || [];
    return kits.some((kitId) => caseKits.includes(kitId)) && moment(item.date).isSame(data.date, 'day');
  });

  if (data?.kitVariant === kitVariantTypes.consignment) {
    list = list?.filter((item) => item.kitVariant === kitVariantTypes.consignment);
  } else {
    list = list?.filter((item) => item.kitVariant === kitVariantTypes.loan);
  }

  const result = [];
  data?.kits?.forEach((kitId) => {
    let count = 0;
    list?.forEach((item) => {
      if (item?.kits?.includes(kitId)) {
        count++;
      }
    });

    let kitSets = [];

    if (data?.kitVariant === kitVariantTypes.consignment) {
      kitSets = sets?.filter((set) => set?.kit === kitId && set.consigned && set?.consignment?.hospital === data?.hospital);
    } else {
      kitSets = sets?.filter((set) => set?.kit === kitId && !set?.consigned);
    }

    if (count <= kitSets?.length) {
      result?.push(kitId);
    }
  });

  if (result?.length) {
    list?.forEach((item) => {
      const warningKits = item?.warningKits ? item?.warningKits?.filter((kitId) => !result?.includes(kitId)) : [];
      dispatch(simpleUpdateCase(item.id, { warningKits }));
    });
  }
};

export const deleteCase = (caseId) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;
  const idToken = await firebase.auth.currentUser.getIdToken();

  if (tenantId) {
    await remove(urls.cases.delete(caseId, tenantId), idToken);
  } else {
    throw new Error('Invalid tenant');
  }
};

export const bulkDeleteCases = (cases) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;
  const idToken = await firebase.auth.currentUser.getIdToken();

  if (tenantId) {
    const promises = [];
    cases.forEach((caseId) => {
      promises.push(remove(urls.cases.delete(caseId, tenantId), idToken));
    });

    await Promise.all(promises);
  } else {
    throw new Error('Invalid tenant');
  }
};

export const cancelCase = (caseId) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;
  const updatedBy = state.user.currentUser ? state.user.currentUser.uid : '';
  const path = collections.CASES_COLLECTION(tenantId);

  if (tenantId) {
    await firebase.db.collection(path).doc(caseId).set({ active: false, updatedBy }, { merge: true });
  } else {
    throw new Error('Invalid tenant');
  }
};

// Active case

export const setCase = (activeCase) => ({ type: SET_CASE, activeCase });

export const getCase = (caseId) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;
  const currentUser = state.user.currentUser;
  const path = collections.CASES_COLLECTION(tenantId);

  dispatch({ type: GET_CASE });

  const doc = await firebase.db.collection(path).doc(caseId).get();
  const data = doc.data();
  let activeCase;

  if (![ADMIN, OPERATIONS, CUSTOMER_SERVICE, LOGISTICS, SALES_MANAGER, MARKETING, FINANCE].includes(currentUser.role)
    && !currentUser.assignedSurgeons.includes(data?.surgeon)) {
    activeCase = { id: null };
  } else {
    activeCase = {
      ...data,
      id: doc.exists ? doc.id : null,
      createdAt: data && data.createdAt ? data.createdAt.toDate() : '',
      updatedAt: data && data.updatedAt ? data.updatedAt.toDate() : '',
      completedAt: data && data.completedAt ? data.completedAt.toDate() : '',
      date: data && data.date ? data.date.toDate() : '',
      proformaSentAt: data && data.proformaSentAt ? data.proformaSentAt.toDate() : '',
      proformaDate: data && data.proformaDate ? data.proformaDate.toDate() : '',
      collectionDate: data && data.collectionDate ? data.collectionDate.toDate() : '',
    };
  }

  if (territoryRoles?.includes(currentUser.role) && !!currentUser.territoryVisibility) {
    const hospitals = state.hospitals?.list?.length ? state.hospitals?.list : await dispatch(getHospitals(tenantId));
    const userHospitals = hospitals?.filter((h) => h.territories?.some((territory) => currentUser?.assignedTerritories?.includes(territory)));
    if (!userHospitals?.map((h) => h.id)?.includes(activeCase.hospital)) {
      activeCase = { id: null };
    }
  }

  dispatch(setCase(activeCase));

  return activeCase;
};

export const getCaseAvailability = (caseId) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;
  const currentUser = state.user.currentUser;
  const path = collections.CASES_COLLECTION(tenantId);

  const doc = await firebase.db.collection(path).doc(caseId).get();
  const data = doc.data();

  return !data.editingBy || data.editingBy === currentUser.uid;
};

export const setCaseView = (view) => ({ type: SET_VIEW, view });

export const setFormStep = (step) => ({ type: SET_STEP, step });

// Case notes

export const setCaseNotes = (notes) => ({ type: SET_CASE_NOTES, notes });

export const getCaseNotes = (caseId) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;
  const path = collections.CASE_NOTES_COLLECTION(tenantId, caseId);

  dispatch({ type: GET_CASE_NOTES });

  const snapshot = await firebase.db.collection(path).get();
  const notes = snapshot.docs.map(doc => {
    const data = doc.data();
    return {
      ...data,
      id: doc.id,
      createdAt: data.createdAt ? data.createdAt.toDate() : '',
    };
  });

  return dispatch(setCaseNotes(notes));
};

export const createCaseNote = (caseId, noteData, connectedTenantId) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = connectedTenantId || state?.tenant?.currentTenant?.id;
  const currentUser = state.user.currentUser;
  const path = collections.CASE_NOTES_COLLECTION(tenantId, caseId);

  if (tenantId) {
    await firebase.db.collection(path).add({
      ...noteData,
      active: true,
      createdAt: nowTimestampUTC(),
      authorFullName: `${currentUser.firstName} ${currentUser.lastName}`,
      authorId: currentUser.uid,
      caseId,
    });
  } else {
    throw new Error('Invalid tenant');
  }
};

export const updateCaseNote = (caseId, noteId, noteData, connectedTenantId) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = connectedTenantId || state?.tenant?.currentTenant?.id;
  const path = collections.CASE_NOTES_COLLECTION(tenantId, caseId);

  if (tenantId) {
    await firebase.db.collection(path).doc(noteId).set({
      ...noteData,
      updatedAt: nowTimestampUTC(),
    }, { merge: true });
  } else {
    throw new Error('Invalid tenant');
  }
};

export const deleteCaseNote = (caseId, noteId, connectedTenantId) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = connectedTenantId || state?.tenant?.currentTenant?.id;
  const path = collections.CASE_NOTES_COLLECTION(tenantId, caseId);

  if (tenantId) {
    await firebase.db.collection(path).doc(noteId).delete();
  } else {
    throw new Error('Invalid tenant');
  }
};

// Case usage

export const setCaseUsage = (usage) => ({ type: SET_CASE_USAGE, usage });

export const setCaseUsageNotes = (notes) => ({ type: SET_CASE_USAGE_NOTES, notes });

export const getCaseUsage = (caseId, noReducer) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;
  const path = collections.CASE_USAGE_COLLECTION(tenantId, caseId);

  dispatch({ type: GET_CASE_USAGE });

  const snapshot = await firebase.db.collection(path).get();
  const usage = snapshot.docs.map(doc => {
    const data = doc.data();
    return {
      ...data,
      id: doc.id,
      createdAt: data.createdAt ? data.createdAt.toDate() : '',
    };
  });

  if (!noReducer) {
    dispatch(setCaseUsage(usage));
  }

  return usage;
};

export const addUsageImplants = (caseId, usageData, connectedTenantId) => async (dispatch, getState) => {
  const state = getState();
  const userId = state.user.currentUser.uid;
  const tenantId = connectedTenantId || state?.tenant?.currentTenant?.id;
  const path = collections.CASE_USAGE_COLLECTION(tenantId, caseId);

  const values = [
    { label: 'REF', value: usageData.ref || '' },
    { label: 'LOT', value: usageData.lot || '' },
    { label: 'EXP', value: usageData.exp || '' },
    { label: 'DESC', value: usageData.desc || '' }
  ];

  if (usageData.gtin) {
    values.push({ label: 'GTIN', value: usageData.gtin });
  }

  const usageObj = {
    active: true,
    createdAt: nowTimestampUTC(),
    type: caseUsageTypes.implants,
    values: values.map((value) => ({ ...value, generated: false, readonly: false, placeholder: null })),
    quantity: usageData.quantity || 0,
    image: null,
    scanType: usageData.scanType || implantScanTypes.text,
    approved: false,
    userId,
    tag: usageData.tag || null,
    batchControl: !!usageData.batchControl
  };

  await firebase.db.collection(path).add(usageObj);
};

export const updateUsageImplants = (caseId, usageData, connectedTenantId) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = connectedTenantId || state?.tenant?.currentTenant?.id;
  const path = collections.CASE_USAGE_COLLECTION(tenantId, caseId);

  const values = usageData.values.map((value) => {
    if (value.label === 'REF') {
      value.value = usageData.ref || '';
    }
    if (value.label === 'LOT') {
      value.value = usageData.lot || '';
    }
    if (value.label === 'EXP') {
      value.value = usageData.exp || '';
    }
    if (value.label === 'GTIN' && usageData.gtin) {
      value.value = usageData.gtin || '';
    }
    if (value.label === 'DESC' && usageData.desc) {
      value.value = usageData.desc || '';
    }
    return value;
  });

  const keys = ['ref', 'lot', 'exp', 'gtin', 'desc'];
  keys?.forEach((key) => {
    if (usageData[key] && !usageData.values?.find((value) => value.label === key?.toUpperCase())) {
      values?.push({
        generated: false,
        readonly: false,
        placeholder: null,
        label: key?.toUpperCase(),
        value: usageData[key],
      });
    }
  });

  if (usageData.gtin && !usageData.values.find((usage) => usage.label === 'GTIN')) {
    values.push({
      generated: false,
      readonly: false,
      placeholder: null,
      label: 'GTIN',
      value: usageData.gtin,
    });
  }

  const usageObj = {
    updatedAt: nowTimestampUTC(),
    quantity: usageData.quantity || 0,
    values,
    tag: usageData.tag || null,
  };

  await firebase.db.collection(path).doc(usageData.id).set(usageObj, { merge: true });
};

export const approveUsage = (caseId, usageId, value, connectedTenantId) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = connectedTenantId || state?.tenant?.currentTenant?.id;
  const path = collections.CASE_USAGE_COLLECTION(tenantId, caseId);

  await firebase.db.collection(path).doc(usageId).set({ approved: value }, { merge: true });
};

export const submitUsage = (caseId, connectedTenantId) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = connectedTenantId || state?.tenant?.currentTenant?.id;
  const path = collections.CASES_COLLECTION(tenantId);
  const userId = state.user.currentUser.uid;

  const doc = {
    status: caseStatusOptions.completed,
    completedAt: nowTimestampUTC(),
    completedBy: userId,
  }

  await firebase.db.collection(path).doc(caseId).set(doc, { merge: true });
};

export const closeOrder = (caseId, reference, files, connectedTenantId) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = connectedTenantId || state?.tenant?.currentTenant?.id;
  const userId = state?.user?.currentUser?.uid;
  const path = collections.CASES_COLLECTION(tenantId);

  let orderAttachments = [];

  if (files?.length) {
    for (const file of files) {
      const storageRef = firebase.storage.ref();
      const storagePath = storageRefs.CASE_USAGE_IMAGES_REF(tenantId, caseId);
      const filePath = `${storagePath}/${file.name}`;
      const ref = storageRef.child(filePath);

      const snap = await ref.put(file);
      const downloadUrl = await snap.ref.getDownloadURL();

      const fileObj = {
        active: true,
        fileName: file.name,
        path: filePath,
        downloadUrl,
        createdAt: nowTimestampUTC(),
      };

      orderAttachments.push(fileObj);
    }
  }

  const doc = {
    orderClosed: true,
    orderReference: reference || '',
    orderAttachments,
    orderClosedAt: nowTimestampUTC()
  };

  await firebase.db.collection(path).doc(caseId).set(doc, { merge: true });

  const activityPath = collections.CASE_ACTIVITY(tenantId, caseId);
  await firebase.db.collection(activityPath).add({
    type: notificationTypes.caseClosed,
    title: 'Case Closed',
    payload: 'The following case has been closed',
    createdAt: nowTimestampUTC(),
    arguments: { caseId, orderReference: reference || '' },
    userId
  });
};

export const updateOrder = (caseId, reference, currentFiles, newFiles, connectedTenantId) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = connectedTenantId || state?.tenant?.currentTenant?.id;
  const path = collections.CASES_COLLECTION(tenantId);

  let orderAttachments = [...currentFiles];

  if (newFiles?.length) {
    for (const file of newFiles) {
      const storageRef = firebase.storage.ref();
      const storagePath = storageRefs.CASE_USAGE_IMAGES_REF(tenantId, caseId);
      const filePath = `${storagePath}/${file.name}`;
      const ref = storageRef.child(filePath);

      const snap = await ref.put(file);
      const downloadUrl = await snap.ref.getDownloadURL();

      const fileObj = {
        active: true,
        fileName: file.name,
        path: filePath,
        downloadUrl,
        createdAt: nowTimestampUTC(),
      };

      orderAttachments.push(fileObj);
    }
  }

  const doc = {
    orderReference: reference || '',
    orderAttachments,
  };

  await firebase.db.collection(path).doc(caseId).set(doc, { merge: true });
};

export const addUsageImage = (caseId, usageData, image, connectedTenantId) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = connectedTenantId || state?.tenant?.currentTenant?.id;
  const user = state.user.currentUser;
  const path = collections.CASE_USAGE_COLLECTION(tenantId, caseId);

  const storageRef = firebase.storage.ref();
  const storagePath = storageRefs.CASE_USAGE_IMAGES_REF(tenantId, caseId);
  const filePath = `${storagePath}/${image.name}`;
  const ref = storageRef.child(filePath);

  const snap = await ref.put(image);
  const downloadUrl = await snap.ref.getDownloadURL();

  const fileObj = {
    active: true,
    fileName: image.name,
    path: filePath,
    downloadUrl,
    createdAt: nowTimestampUTC(),
  };

  const values = [
    { label: 'USER', value: `${user.firstName} ${user.lastName}` },
    { label: 'DESC', value: usageData.description || '' },
  ];
  const usageObj = {
    active: true,
    createdAt: nowTimestampUTC(),
    type: caseUsageTypes.images,
    scanType: null,
    values: values.map((value) => ({ ...value, generated: false, readonly: false, placeholder: null })),
    image: fileObj,
    quantity: usageData.quantity || 1,
  };

  await firebase.db.collection(path).add(usageObj);
};

export const updateUsageImage = (caseId, usageData, connectedTenantId) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = connectedTenantId || state?.tenant?.currentTenant?.id;
  const path = collections.CASE_USAGE_COLLECTION(tenantId, caseId);

  const values = [...usageData.values];
  const desc = values.find((value) => value.label === 'DESC');

  desc.value = usageData.description || '';

  const usageObj = {
    updatedAt: nowTimestampUTC(),
    quantity: usageData.quantity || 1,
    values,
  };

  await firebase.db.collection(path).doc(usageData.id).set(usageObj, { merge: true });
};

export const createUsageNote = (caseId, noteData, connectedTenantId) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = connectedTenantId || state?.tenant?.currentTenant?.id;
  const currentUser = state.user.currentUser;
  const path = collections.CASE_USAGE_NOTES_COLLECTION(tenantId, caseId);

  if (tenantId) {
    await firebase.db.collection(path).add({
      ...noteData,
      active: true,
      createdAt: nowTimestampUTC(),
      authorFullName: `${currentUser.firstName} ${currentUser.lastName}`,
      authorId: currentUser.uid,
      caseId,
    });
  } else {
    throw new Error('Invalid tenant');
  }
};

export const updateUsageNote = (caseId, noteId, noteData, connectedTenantId) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = connectedTenantId || state?.tenant?.currentTenant?.id;
  const path = collections.CASE_USAGE_NOTES_COLLECTION(tenantId, caseId);

  if (tenantId) {
    await firebase.db.collection(path).doc(noteId).set({
      ...noteData,
      updatedAt: nowTimestampUTC(),
    }, { merge: true });
  } else {
    throw new Error('Invalid tenant');
  }
};

export const deleteUsageNote = (caseId, noteId, connectedTenantId) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = connectedTenantId || state?.tenant?.currentTenant?.id;
  const path = collections.CASE_USAGE_NOTES_COLLECTION(tenantId, caseId);

  if (tenantId) {
    await firebase.db.collection(path).doc(noteId).delete();
  } else {
    throw new Error('Invalid tenant');
  }
};

export const changeProformaStatus = (caseId, proformaSent, proformaValue, connectedTenantId, freightCost, referenceNumber) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = connectedTenantId || state?.tenant?.currentTenant?.id;
  const path = collections.CASES_COLLECTION(tenantId);

  const doc = {
    proformaSent,
    proformaSentAt: nowTimestampUTC(),
    referenceNumber,
  };

  if (proformaValue && Number(proformaValue) > 0) {
    doc.proformaValue = Number(proformaValue);
  }

  if (freightCost && Number(freightCost) > 0) {
    doc.freightCost = Number(freightCost);
  }

  await firebase.db.collection(path).doc(caseId).set(doc, { merge: true });
};

export const deleteUsage = (caseId, usageId, connectedTenantId) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = connectedTenantId || state?.tenant?.currentTenant?.id;
  const path = collections.CASE_USAGE_COLLECTION(tenantId, caseId);

  await firebase.db.collection(path).doc(usageId).delete();
};

export const getUsageReportingData = (data, submittedDate) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;
  const cases = state.cases?.list
    ?.filter((item) => item.status === caseStatusOptions.completed || item.status === caseStatusOptions.overdue) || [];

  const {
    hospitals,
    surgeons,
    lot,
    ref,
    minDate,
    maxDate,
  } = data;

  let result = [];
  const filteredCases = cases?.filter((item) => {
    let persistence = true;
    if (hospitals && hospitals.length) {
      persistence = hospitals.includes(item.hospital);
    }
    if (surgeons && surgeons.length) {
      persistence = surgeons.includes(item.surgeon);
    }
    return persistence
      && moment(item[submittedDate ? 'completedAt' : 'date']).isAfter(moment(minDate).startOf('day'))
      && moment(item[submittedDate ? 'completedAt' : 'date']).isBefore(moment(maxDate).endOf('day'));
  });

  for (const item of filteredCases) {
    const path = collections.CASE_USAGE_COLLECTION(tenantId, item.id);
    let snapshot = await firebase.db.collection(path).get();
    let usage = snapshot.docs?.map((doc) => {
      const data = doc.data();
      return {
        ...data,
        id: doc.id,
        createdAt: data.createdAt ? data.createdAt.toDate() : '',
        caseId: item.id,
        date: item.date,
        completedAt: item.completedAt,
        surgeon: item.surgeon,
        hospital: item.hospital,
        patientReference: item.patientReference,
        orderReference: item.orderReference,
      };
    });
    usage = usage.filter((u) => {
      let persistence = true;
      if (lot) {
        const value = u?.values?.find((v) => v.label === 'LOT');
        persistence = value?.value === lot;
      }
      if (ref) {
        const value = u?.values?.find((v) => v.label === 'REF');
        persistence = value?.value === ref;
      }
      return persistence;
    });
    result = [...result, ...usage];
  }

  return result;
};

// Case guidance

export const getCaseGuidance = () => async (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;
  const products = state.activeCase.data.products;
  const path = 'files';

  const snap1 = await firebase.db.collectionGroup(path)
    .where('tenantId', '==', tenantId)
    .where('active', '==', true)
    .where('relatedProducts', 'array-contains-any', products)
    .get();
  const snap2 = await firebase.db.collectionGroup(path)
    .where('tenantId', '==', tenantId)
    .where('active', '==', true)
    .where('allProducts', '==', true)
    .get();

  const files1 = snap1?.docs?.map((doc) => {
    const data = doc.data();
    return {
      ...data,
      id: doc.id,
      createdAt: data.createdAt ? data.createdAt.toDate() : '',
      updatedAt: data.updatedAt ? data.updatedAt.toDate() : '',
    };
  });
  const files2 = snap2?.docs?.map((doc) => {
    const data = doc.data();
    return {
      ...data,
      id: doc.id,
      createdAt: data.createdAt ? data.createdAt.toDate() : '',
      updatedAt: data.updatedAt ? data.updatedAt.toDate() : '',
    };
  });

  return [...files1, ...files2];
};

// Kit files

export const getKitFiles = (kitId) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;
  const path = 'files';

  const snapshot = await firebase.db.collectionGroup(path)
    .where('tenantId', '==', tenantId)
    .where('active', '==', true)
    .where('relatedKits', 'array-contains', kitId)
    .get();

  const files = snapshot.docs.map(doc => {
    const data = doc.data();
    return {
      ...data,
      id: doc.id,
      createdAt: data.createdAt ? data.createdAt.toDate() : '',
      updatedAt: data.updatedAt ? data.updatedAt.toDate() : '',
    };
  });

  return files;
};

// Case preferences

export const getCasePreferences = () => async (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;
  const activeCase = state.activeCase.data;

  const { hospital, procedure, surgeon } = activeCase;
  const path = collections.SURGEON_PREFERENCES_COLLECTION(tenantId, surgeon);

  const snapshot = await firebase.db.collection(path)
    .where('hospitalId', '==', hospital)
    .where('procedureId', '==', procedure)
    .get();

  const preferences = snapshot.docs.map(doc => {
    const data = doc.data();
    return {
      ...data,
      id: doc.id,
      createdAt: data.createdAt ? data.createdAt.toDate() : '',
    };
  });

  return orderBy(preferences, 'createdAt', 'desc');
};

// Case documents

export const setCaseDocuments = (documents) => ({ type: SET_CASE_DOCUMENTS, documents });

export const getCaseDocuments = (caseId) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;
  const path = collections.CASE_DOCUMENTS_COLLECTION(tenantId, caseId);

  dispatch({ type: GET_CASE_DOCUMENTS });

  const snapshot = await firebase.db.collection(path).get();
  const documents = snapshot.docs.map(doc => {
    const data = doc.data();
    return {
      ...data,
      id: doc.id,
      createdAt: data.createdAt ? data.createdAt.toDate() : '',
      shippingDate: data.shippingDate ? data.shippingDate.toDate() : '',
      returnDate: data.returnDate ? data.returnDate.toDate() : '',
    };
  });

  return dispatch(setCaseDocuments(documents));
};

export const uploadDocuments = (caseId, files) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;
  const promises = [];

  files.forEach((file) => {
    promises.push(uploadDocument(tenantId, caseId, file));
  });

  await Promise.all(promises);
};

const uploadDocument = async (tenantId, caseId, file) => {
  const storageRef = firebase.storage.ref();
  const storagePath = storageRefs.CASE_DOCUMENTS_REF(tenantId, caseId);
  const filePath = `${storagePath}/${file.name}`;
  const ref = storageRef.child(filePath);
  const snap = await ref.put(file);
  const downloadUrl = await snap.ref.getDownloadURL();
  const fileObj = {
    active: true,
    fileName: file.name,
    path: filePath,
    downloadUrl,
    createdAt: nowTimestampUTC(),
  };
  const path = collections.CASE_DOCUMENTS_COLLECTION(tenantId, caseId);

  await firebase.db.collection(path).add(fileObj);
};

export const onUploadComplete = (caseId, file, connectedTenantId) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = connectedTenantId || state?.tenant?.currentTenant?.id;
  const storagePath = storageRefs.CASE_DOCUMENTS_REF(tenantId, caseId);
  const filePath = `${storagePath}/${file.name}`;
  const fileObj = {
    active: true,
    fileName: file.name,
    path: filePath,
    downloadUrl: file.downloadUrl,
    createdAt: nowTimestampUTC(),
  };
  const path = collections.CASE_DOCUMENTS_COLLECTION(tenantId, caseId);

  await firebase.db.collection(path).add(fileObj);
};

export const deleteDocument = (caseId, fileId) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;
  const path = collections.CASE_DOCUMENTS_COLLECTION(tenantId, caseId);

  await firebase.db.collection(path).doc(fileId).delete();
};

// Sets allocation

export const setSetsAllocation = (setsAllocation) => ({ type: SET_CASE_SETS_ALLOCATION, setsAllocation });

export const getSetsAllocation = (caseId, noReducer) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;
  const path = collections.CASE_SETS_ALLOCATION_COLLECTION(tenantId, caseId);

  dispatch({ type: GET_CASE_SETS_ALLOCATION });

  const snapshot = await firebase.db.collection(path).get();
  const setsAllocation = snapshot.docs.map(doc => {
    const data = doc.data();
    return {
      ...data,
      id: doc.id,
      createdAt: data.createdAt ? data.createdAt.toDate() : '',
      shippingDate: data.shippingDate ? data.shippingDate.toDate() : '',
      returnDate: data.returnDate ? data.returnDate.toDate() : '',
    };
  });

  if (!noReducer) {
    dispatch(setSetsAllocation(setsAllocation));
  }

  return setsAllocation;
};

export const updateSetsAllocation = (caseId, allocationId, data) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;
  const path = collections.CASE_SETS_ALLOCATION_COLLECTION(tenantId, caseId);

  if (tenantId) {
    await firebase.db.collection(path).doc(allocationId).set(data, { merge: true });
  } else {
    throw new Error('Invalid tenant');
  }
};

export const deleteSetsAllocation = (activeCase, allocation) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;
  const casesPath = collections.CASES_COLLECTION(tenantId);
  const setsAllocationsPath = collections.CASE_SETS_ALLOCATION_COLLECTION(tenantId, activeCase.id);

  if (tenantId) {
    let doc = {};

    if (allocation.kit) {
      if (allocation.additional) {
        const additionalKits = [...activeCase.additionalKits];
        doc.additionalKits = additionalKits?.filter((id) => id !== allocation.kit);
      } else {
        const kits = [...activeCase.kits];
        doc.kits = kits?.filter((id) => id !== allocation.kit);
      }
    }
    if (allocation.itemId) {
      if (allocation.additional) {
        const additionalItems = [...activeCase.additionalItems];
        doc.additionalItems = additionalItems?.filter((id) => id !== allocation.itemId);
      } else {
        const items = [...activeCase.items];
        doc.items = items?.filter((id) => id !== allocation.itemId);
      }
    }

    await firebase.db.collection(casesPath).doc(activeCase.id).set(doc, { merge: true });
    await firebase.db.collection(setsAllocationsPath).doc(allocation.id).delete();
  } else {
    throw new Error('Invalid tenant');
  }
};

export const bulkUpdateAllocations = (caseId, allocations) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;
  const userId = state.user?.currentUser?.uid || '';
  const kits = state?.kits?.list || [];
  const items = state?.items?.list || [];
  const path = collections.CASE_SETS_ALLOCATION_COLLECTION(tenantId, caseId);
  const batch = firebase.db.batch();

  if (tenantId) {
    allocations.forEach((allocation) => {
      const ref = firebase.db.collection(path).doc(allocation.id);
      batch.update(ref, allocation);
    });

    await batch.commit();

    const activityPath = collections.CASE_ACTIVITY(tenantId, caseId);
    const shipped = [];

    allocations?.forEach((allocation) => {
      const item = items?.find((i) => i.id === allocation.id);
      if (item) {
        shipped?.push(item.code);
      }
      const kit = kits?.find((k) => k.id === allocation.id);
      if (kit) {
        shipped?.push(kit.name);
      }
    });

    if (shipped?.length) {
      const payload = `The following have been shipped: ${shipped?.join(', ')}`;
      await firebase.db.collection(activityPath).add({
        type: notificationTypes.caseShipmentsSent,
        title: 'Shipments Sent',
        payload,
        createdAt: nowTimestampUTC(),
        arguments: { caseId, ids: allocations?.map((item) => item.id) || [] },
        userId
      });
    }
  } else {
    throw new Error('Invalid tenant');
  }
};

export const setOverdueSetsNumber = (value) => ({ type: SET_OVERDUE_SETS_NUMBER, value });

export const addNewKits = (activeCase, data) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;
  const casesPath = collections.CASES_COLLECTION(tenantId);
  const setAllocationsPath = collections.CASE_SETS_ALLOCATION_COLLECTION(tenantId, activeCase.id);
  const newAdditional = [];

  if (tenantId) {
    let doc = {};
    const kitIds = data?.map((k) => k.id);

    if (activeCase.kitPreference) {
      const additionalKits = activeCase?.additionalKits ? [...activeCase.additionalKits] : [];
      doc.additionalKits = [...additionalKits, ...kitIds];
    } else {
      const kits = activeCase?.kits || [];
      const additionalKits = activeCase?.additionalKits ? [...activeCase.additionalKits] : [];

      kitIds?.forEach((id) => {
        if (kits?.includes(id)) {
          additionalKits?.push(id);
          newAdditional?.push(id);
        } else {
          kits?.push(id);
        }
      });

      doc.kits = kits;
      doc.additionalKits = additionalKits;
    }

    await firebase.db.collection(casesPath).doc(activeCase.id).set(doc, { merge: true });
    for (const kit of data) {
      const isAdditional = !!activeCase.kitPreference || newAdditional?.includes(kit.id);
      const docId = isAdditional ? `${kit.id}_additional` : kit.id;
      await firebase.db.collection(setAllocationsPath).doc(docId).set({
        active: true,
        additional: isAdditional,
        kit: kit.id,
        sets: [],
        status: setAllocationStatuses.AVAILABLE.value,
        quantity: kit.quantity || 1,
      });
    }
  } else {
    throw new Error('Invalid tenant');
  }
};

export const addNewItems = (activeCase, data) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;
  const casesPath = collections.CASES_COLLECTION(tenantId);
  const setAllocationsPath = collections.CASE_SETS_ALLOCATION_COLLECTION(tenantId, activeCase.id);
  const newAdditional = [];

  if (tenantId) {
    let doc = {};
    const itemIds = data?.map((i) => i.id);

    if (activeCase.kitPreference) {
      const additionalItems = activeCase?.additionalItems || [];
      doc.additionalItems = [...additionalItems, ...itemIds];
    } else {
      const items = activeCase?.items || [];
      const additionalItems = activeCase?.additionalItems || [];

      itemIds?.forEach((id) => {
        if (items?.includes(id)) {
          additionalItems?.push(id);
          newAdditional?.push(id);
        } else {
          items?.push(id);
        }
      });

      doc.items = items;
      doc.additionalItems = additionalItems;
    }

    await firebase.db.collection(casesPath).doc(activeCase.id).set(doc, { merge: true });
    for (const item of data) {
      const isAdditional = !!activeCase.kitPreference || newAdditional?.includes(item.id);
      const docId = isAdditional ? `${item.id}_additional` : item.id;
      await firebase.db.collection(setAllocationsPath).doc(docId).set({
        active: true,
        additional: isAdditional,
        itemId: item.id,
        sets: [],
        consumables: [],
        status: setAllocationStatuses.AVAILABLE.value,
        quantity: item.quantity || 1,
      });
    }
  } else {
    throw new Error('Invalid tenant');
  }
};

// Case forms

export const setCaseForms = (forms) => ({ type: SET_CASE_FORMS, forms });

export const addForm = (caseId, formId) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;
  const userId = state.user?.currentUser?.uid || '';
  const path = collections.CUSTOMER_FORMS_COLLECTION(tenantId);

  const doc = {
    active: true,
    status: formStatuses.PENDING,
    formId,
    values: {},
    createdAt: nowTimestampUTC(),
    createdBy: userId,
    caseId,
  };

  const snap = await firebase.db.collection(path).add(doc);
  return snap.id;
};

export const getCaseForm = (caseId, formId) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = state?.tenant?.currentTenant?.id;
  const path = collections.CUSTOMER_FORMS_COLLECTION(tenantId);

  const doc = await firebase.db.collection(path).doc(formId).get();
  const data = doc?.data();
  const form = {
    ...data,
    id: doc.id,
    createdAt: data.createdAt ? data.createdAt.toDate() : '',
    closedAt: data.closedAt ? data.closedAt.toDate() : '',
    submittedAt: data.submittedAt ? data.submittedAt.toDate() : '',
  };

  return form;
};

export const updateCaseForm = (caseId, formId, data, files) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;
  const path = collections.CUSTOMER_FORMS_COLLECTION(tenantId);

  const { values } = data;

  for (const key of Object.keys(values)) {
    if (isMoment(values[key])) {
      values[key] = values[key]?.format('L');
    }
  }

  if (files && Object.keys(files)?.length) {
    for (const key of Object.keys(files)) {
      const fileObj = await uploadFormFile(tenantId, formId, files[key]);
      values[key] = fileObj;
    }
  }

  await firebase.db.collection(path).doc(formId).set({ ...data, updatedAt: nowTimestampUTC() }, { merge: true });
};

const uploadFormFile = async (tenantId, formId, file) => {
  const storageRef = firebase.storage.ref();
  const storagePath = storageRefs.CUSTOMER_FORMS_REF(tenantId, formId);
  const filePath = `${storagePath}/${file.name}`;
  const ref = storageRef.child(filePath);
  const snap = await ref.put(file);
  const downloadUrl = await snap.ref.getDownloadURL();
  const fileObj = {
    active: true,
    fileName: file.name,
    path: filePath,
    downloadUrl,
    createdAt: nowTimestampUTC(),
  };
  return fileObj;
};

// Case proforma

export const setCaseProforma = (proforma) => ({ type: SET_CASE_PROFORMA, proforma });

const findValue = (usage, label) => {
  if (!usage || !usage.values || !label) {
    return '';
  }

  const value = usage.values.find((v) => v.label === label);
  return value ? value.value : '';
};

export const createProformaList = (caseId, usage) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;
  const userId = state.user?.currentUser?.uid || '';
  const items = state?.items?.list || [];

  const path = collections.CASE_PROFORMA(tenantId, caseId);
  const casesPath = collections.CASES_COLLECTION(tenantId);

  await firebase.db.collection(casesPath).doc(caseId).set({ proformaGenerated: true }, { merge: true });

  for (const usageItem of usage) {
    const code = findValue(usageItem, 'REF');
    // const description = findValue(usageItem, 'DESC');
    const item = items?.find((i) => i.code === code);

    const doc = {
      code,
      description: item?.description || '',
      value: item?.value || '',
      rebateCode: item?.rebateCode || '',
      discount: 0,
      quantity: usageItem?.quantity || 1,
      caseId,
      usageId: usageItem?.id || '',
      submittedBy: usageItem?.userId || '',
      createdAt: nowTimestampUTC(),
      createdBy: userId,
      itemId: item?.id || '',
    };

    await firebase.db.collection(path).add(doc);
  }
};

export const updateCaseProforma = (caseId, proformaId, data) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;
  const path = collections.CASE_PROFORMA(tenantId, caseId);

  await firebase.db.collection(path).doc(proformaId).set(data, { merge: true });
};

export const deleteProformaItem = (caseId, proformaId) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;
  const path = collections.CASE_PROFORMA(tenantId, caseId);

  await firebase.db.collection(path).doc(proformaId).delete();
};

export const approveProforma = (caseId) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;
  const userId = state?.user?.currentUser?.uid;

  const path = collections.CASES_COLLECTION(tenantId);
  const activityPath = collections.CASE_ACTIVITY(tenantId, caseId);

  if (tenantId) {
    await firebase.db.collection(path).doc(caseId).set({ proformaApproved: true }, { merge: true });

    await firebase.db.collection(activityPath).add({
      type: notificationTypes.proformaApproved,
      title: 'Proforma Approved',
      payload: 'The proforma for this case has been approved',
      createdAt: nowTimestampUTC(),
      arguments: { caseId },
      userId
    });
  } else {
    throw new Error('Invalid tenant');
  }
};

export const amendProforma = (caseId) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;
  const userId = state?.user?.currentUser?.uid;

  const path = collections.CASES_COLLECTION(tenantId);
  const activityPath = collections.CASE_ACTIVITY(tenantId, caseId);

  if (tenantId) {
    await firebase.db.collection(path).doc(caseId).set({ proformaApproved: false }, { merge: true });

    await firebase.db.collection(activityPath).add({
      type: notificationTypes.proformaAmended,
      title: 'Proforma Amended',
      payload: 'The proforma has been amended',
      createdAt: nowTimestampUTC(),
      arguments: { caseId },
      userId
    });
  } else {
    throw new Error('Invalid tenant');
  }
};

// Toggles

export const clearCase = () => ({ type: CLEAR_CASE });

export const toggleShipped = () => ({ type: TOGGLE_SHIPPED });

export const toggleReturned = () => ({ type: TOGGLE_RETURNED });

export const toggleClosed = () => ({ type: TOGGLE_CLOSED });

export const toggleConsignment = () => ({ type: TOGGLE_CONSIGNMENT });

export const toggleLoan = () => ({ type: TOGGLE_LOAN });

export const toggleCanceled = (value) => ({ type: TOGGLE_CANCELED, value });

export const setFilters = (filters) => ({ type: SET_FILTERS, filters });

export const setUsageFilters = (filters) => ({ type: SET_USAGE_FILTERS, filters });

export const setStatusFilter = (filter) => ({ type: SET_STATUS_FILTERS, filter });

export const setOrder = (value) => ({ type: SET_ORDER, value });

export const setOrderBy = (value) => ({ type: SET_ORDER_BY, value });

export const setPage = (value) => ({ type: SET_PAGE, value });

export const setRowsPerPage = (value) => ({ type: SET_ROWS_PER_PAGE, value });

export const setSearch = (value) => ({ type: SET_SEARCH, value });

// Case activity

export const setActivity = (activity) => ({ type: SET_CASE_ACTIVITY, activity });

export const getActivity = (caseId) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;
  const path = collections.CASE_ACTIVITY(tenantId, caseId);

  dispatch({ type: GET_CASE_ACTIVITY });

  const snapshot = await firebase.db.collection(path).get();
  const activity = snapshot.docs.map(doc => {
    const data = doc.data();
    return {
      ...data,
      id: doc.id,
      createdAt: data.createdAt ? data.createdAt.toDate() : '',
    };
  });

  return orderBy(activity, 'createdAt', 'desc');
};

export const createActivity = (caseId, activity) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;
  const userId = state?.user?.currentUser?.uid;
  const path = collections.CASE_ACTIVITY(tenantId, caseId);

  await firebase.db.collection(path).add({
    type: activity?.type || '',
    title: activity?.title || '',
    payload: activity?.payload || '',
    createdAt: nowTimestampUTC(),
    arguments: { caseId },
    userId
  });
}

// Case flow

export const setFlow = (flow) => ({ type: SET_CASE_FLOW, flow });

export const getFlow = (caseId) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;
  const path = collections.CASE_FLOW(tenantId, caseId);

  dispatch({ type: GET_CASE_FLOW });

  const snapshot = await firebase.db.collection(path).get();
  const steps = snapshot.docs.map(doc => {
    const data = doc.data();
    return {
      ...data,
      id: doc.id,
      completedAt: data.completedAt ? data.completedAt.toDate() : '',
    };
  });

  return orderBy(steps, 'stepNumber', 'asc');
};

export const addFlow = (caseId, flowId, previousSteps = [], connectedTenantId) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = connectedTenantId || state?.tenant?.currentTenant?.id;
  const casePath = collections.CASES_COLLECTION(tenantId, caseId);
  const flowPath = collections.CASE_FLOW(tenantId, caseId);

  await firebase.db.collection(casePath).doc(caseId).set({ flow: flowId }, { merge: true });

  if (previousSteps && previousSteps.length) {
    await Promise.all(previousSteps?.map((stepId) => firebase.db.collection(flowPath).doc(stepId).delete()));
  }

  const steps = await dispatch(getSteps(flowId));
  await Promise.all(steps?.map((step) => {
    const doc = {
      active: true,
      status: flowStepStatuses.PENDING.value,
      title: step.title || '',
      subtitle: step.subtitle || '',
      description: step.description || '',
      stepNumber: step.stepNumber || 0,
      config: {
        documentUpload: !!step.documentUpload,
        documentUploadRequired: !!step.documentUploadRequired,
        imageCapture: !!step.imageCapture,
        imageCaptureRequired: !!step.imageCaptureRequired,
        notesField: !!step.notesField,
        notesFieldRequired: !!step.notesFieldRequired,
        urlLink: !!step.urlLink,
        urlLinkRequired: !!step.urlLinkRequired,
        dueDate: Number(step.dueDate) || 0,
        openInBrowser: !!step.openInBrowser,
        roles: step.roles || [],
        readAccess: step.readAccess || []
      },
    };

    return firebase.db.collection(flowPath).doc(step.id).set(doc, { merge: true });
  }));
};

export const completeStep = (caseId, stepId, data) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;
  const userId = state?.user?.currentUser?.uid;
  const flowPath = collections.CASE_FLOW(tenantId, caseId);

  const doc = {
    ...data,
    status: flowStepStatuses.COMPLETED.value,
    completedAt: nowTimestampUTC(),
    userId,
  };

  return firebase.db.collection(flowPath).doc(stepId).set(doc, { merge: true });
};

// Case checklists

export const setChecklists = (checklists) => ({ type: SET_CASE_CHECKLISTS, checklists });

export const getChecklists = (caseId) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;
  const path = collections.CASE_CHECKLISTS(tenantId, caseId);

  dispatch({ type: GET_CASE_CHECKLISTS });

  const snapshot = await firebase.db.collection(path).get();
  const checklists = snapshot.docs.map(doc => {
    const data = doc.data();
    return {
      ...data,
      id: doc.id,
    };
  });

  return checklists;
};

export const addChecklist = (caseId, data) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;
  const path = collections.CASE_CHECKLISTS(tenantId, caseId);

  await firebase.db.collection(path).doc(data.id).set(data, { merge: true });
};

export const updateChecklist = (caseId, checklistId, data, createActivity) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;
  const userId = state?.user?.currentUser?.uid;
  const path = collections.CASE_CHECKLISTS(tenantId, caseId);

  await firebase.db.collection(path).doc(checklistId).set(data, { merge: true });

  if (createActivity) {
    const activityPath = collections.CASE_ACTIVITY(tenantId, caseId);
    await firebase.db.collection(activityPath).add({
      type: notificationTypes.checklistSubmitted,
      title: 'Set Inspection Complete',
      payload: data.setNumber,
      createdAt: nowTimestampUTC(),
      arguments: { caseId, checklistId, setNumber: data.setNumber },
      userId
    });
  }
};

export const addReturnSetsActivity = (caseId, allocations) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;
  const userId = state?.user?.currentUser?.uid;
  const path = collections.CASE_ACTIVITY(tenantId, caseId);

  const returned = allocations?.map((item) => item?.kitName || item?.code)?.filter((item) => !!item);

  if (returned?.length) {
    const payload = `The following have been returned: ${returned?.join(', ')}`;
    await firebase.db.collection(path).add({
      type: notificationTypes.caseSetsReturned,
      title: 'Sets & Items Returned',
      payload,
      createdAt: nowTimestampUTC(),
      arguments: { caseId, ids: allocations?.map((item) => item.id) || [] },
      userId
    });
  }
};

export const deleteChecklist = (caseId, checklistId) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;
  const path = collections.CASE_CHECKLISTS(tenantId, caseId);

  await firebase.db.collection(path).doc(checklistId).delete();
};

export const getDispatchDocuments = (caseId) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;
  const path = collections.CASES_COLLECTION(tenantId);

  const snap = await firebase.db.collection(path).doc(caseId).get();
  const caseDoc = snap?.data();

  const { products = [], kits = [] } = caseDoc;

  const querySnapshot = await firebase.db.collectionGroup('files')
    .where('tenantId', '==', tenantId)
    .where('active', '==', true)
    .where('dispatchDocument', '==', true)
    .get();
  const files = querySnapshot?.docs?.map((doc) => ({ ...doc.data(), id: doc.id })) || [];

  return files?.filter((file) => file.allProducts
    || file?.relatedProducts?.some((product) => products?.includes(product))
    || file?.relatedKits?.some((kit) => kits?.includes(kit)));
};

// Subscriptions

export const subscribeToCases = () => async (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;
  const currentUser = state.user.currentUser;
  const path = collections.CASES_COLLECTION(tenantId);

  const query = firebase.db.collection(path);
  const hospitals = state.hospitals?.list?.length ? state.hospitals?.list : await dispatch(getHospitals(tenantId));

  return query
    .onSnapshot({
      error: (e) => console.error(e),
      next: (querySnapshot) => {
        let cases = [];
        querySnapshot.forEach(async (documentSnapshot) => {
          const id = documentSnapshot.id;
          const data = documentSnapshot.data();
          const item = {
            ...data,
            id,
            createdAt: data && data.createdAt ? data.createdAt.toDate() : '',
            updatedAt: data && data.updatedAt ? data.updatedAt.toDate() : '',
            completedAt: data && data.completedAt ? data.completedAt.toDate() : '',
            date: data && data.date ? data.date.toDate() : '',
            proformaSentAt: data && data.proformaSentAt ? data.proformaSentAt.toDate() : '',
            proformaDate: data && data.proformaDate ? data.proformaDate.toDate() : '',
            collectionDate: data && data.collectionDate ? data.collectionDate.toDate() : '',
          };

          if ([ADMIN, OPERATIONS, CUSTOMER_SERVICE, LOGISTICS, SALES_MANAGER, MARKETING, FINANCE].includes(currentUser.role)) {
            cases?.push(item);
          } else {
            const assignedSurgeons = currentUser.assignedSurgeons && currentUser.assignedSurgeons.length ? currentUser.assignedSurgeons : ['empty'];
            if (assignedSurgeons?.includes(data?.surgeon)) {
              cases?.push(item);
            }
          }
        });

        if (territoryRoles?.includes(currentUser.role) && !!currentUser.territoryVisibility) {
          const userHospitals = hospitals?.filter((h) => h.territories?.some((territory) => currentUser?.assignedTerritories?.includes(territory)));
          cases = cases?.filter((item) => userHospitals?.map((h) => h.id)?.includes(item.hospital));
        }

        return dispatch(setCases(orderBy(cases, 'date', 'asc')));
      },
    });
};

export const subscribeToCase = (caseId, connectedTenantId) => async (dispatch, getState) => {
  const state = getState();
  const tenant = state?.tenant?.currentTenant;
  const tenantId = connectedTenantId || tenant?.id;
  const currentUser = state.user.currentUser;
  const path = collections.CASES_COLLECTION(tenantId);

  const hospitals = state.hospitals?.list?.length ? state.hospitals?.list : await dispatch(getHospitals(tenantId));

  return firebase
    .db
    .collection(path)
    .doc(caseId)
    .onSnapshot({
      error: (e) => console.error(e),
      next: (doc) => {
        const data = doc.data();
        let activeCase;

        if (![ADMIN, OPERATIONS, CUSTOMER_SERVICE, LOGISTICS, SALES_MANAGER, MARKETING, FINANCE].includes(currentUser.role)
          && !currentUser.assignedSurgeons.includes(data?.surgeon)) {
          activeCase = { id: null };
        } else {
          activeCase = {
            ...data,
            id: doc.exists ? doc.id : null,
            createdAt: data && data.createdAt ? data.createdAt.toDate() : '',
            updatedAt: data && data.updatedAt ? data.updatedAt.toDate() : '',
            completedAt: data && data.completedAt ? data.completedAt.toDate() : '',
            date: data && data.date ? data.date.toDate() : '',
            proformaSentAt: data && data.proformaSentAt ? data.proformaSentAt.toDate() : '',
            proformaDate: data && data.proformaDate ? data.proformaDate.toDate() : '',
            collectionDate: data && data.collectionDate ? data.collectionDate.toDate() : '',
          };
        }

        if (territoryRoles?.includes(currentUser.role) && !!currentUser.territoryVisibility) {
          const userHospitals = hospitals?.filter((h) => h.territories?.some((territory) => currentUser?.assignedTerritories?.includes(territory)));
          if (!userHospitals?.map((h) => h.id)?.includes(activeCase.hospital)) {
            activeCase = { id: null };
          }
        }

        return dispatch(setCase(activeCase));
      },
    });
};

export const subscribeToCaseNotes = (caseId, connectedTenantId) => (dispatch, getState) => {
  const state = getState();
  const tenant = state?.tenant?.currentTenant;
  const tenantId = connectedTenantId || tenant?.id;
  const path = collections.CASE_NOTES_COLLECTION(tenantId, caseId);

  return firebase
    .db
    .collection(path)
    .onSnapshot({
      error: (e) => console.error(e),
      next: (querySnapshot) => {
        let notes = [];
        querySnapshot.forEach((documentSnapshot) => {
          const id = documentSnapshot.id;
          const data = documentSnapshot.data();

          notes = [...notes, {
            ...data,
            id,
            createdAt: data.createdAt ? data.createdAt.toDate() : '',
          }];
        });
        const sortedNotes = orderBy(notes, 'createdAt', 'desc');

        return dispatch(setCaseNotes(sortedNotes));
      },
    });
};

export const subscribeToCaseUsageNotes = (caseId, connectedTenantId) => (dispatch, getState) => {
  const state = getState();
  const tenant = state?.tenant?.currentTenant;
  const tenantId = connectedTenantId || tenant?.id;
  const path = collections.CASE_USAGE_NOTES_COLLECTION(tenantId, caseId);

  return firebase
    .db
    .collection(path)
    .onSnapshot({
      error: (e) => console.error(e),
      next: (querySnapshot) => {
        let notes = [];
        querySnapshot.forEach((documentSnapshot) => {
          const id = documentSnapshot.id;
          const data = documentSnapshot.data();

          notes = [...notes, {
            ...data,
            id,
            createdAt: data.createdAt ? data.createdAt.toDate() : '',
          }];
        });
        const sortedNotes = orderBy(notes, 'createdAt', 'desc');

        return dispatch(setCaseUsageNotes(sortedNotes));
      },
    });
};

export const subscribeToCaseUsage = (caseId, connectedTenantId) => (dispatch, getState) => {
  const state = getState();
  const tenant = state?.tenant?.currentTenant;
  const tenantId = connectedTenantId || tenant?.id;
  const path = collections.CASE_USAGE_COLLECTION(tenantId, caseId);

  return firebase
    .db
    .collection(path)
    .onSnapshot({
      error: (e) => console.error(e),
      next: (querySnapshot) => {
        let usage = [];
        querySnapshot.forEach((documentSnapshot) => {
          const id = documentSnapshot.id;
          const data = documentSnapshot.data();

          usage = [...usage, {
            ...data,
            id,
            createdAt: data.createdAt ? data.createdAt.toDate() : '',
          }];
        });

        return dispatch(setCaseUsage(usage));
      },
    });
};

export const subscribeToCaseDocuments = (caseId, connectedTenantId) => (dispatch, getState) => {
  const state = getState();
  const tenant = state?.tenant?.currentTenant;
  const tenantId = connectedTenantId || tenant?.id;
  const path = collections.CASE_DOCUMENTS_COLLECTION(tenantId, caseId);

  return firebase
    .db
    .collection(path)
    .onSnapshot({
      error: (e) => console.error(e),
      next: (querySnapshot) => {
        let documents = [];
        querySnapshot.forEach((documentSnapshot) => {
          const id = documentSnapshot.id;
          const data = documentSnapshot.data();

          documents = [...documents, {
            ...data,
            id,
            createdAt: data.createdAt ? data.createdAt.toDate() : '',
          }];
        });

        return dispatch(setCaseDocuments(documents));
      },
    });
};

export const subscribeToSetsAllocation = (caseId, connectedTenantId) => (dispatch, getState) => {
  const state = getState();
  const tenant = state?.tenant?.currentTenant;
  const tenantId = connectedTenantId || tenant?.id;
  const path = collections.CASE_SETS_ALLOCATION_COLLECTION(tenantId, caseId);

  return firebase
    .db
    .collection(path)
    .onSnapshot({
      error: (e) => console.error(e),
      next: (querySnapshot) => {
        let sets = [];
        querySnapshot.forEach((documentSnapshot) => {
          const id = documentSnapshot.id;
          const data = documentSnapshot.data();

          sets = [...sets, {
            ...data,
            id,
            createdAt: data.createdAt ? data.createdAt.toDate() : '',
            shippingDate: data.shippingDate ? data.shippingDate.toDate() : '',
            returnDate: data.returnDate ? data.returnDate.toDate() : '',
          }];
        });

        return dispatch(setSetsAllocation(sets));
      },
    });
};

export const subscribeToCaseActivity = (caseId, connectedTenantId) => (dispatch, getState) => {
  const state = getState();
  const tenant = state?.tenant?.currentTenant;
  const tenantId = connectedTenantId || tenant?.id;
  const path = collections.CASE_ACTIVITY(tenantId, caseId);

  return firebase
    .db
    .collection(path)
    .onSnapshot({
      error: (e) => console.error(e),
      next: (querySnapshot) => {
        let activity = [];
        querySnapshot.forEach((documentSnapshot) => {
          const id = documentSnapshot.id;
          const data = documentSnapshot.data();

          activity = [...activity, {
            ...data,
            id,
            createdAt: data.createdAt ? data.createdAt.toDate() : '',
          }];
        });

        return dispatch(setActivity(orderBy(activity, 'createdAt', 'desc')));
      },
    });
};

export const subscribeToCaseFlow = (caseId, connectedTenantId) => (dispatch, getState) => {
  const state = getState();
  const tenant = state?.tenant?.currentTenant;
  const tenantId = connectedTenantId || tenant?.id;
  const path = collections.CASE_FLOW(tenantId, caseId);

  return firebase
    .db
    .collection(path)
    .onSnapshot({
      error: (e) => console.error(e),
      next: (querySnapshot) => {
        let steps = [];
        querySnapshot.forEach((documentSnapshot) => {
          const id = documentSnapshot.id;
          const data = documentSnapshot.data();

          steps = [...steps, {
            ...data,
            id,
            completedAt: data.completedAt ? data.completedAt.toDate() : '',
          }];
        });

        return dispatch(setFlow(orderBy(steps, 'stepNumber', 'asc')));
      },
    });
};

export const subscribeToCaseChecklists = (caseId, connectedTenantId) => (dispatch, getState) => {
  const state = getState();
  const tenant = state?.tenant?.currentTenant;
  const tenantId = connectedTenantId || tenant?.id;
  const path = collections.CASE_CHECKLISTS(tenantId, caseId);

  return firebase
    .db
    .collection(path)
    .onSnapshot({
      error: (e) => console.error(e),
      next: (querySnapshot) => {
        let checklists = [];
        querySnapshot.forEach((documentSnapshot) => {
          const id = documentSnapshot.id;
          const data = documentSnapshot.data();

          checklists = [...checklists, {
            ...data,
            id,
          }];
        });

        return dispatch(setChecklists(checklists));
      },
    });
};

export const subscribeToCaseForms = (caseId) => (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;
  const path = collections.CUSTOMER_FORMS_COLLECTION(tenantId);

  return firebase
    .db
    .collection(path)
    .where('caseId', '==', caseId)
    .onSnapshot({
      error: (e) => console.error(e),
      next: (querySnapshot) => {
        let forms = [];
        querySnapshot.forEach((documentSnapshot) => {
          const id = documentSnapshot.id;
          const data = documentSnapshot.data();

          forms = [...forms, {
            ...data,
            id,
            createdAt: data.createdAt ? data.createdAt.toDate() : '',
            submittedAt: data.submittedAt ? data.submittedAt.toDate() : '',
          }];
        });

        return dispatch(setCaseForms(forms));
      },
    });
};

export const subscribeToCaseProforma = (caseId, connectedTenantId) => (dispatch, getState) => {
  const state = getState();
  const tenant = state?.tenant?.currentTenant;
  const tenantId = connectedTenantId || tenant?.id;
  const path = collections.CASE_PROFORMA(tenantId, caseId);

  return firebase
    .db
    .collection(path)
    .onSnapshot({
      error: (e) => console.error(e),
      next: (querySnapshot) => {
        let proforma = [];
        querySnapshot.forEach((documentSnapshot) => {
          const id = documentSnapshot.id;
          const data = documentSnapshot.data();

          proforma = [...proforma, {
            ...data,
            id,
            createdAt: data.createdAt ? data.createdAt.toDate() : '',
          }];
        });

        return dispatch(setCaseProforma(proforma));
      },
    });
};

// Utils

export const generatePdf = (caseId) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;
  const idToken = await firebase.auth.currentUser.getIdToken();

  try {
    await getFile(urls.cases.exportSets(caseId, tenantId), idToken, `${caseId} - Dispatch`, 'pdf');
  } catch (err) {
    throw new Error(err);
  }
};

export const generateChecklistsPdf = (caseId) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;
  const idToken = await firebase.auth.currentUser.getIdToken();

  try {
    await getFile(urls.cases.exportChecklists(caseId, tenantId), idToken, `${caseId} - Checklists`, 'pdf');
  } catch (err) {
    throw new Error(err);
  }
};

export const exportProforma = (caseId) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;
  const idToken = await firebase.auth.currentUser.getIdToken();

  try {
    await getFile(urls.cases.exportProforma(caseId, tenantId), idToken, `Proforma_${caseId}`, 'pdf');
  } catch (err) {
    throw new Error(err);
  }
};

export const exportUsage = (caseId, connectedTenantId) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = connectedTenantId || state?.tenant?.currentTenant?.id;
  const idToken = await firebase.auth.currentUser.getIdToken();

  try {
    await getFile(urls.cases.exportUsage(caseId, tenantId), idToken, caseId);
  } catch (err) {
    throw new Error(err);
  }
};

export const exportCases = (caseIds) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;
  const idToken = await firebase.auth.currentUser.getIdToken();

  try {
    await getFileWithParameters(urls.cases.exportCases(tenantId), idToken, 'Cases report', 'xlsx', { caseIds });
  } catch (err) {
    throw new Error(err);
  }
};

export const exportBilling = (caseIds) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;
  const idToken = await firebase.auth.currentUser.getIdToken();

  try {
    await getFileWithParameters(urls.cases.exportBilling(tenantId), idToken, 'Billing report', 'xlsx', { caseIds });
  } catch (err) {
    throw new Error(err);
  }
};

export const exportUsageReport = (items) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;
  const idToken = await firebase.auth.currentUser.getIdToken();

  try {
    await getFileWithParameters(urls.cases.exportUsageReport(tenantId), idToken, 'Usage report', 'xlsx', { items });
  } catch (err) {
    throw new Error(err);
  }
};

export const exportDispatchDocument = (path, fileName) => async () => {
  const storageRef = firebase.storage.ref();
  storageRef.child(path).getDownloadURL()
    .then((url) => {
      const xhr = new XMLHttpRequest();
      xhr.responseType = 'blob';
      xhr.onload = (event) => {
        const blob = xhr.response;
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      };
      xhr.open('GET', url);
      xhr.send();
    })
};
