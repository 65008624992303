import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { NotificationsBlock } from '../../../components/notifications';

import { notificationTypes } from '../../../constants/enums';

import './notifications-page.scss';

const {
  caseBooked,
  caseDateUpdated,
  caseNoteAdded,
  caseOverdue,
  caseRequested,
  caseUsageSubmitted,
  caseCancelled,
  caseDeleted,
  countAdded,
  countCompleted,
  preferenceAdded,
  taskDue,
  transferRequested,
  transferDeclined,
  transferAccepted,
  formSubmitted,
} = notificationTypes;

const NotificationsPage = () => {
  const notifications = useSelector((state) => state.notifications.list);
  const tenantColor = useSelector((state) => state.tenant.currentTenant.colorPrimary);

  const casesNotifications = useMemo(() => {
    return notifications.filter((notification) => [
      caseBooked, caseNoteAdded, caseDateUpdated, caseOverdue, caseRequested, caseUsageSubmitted,
      transferRequested, transferAccepted, transferDeclined, caseCancelled, caseDeleted,
    ].includes(notification.type));
  }, [notifications]);

  const customersNotifications = useMemo(() => {
    return notifications.filter((notification) => [preferenceAdded, taskDue, formSubmitted].includes(notification.type));
  }, [notifications]);

  const inventoryNotifications = useMemo(() => {
    return notifications.filter((notification) => [countAdded, countCompleted].includes(notification.type));
  }, [notifications])

  return (
    <div className='notifications-page'>
      <div className='page-title'>
        Notifications
      </div>

      <div className='notifications-page__body'>
        <NotificationsBlock
          type='cases'
          notifications={casesNotifications}
          tenantColor={tenantColor}
        />
        <NotificationsBlock
          type='customers'
          notifications={customersNotifications}
          tenantColor={tenantColor}
        />
        <NotificationsBlock
          type='inventory'
          notifications={inventoryNotifications}
          tenantColor={tenantColor}
        />
      </div>
    </div>
  );
};

export default NotificationsPage;
