import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';

import CircularProgress from '@material-ui/core/CircularProgress';

import Modal from '../../shared/modal';
import Checkbox from '../../shared/Checkbox';
import Button from '../../shared/Button';
import Input from '../../shared/Input';

import { getCasesByKitId, getSetsAllocation } from '../../../actions/casesActions';

import { caseStatusOptions, setAllocationStatuses } from '../../../constants/enums';

const TransferSetsModal = (props) => {
  const {
    open,
    onClose,
    onSubmit,
    allocation,
    loading,
    caseId,
  } = props;

  const dispatch = useDispatch();

  const [selectedCase, setSelectedCase] = useState(null);
  const [search, setSearch] = useState('');

  const [cases, setCases] = useState([]);
  const [fetching, setFetching] = useState(false);

  useEffect(() => {
    if (open) {
      onLoad().catch((err) => console.error(err));
    } else {
      setCases([]);
    }
  }, [open]);

  const onLoad = async () => {
    setFetching(true);

    try {
      const list = await dispatch(getCasesByKitId(allocation.kit));

      for (const item of list) {
        const setsAllocation = await dispatch(getSetsAllocation(item.id, true));
        item.setsAllocation = setsAllocation;
      }

      setCases(list);
    } catch (err) {
      console.error(err);
    } finally {
      setFetching(false);
    }
  };

  const handleSubmit = () => {
    onSubmit(allocation, selectedCase);
  };

  const isAvailable = (setsAllocation) => {
    if (!setsAllocation || !setsAllocation.length) {
      return false;
    }

    const item = setsAllocation?.find((a) => a?.kit === allocation?.kit);
    return item && item.status === setAllocationStatuses.AVAILABLE.value;
  };

  const list = useMemo(() => {
    return cases.filter((item) => (
      item.id.toLowerCase().includes(search.toLowerCase()) && item.id !== caseId &&
        item.status === caseStatusOptions.booked && isAvailable(item.setsAllocation)
    ));
  }, [search, cases]);

  return (
    <Modal
      open={open}
      onClose={onClose}
      title='Transfer Sets'
      fullWidth
    >
      <div className='case-sets-modal__container'>
        <>
          <Input
            type='search'
            placeholder='Search'
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />

          <div className='m-t-md m-b-md secondary'>Cases</div>
          <div className='m-b-lg form__select-users'>
            {fetching ? (
              <div className='d-flex flex-center'>
                <CircularProgress />
              </div>
            ) : (
              <>
                {!!list?.length ? (
                  list.map((item) => (
                    <div className='md-user-info' key={item.id}>
                      <Checkbox
                        input={{
                          onClick: () => setSelectedCase(item.id),
                          checked: item.id === selectedCase
                        }}
                        fontSize='small'
                        label={`${item.id} (${item.patientReference})`}
                      />
                      <span
                        style={{ color: setAllocationStatuses.AVAILABLE.color }}
                        className='font-size-sm'
                      >
                      Available
                    </span>
                    </div>
                  ))
                ) : (
                  <div className='text-center secondary font-bold m-t-md'>No cases</div>
                )}
              </>
            )}
          </div>
        </>

        <div className='form__actions p-t-lg'>
          <Button
            type='submit'
            text='Transfer'
            onClick={handleSubmit}
            disabled={loading || !selectedCase}
            loading={loading}
          />
        </div>
      </div>
    </Modal>
  );
};

export default TransferSetsModal;
