import React from 'react';
import { Field, Form } from 'react-final-form';

import Button from '../shared/Button';
import Input from '../shared/Input';
import MultiSelect from '../shared/MultiSelect';
import Checkbox from '../shared/Checkbox';

import userRoles from '../../constants/userRoles';

import '../../styles/shared/form.scss';

const roleOptions = Object.values(userRoles).map(({ label, name }) => ({ label, value: name }));

const FlowStepForm = (props) => {
  const {
    mode = 'create',
    initialValues,
    buttonText,
    onSubmit,
    loading,
    onClose,
  } = props;

  const { title } = initialValues || {};

  const validate = (values) => {
    const errors = {};

    if (!values.title) {
      errors.title = 'Required';
    }

    if (!values.subtitle) {
      errors.subtitle = 'Required';
    }

    return errors;
  };

  return (
    <div className='form__container'>
      <Form
        onSubmit={(formObj) => onSubmit(formObj, mode)}
        validate={validate}
        initialValues={{
          ...initialValues,
          steps: initialValues?.steps || []
        }}
        subscription={{ pristine: true, invalid: true, values: true }}
      >
        {
          ({ handleSubmit, submitting, invalid, pristine, values }) => (
            <form onSubmit={handleSubmit} className='form__body'>
              <div className='form__subtitle'>
                { title ? title : 'Add Step' }
              </div>
              { mode === 'update' && (
                <Field name='active'>
                  {({ input, meta }) => (
                    <Input
                      type='switch'
                      name={input.name}
                      value={input.value}
                      onChange={input.onChange}
                      placeholder='Status'
                    />
                  )}
                </Field>
              )}

              <div className='field__title'>Display Name</div>
              <div className='combined-fields__container'>
                <Field name='title'>
                  {({ input, meta }) => (
                    <Input
                      name={input.name}
                      value={input.value}
                      borderless
                      type='text'
                      onChange={input.onChange}
                      startAdornment='Title'
                    />
                  )}
                </Field>
                <Field name='subtitle'>
                  {({ input, meta }) => (
                    <Input
                      name={input.name}
                      value={input.value}
                      borderless
                      type='text'
                      onChange={input.onChange}
                      startAdornment='Sub'
                    />
                  )}
                </Field>
              </div>

              <div className='m-b-sm'>
                <div className='field__title'>Description</div>
                <Field name='description'>
                  {({ input, meta }) => (
                    <Input
                      name={input.name}
                      value={input.value}
                      placeholder='Description'
                      type='textarea'
                      onChange={input.onChange}
                    />
                  )}
                </Field>
              </div>

              <div className='m-b-sm'>
                <div className='field__title'>Step Functionality (check if the field is mandatory)</div>

                <div className='d-flex'>
                  <Field name='notesField'>
                    {({ input, meta }) => (
                      <Input
                        type='switch'
                        name={input.name}
                        value={input.value}
                        onChange={input.onChange}
                        placeholder='Notes Field'
                      />
                    )}
                  </Field>

                  <Field name='notesFieldRequired' type='checkbox'>
                    {(props) => (
                      <div className='m-l-lg m-b-lg'>
                        <Checkbox
                          input={props.input}
                          fontSize='small'
                        />
                      </div>
                    )}
                  </Field>
                </div>

                <div className='d-flex'>
                  <Field name='documentUpload'>
                    {({ input, meta }) => (
                      <Input
                        type='switch'
                        name={input.name}
                        value={input.value}
                        onChange={input.onChange}
                        placeholder='Document Upload'
                      />
                    )}
                  </Field>

                  <Field name='documentUploadRequired' type='checkbox'>
                    {({ input }) => (
                      <div className='m-l-lg m-b-lg'>
                        <Checkbox
                          input={input}
                          fontSize='small'
                        />
                      </div>
                    )}
                  </Field>
                </div>

                <div className='d-flex'>
                  <Field name='imageCapture'>
                    {({ input, meta }) => (
                      <Input
                        type='switch'
                        name={input.name}
                        value={input.value}
                        onChange={input.onChange}
                        placeholder='Image Capture'
                      />
                    )}
                  </Field>

                  <Field name='imageCaptureRequired' type='checkbox'>
                    {({ input }) => (
                      <div className='m-l-lg m-b-lg'>
                        <Checkbox
                          input={input}
                          fontSize='small'
                        />
                      </div>
                    )}
                  </Field>
                </div>

                <div className='d-flex'>
                  <Field name='urlLink'>
                    {({ input, meta }) => (
                      <Input
                        type='switch'
                        name={input.name}
                        value={input.value}
                        onChange={input.onChange}
                        placeholder='URL Link'
                      />
                    )}
                  </Field>

                  <Field name='urlLinkRequired' type='checkbox'>
                    {({ input }) => (
                      <div className='m-l-lg m-b-lg'>
                        <Checkbox
                          input={input}
                          fontSize='small'
                        />
                      </div>
                    )}
                  </Field>
                </div>

                <Field name='openInBrowser'>
                  {({ input, meta }) => (
                    <Input
                      type='switch'
                      name={input.name}
                      value={input.value}
                      onChange={input.onChange}
                      placeholder='Open In Mobile Browser'
                    />
                  )}
                </Field>
              </div>

              <div className='field__title'>Due Date (days)</div>
              <Field name='dueDate'>
                {({ input, meta }) => (
                  <Input
                    name={input.name}
                    value={input.value}
                    placeholder='No. of calendar days prior to case date'
                    type='text'
                    numbersOnly
                    onChange={input.onChange}
                  />
                )}
              </Field>

              <div className='m-b-lg'>
                <div className='field__title'>Permission to Complete</div>

                <Field name='allRoles' type='checkbox'>
                  {({ input }) => (
                    <div className='m-b-sm'>
                      <Checkbox
                        input={input}
                        fontSize='small'
                        label='Allow all roles to complete the step'
                      />
                    </div>
                  )}
                </Field>

                {!values?.allRoles && (
                  <Field name='roles'>
                    {({ input, meta }) => (
                      <MultiSelect
                        name={input.name}
                        value={input.value || []}
                        onChange={input.onChange}
                        input={input}
                        options={roleOptions}
                      />
                    )}
                  </Field>
                )}
              </div>

              <div className='m-b-lg'>
                <div className='field__title'>Read Access</div>

                <Field name='allRolesRead' type='checkbox'>
                  {({ input }) => (
                    <div className='m-b-sm'>
                      <Checkbox
                        input={input}
                        fontSize='small'
                        label='Allow all roles to view the step data'
                      />
                    </div>
                  )}
                </Field>

                {!values?.allRolesRead && (
                  <Field name='readAccess'>
                    {({ input, meta }) => (
                      <MultiSelect
                        name={input.name}
                        value={input.value || []}
                        onChange={input.onChange}
                        input={input}
                        options={roleOptions}
                      />
                    )}
                  </Field>
                )}
              </div>

              <div className='form__actions'>
                <Button
                  type='submit'
                  text={buttonText || 'Save Changes'}
                  disabled={invalid || loading || pristine}
                  loading={loading}
                  onClick={handleSubmit}
                />
                {mode === 'create' && (
                  <Button
                    type='cancel'
                    onClick={onClose}
                  />
                )}
              </div>
            </form>
          )}
      </Form>
    </div>
  );
};

export default FlowStepForm;
