import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import moment from 'moment';

import Input from '../../../components/shared/Input';
import ConfirmationModal from '../../../components/shared/modal/ConfirmationModal';
import LoadScreen from '../../../components/load-screen';
import SetsTable from '../../../components/sets/SetsTable';
import QuarantineForm from '../../../components/sets/QuarantineForm';
import QuarantineModal from '../../../components/sets/QuarantineModal';
import ConsignmentModal from '../../../components/sets/ConsignmentModal';
import FilterButton from '../../../components/sets/FilterButton';

import { getCasesWithSetsAllocation, setCaseView } from '../../../actions/casesActions';
import { getSurgeons } from '../../../actions/surgeonsActions';
import {
  subscribeToSets,
  updateSet,
  subscribeToSetLabels,
  resetTargetTurns,
  uploadDocument,
} from '../../../actions/setsActions';
import { getKits, getSections } from '../../../actions/kitsActions';

import { useLoading, useAlert } from '../../../hooks';

import routes from '../../../constants/routes';
import { setPositionOptions } from '../../../constants/enums';

import './sets-page.scss';

const SetsPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const hospitals = useSelector((state) => state?.hospitals.list);
  const sets = useSelector((state) => state?.sets.list?.filter((set) => !!set.active));
  const labels = useSelector((state) => state?.sets.labels);
  const kits = useSelector((state) => state?.kits.list);
  const itemsList = useSelector((state) => state?.items.list);
  const tenantColor = useSelector((state) => state?.tenant.currentTenant.colorPrimary);
  const tenantId = useSelector((state) => state?.tenant.currentTenant.id);
  const itemsLoaded = useSelector((state) => state?.items?.isLoaded);

  const [cases, setCases] = useState([]);
  const [items, setItems] = useState([]);
  const [setsSearch, setSetsSearch] = useState('');
  const [file, setFile] = useState(null);

  const [selectedSet, selectSet] = useState(null);
  const [statusFilter, setStatusFilter] = useState({
    [setPositionOptions.AVAILABLE.value]: true,
    [setPositionOptions.ALLOCATED.value]: true,
    [setPositionOptions.QUARANTINED.value]: true,
    [setPositionOptions.CONSIGNED.value]: true,
  });

  const [quarantineLoading, setQuarantineLoading] = useState(false);
  const [consignLoading, setConsignLoading] = useState(false);
  const [fetching, setFetching] = useState(false);
  const [resetLoading, setResetLoading] = useState(false);

  const [quarantineModal, toggleQuarantineModal] = useState(false);
  const [availableModal, toggleAvailableModal] = useState(false);
  const [consignModal, toggleConsignModal] = useState(false);

  const { loading, startLoading, stopLoading } = useLoading();
  const { showAlert } = useAlert();

  useEffect(() => {
    const unsubscribe = dispatch(subscribeToSets());
    const unsubscribeToLabels = dispatch(subscribeToSetLabels());

    onLoad().catch((err) => console.error(err));

    return () => {
      unsubscribe();
      unsubscribeToLabels();
    };
  }, []);

  useEffect(() => {
    if (file && selectedSet) {
      onUploadDocument(file).catch(console.error);
    }
  }, [file]);

  const onUploadDocument = async (document) => {
    const consignmentDocument = await dispatch(uploadDocument(selectedSet.id, document));
    if (consignmentDocument) {
      selectSet((prevState) => ({ ...prevState, consignmentDocument }));
      setFile(null);
    }
  };

  const onLoad = async () => {
    startLoading()
    try {
      await Promise.all([
        loadCases(),
        dispatch(getKits()),
        dispatch(getSurgeons()),
        // dispatch(getHospitals()),
        // dispatch(getItems()),
      ]);
    } catch (err) {
      console.error(err);
    } finally {
      stopLoading();
    }
  };

  const loadCases = async () => {
    const resp = await dispatch(getCasesWithSetsAllocation());
    setCases(resp.filter((item) => !!item.setsAllocation.length));
  };

  const onSelectSet = async (set) => {
    setFetching(true);

    try {
      const res = await dispatch(getSections(set.kit));
      const arr = [];
      res?.forEach((section) => {
        section?.items?.forEach((item) => {
          const _item = itemsList?.find((i) => i.id === item.id);
          if (!arr?.find((i) => i.id === item.id)) {
            arr?.push({ ...item, batchControl: !!_item?.batchControl });
          }
        })
      });
      setItems(arr);
      selectSet({
        ...set,
        billOfMaterial: !!res?.length,
        batchItemsLength: arr?.filter((item) => !!item.batchControl)?.length,
      });
    } catch (err) {
      console.error(err);
    } finally {
      setFetching(false);
    }
  }

  const onStatusFilterChange = (key) => {
    setStatusFilter({ ...statusFilter, [key]: !statusFilter[key] });
  };

  const onViewClick = (caseId) => {
    dispatch(setCaseView('SETS'));
    history.push(`${routes.CASES}/${caseId}`);
  };

  const onQuarantineClick = () => {
    toggleQuarantineModal(true);
  };

  const onAvailableClick = () => {
    toggleAvailableModal(true);
  };

  const onSubmit = (items, note) => {
    setQuarantineLoading(true);
    try {
      const doc = { quarantined: true, quarantineNote: note, quarantineItems: items };
      dispatch(updateSet(selectedSet?.id, doc));
      selectSet({
        ...selectedSet,
        position: setPositionOptions.QUARANTINED.value,
        quarantineNote: note,
        quarantineItems: items,
      });
      toggleQuarantineModal(false);
    } catch (err) {
      console.error(err);
    } finally {
      setQuarantineLoading(false);
    }
  };

  const onConsignSubmit = (hospital, location, period, note) => {
    setConsignLoading(true);
    try {
      const doc = {
        consigned: true,
        consignment: {
          hospital: hospital.id,
          location: location || '',
          period: period || null,
          note: note || '',
          consignedDate: moment(),
          reviewDate: moment().add(period, 'month')
        },
      };
      dispatch(updateSet(selectedSet?.id, doc));
      selectSet({
        ...selectedSet,
        position: setPositionOptions.CONSIGNED.value,
        consignment: {
          hospital: hospital.id,
          location: location || '',
          period: period || null,
          note: note || '',
        },
      });
      toggleConsignModal(false);
      showAlert('success', `Kit consigned to ${hospital.name}`);
    } catch (err) {
      console.error(err);
    } finally {
      setConsignLoading(false);
    }
  };

  const onMakeAvailable = () => {
    setQuarantineLoading(true);
    try {
      const doc = {
        quarantined: false,
        consigned: false,
        quarantineNote: '',
        quarantineItems: [],
        consignment: null,
      };
      dispatch(updateSet(selectedSet?.id, doc));
      selectSet({
        ...selectedSet,
        position: setPositionOptions.AVAILABLE.value,
        quarantined: false,
        consigned: false,
        quarantineNote: '',
        quarantineItems: [],
        consignment: null,
      });
      toggleAvailableModal(false);
    } catch (err) {
      console.error(err);
    } finally {
      setQuarantineLoading(false);
    }
  };

  const list = useMemo(() => (
    sets.filter((set) => {
      let position = set.caseAllocation ? setPositionOptions.ALLOCATED.value : setPositionOptions.AVAILABLE.value;

      if (set.consigned) {
        position = setPositionOptions.CONSIGNED.value;
      }

      if (set.quarantined) {
        position = setPositionOptions.QUARANTINED.value;
      }

      return !!statusFilter[position];
    })
  ), [sets, statusFilter]);

  const onLabelsChange = (labels) => {
    dispatch(updateSet(selectedSet?.id, { labels }));
    selectSet({
      ...selectedSet,
      labels
    });
  };

  const onResetTarget = async (setId, note) => {
    setResetLoading(true);
    try {
      await dispatch(resetTargetTurns(setId, note));
      selectSet({ ...selectedSet, targetTurns: 0 });
    } catch (err) {
      console.error(err);
    } finally {
      setResetLoading(false);
    }
  };

  return (
    <div className='page-container sets-page__container'>
      {(loading || !itemsLoaded) && <LoadScreen /> }

      <div className='d-flex space-between'>
        <div className='d-flex'>
          <div className='page-title'>
            Sets
          </div>
        </div>
      </div>

      <div className='sets-page__body'>
        <div>
          <div className='sets-page-filters'>
            <FilterButton
              status={setPositionOptions.AVAILABLE.value}
              value={statusFilter[setPositionOptions.AVAILABLE.value]}
              onClick={onStatusFilterChange}
            />
            <FilterButton
              status={setPositionOptions.ALLOCATED.value}
              value={statusFilter[setPositionOptions.ALLOCATED.value]}
              onClick={onStatusFilterChange}
            />
            <FilterButton
              status={setPositionOptions.QUARANTINED.value}
              value={statusFilter[setPositionOptions.QUARANTINED.value]}
              onClick={onStatusFilterChange}
            />
            <FilterButton
              status={setPositionOptions.CONSIGNED.value}
              value={statusFilter[setPositionOptions.CONSIGNED.value]}
              onClick={onStatusFilterChange}
            />
          </div>
          <SetsTable
            sets={list}
            kits={kits}
            cases={cases}
            hospitals={hospitals}
            tenantColor={tenantColor}
            withoutStatus
            search={setsSearch}
            checkboxes={false}
            onSelectSet={onSelectSet}
            selectedSetId={selectedSet?.id}
            labels={labels}
          />
        </div>
        <div>
          <div className='flex-1'>
            <Input
              type='search'
              placeholder='Search Set Number or Kit ID'
              value={setsSearch}
              onChange={(e) => setSetsSearch(e.target.value)}
            />
          </div>
          {selectedSet && (
            <QuarantineForm
              selectedSet={selectedSet}
              setItems={items}
              tenantColor={tenantColor}
              onViewClick={onViewClick}
              onQuarantineClick={onQuarantineClick}
              onAvailableClick={onAvailableClick}
              onConsignClick={() => toggleConsignModal(true)}
              fetching={fetching}
              items={itemsList}
              hospitals={hospitals}
              kits={kits}
              labels={labels}
              onLabelsChange={onLabelsChange}
              loading={resetLoading}
              onResetTarget={onResetTarget}
              tenantId={tenantId}
              file={file}
              setFile={setFile}
            />
          )}
        </div>
      </div>

      {selectedSet && (
        <>
          <QuarantineModal
            open={quarantineModal}
            onClose={() => toggleQuarantineModal(false)}
            onSubmit={onSubmit}
            loading={quarantineLoading}
            items={items}
            itemsList={itemsList}
          />

          <ConsignmentModal
            open={consignModal}
            onClose={() => toggleConsignModal(false)}
            onSubmit={onConsignSubmit}
            loading={consignLoading}
            hospitals={hospitals}
          />

          <ConfirmationModal
            open={availableModal}
            onClose={() => toggleAvailableModal(false)}
            onSubmit={onMakeAvailable}
            title={`Make set #${selectedSet?.number} available`}
            text='You are about to make this set available for case allocation. Any related items and quarantine notes will be removed.'
            submitText='Confirm'
            loading={quarantineLoading}
          />
        </>
      )}
    </div>
  );
};

export default SetsPage;
