import {
  GET_USAGE_CASES,
  SET_USAGE_CASES,
  SET_USAGE_ORDER,
  SET_USAGE_ORDER_BY,
  SET_USAGE_PAGE,
  SET_USAGE_ROWS_PER_PAGE,
  SET_USAGE_SEARCH,
  SET_USAGE_ACTIVE_STATUSES,
} from '../actions/actionTypes';

const initialState = {
  list: [],
  isLoading: false,
  isLoaded: false,
  order: '',
  orderBy: '',
  page: 0,
  rowsPerPage: 10,
  search: '',
  activeStatuses: {
    approve: true,
    approved: true,
    closed: false
  },
};

const usageReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_USAGE_CASES:
      return { ...state, isLoading: true };
    case SET_USAGE_CASES:
      return {
        ...state,
        list: action.cases,
        isLoaded: true,
        isLoading: false
      };
    case SET_USAGE_ORDER:
      return {
        ...state,
        order: action.value,
      };
    case SET_USAGE_ORDER_BY:
      return {
        ...state,
        orderBy: action.value,
      };
    case SET_USAGE_PAGE:
      return {
        ...state,
        page: action.value,
      };
    case SET_USAGE_ROWS_PER_PAGE:
      return {
        ...state,
        rowsPerPage: action.value,
      };
    case SET_USAGE_SEARCH:
      return {
        ...state,
        search: action.value,
      };
    case SET_USAGE_ACTIVE_STATUSES:
      return {
        ...state,
        activeStatuses: action.value,
      };
    default:
      return state;
  }
};

export default usageReducer;
