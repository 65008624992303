import React, { useState, useMemo } from 'react';
import { useHistory } from 'react-router-dom';

import CircularProgress from '@material-ui/core/CircularProgress';

import SetLabelsModal from './SetLabelsModal';
import ResetTargetModal from './ResetTargetModal';
import Button from '../../components/shared/Button';
import Input from '../../components/shared/Input';
import ModalSelectInput from '../shared/modal-select/ModalSelectInput';
import Progress from '../shared/Progress';
import FileUpload from '../shared/FileUpload';

import { setPositionOptions } from '../../constants/enums';
import routes from '../../constants/routes';

import './sets.scss';

const QuarantineForm = (props) => {
  const {
    selectedSet,
    setItems = [],
    tenantColor,
    onViewClick,
    onQuarantineClick,
    onConsignClick,
    onAvailableClick,
    fetching,
    items = [],
    hospitals = [],
    labels = [],
    kits = [],
    onLabelsChange,
    loading,
    onResetTarget,
    tenantId,
    file,
    setFile,
  } = props;

  const history = useHistory();

  const [isOpen, toggleModal] = useState(false);
  const [targetModalOpen, toggleTargetModal] = useState(false);

  const status = setPositionOptions[selectedSet?.position] || {};

  const target = useMemo(() => {
    if (!selectedSet) {
      return null;
    }
    const kit = kits?.find((k) => k.id === selectedSet.kit);
    if (!kit || !kit.target) {
      return null;
    }
    return kit.target;
  }, [kits, selectedSet.kit]);

  const value = useMemo(() => {
    if (!target || !target.value || !selectedSet || !selectedSet.targetTurns) {
      return 0;
    }
    return Math.round((selectedSet?.targetTurns / target.value) * 100);
  }, [target, selectedSet]);

  const isIncomplete = useMemo(() => {
    if (!selectedSet) {
      return false;
    }

    return setItems?.some((item) => {
      if (item?.batchControl && (!selectedSet?.batchItems || selectedSet?.batchItems[item.id] === undefined || selectedSet?.batchItems[item.id]?.length < item?.quantity)) {
        return true;
      }
      if (!item?.batchControl && (selectedSet?.itemsQuantity && selectedSet?.itemsQuantity[item.id] !== undefined && selectedSet?.itemsQuantity[item.id] < item?.quantity)) {
        return true;
      }
      return false;
    });
  }, [selectedSet, setItems]);

  if (fetching) {
    return <div className='d-flex flex-center w-100 m-t-lg'>
      <CircularProgress />
    </div>;
  }

  const getItemCode = (itemId) => {
    const item = items?.find((item) => item.id === itemId);
    return item ? `${item.code} – ${item.description}` : '';
  };

  const getHospitalName = (hospitalId) => {
    const hospital = hospitals.find((hospital) => hospital.id === hospitalId);
    return hospital ? hospital.name : '';
  };

  const getLabelNames = (labelIds = []) => {
    return labelIds.map((id) => {
      const label = labels.find((item) => item.id === id);
      return label ? label.name : '';
    })
  };

  const getKitId = (id) => {
    const kit = kits?.find((k) => k.id === id);
    return kit ? kit.kitId : '';
  }

  const getColor = (value) => {
    if (!value || value < 80) {
      return setPositionOptions.AVAILABLE.color;
    } else if (value >= 80 && value < 96) {
      return setPositionOptions.ALLOCATED.color;
    } else {
      return setPositionOptions.QUARANTINED.color;
    }
  };

  const onResetTargetClick = async (note) => {
    await onResetTarget(selectedSet.id, note);
    toggleTargetModal(false);
  };

  return (
    <div className='quarantine-form__container'>
      <div className='set-number'>
        {selectedSet?.number || ''}
      </div>

      <div className='set-field__container'>
        <div className='set-field-label'>Kit ID</div>
        <div className='set-field'>
          <div>{getKitId(selectedSet.kit)}</div>
          {/*{!!selectedSet.kit && !!selectedSet?.billOfMaterial && (*/}
          {/*  <div*/}
          {/*    className='pointer'*/}
          {/*    style={{ color: tenantColor }}*/}
          {/*    onClick={() => history.push(`${routes.BILL_OF_MATERIAL}/${selectedSet.kit}`)}*/}
          {/*  >*/}
          {/*    View BOM*/}
          {/*  </div>*/}
          {/*)}*/}
        </div>
      </div>

      <div className='set-field__container' style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div style={{ flex: 1, marginRight: 16 }}>
          <div className='set-field-label'>Position</div>
          <div className='set-field'>
            <span style={{ color: status.color }}>
              {status.label}
            </span>
            {isIncomplete && (
              <span style={{ color: setPositionOptions.INCOMPLETE.color }}>
                {setPositionOptions.INCOMPLETE.label}
              </span>
            )}
          </div>
        </div>
        <div style={{ width: '160px', marginLeft: 16 }}>
          <div className='set-field-label'>Set Turns (lifetime)</div>
          <div className='set-field'>
            {selectedSet?.turns || ''}
          </div>
        </div>
      </div>

      {selectedSet.position !== setPositionOptions?.CONSIGNED?.value && (
        <div className='set-field__container'>
          <div className='set-field-label'>Allocation</div>
          <div className='set-field'>
            {selectedSet.caseAllocation && (
              <>
                <div>{selectedSet.caseAllocation}</div>
                <div
                  className='pointer'
                  style={{ color: tenantColor }}
                  onClick={() => onViewClick(selectedSet.caseAllocation)}
                >
                  View
                </div>
              </>
            )}
          </div>
        </div>
      )}

      {selectedSet.position === setPositionOptions?.CONSIGNED?.value && (
        <>
          {selectedSet?.consignment?.hospital && (
            <div className='set-field__container'>
              <div className='set-field-label'>Location</div>
              <Input
                value={getHospitalName(selectedSet?.consignment?.hospital)}
                disabled
              />
            </div>
          )}
          {selectedSet?.consignment?.location && (
            <div className='set-field__container'>
              <div className='set-field-label'>Sub-location</div>
              <Input
                value={selectedSet?.consignment?.location}
                disabled
              />
            </div>
          )}
          {selectedSet?.consignment?.period && (
            <div className='set-field__container'>
              <div className='set-field-label'>Review Period (months)</div>
              <Input
                value={selectedSet?.consignment?.period}
                disabled
              />
            </div>
          )}
          {selectedSet?.consignment?.note && (
            <div className='set-field__container'>
              <div className='set-field-label'>Consignment note</div>
              <Input
                value={selectedSet?.consignment?.note}
                disabled
                type='textarea'
              />
            </div>
          )}
        </>
      )}

      {selectedSet.position === setPositionOptions?.QUARANTINED?.value && selectedSet.quarantineNote && (
        <div className='set-field__container'>
          <div className='set-field-label'>Quarantine note</div>
          <Input
            value={selectedSet.quarantineNote}
            disabled
            type='textarea'
          />
        </div>
      )}

      {selectedSet.position === setPositionOptions?.QUARANTINED?.value && !!selectedSet.quarantineItems?.length && (
        <div className='set-field__container'>
          <div className='set-field-label'>Related items</div>
          <div className='set-items__container'>
            {selectedSet.quarantineItems?.map((itemId) => (
              <div key={itemId}>
                {getItemCode(itemId)}
              </div>
            ))}
          </div>
        </div>
      )}

      <div className='set-field__container'>
        <div className='set-field-label'>Labels</div>
        <ModalSelectInput
          onClick={() => toggleModal(true)}
          count={selectedSet?.labels?.length}
          label='Edit Labels'
          values={getLabelNames(selectedSet?.labels)}
          icon='option'
        />
        <SetLabelsModal
          labels={labels}
          title='Set Labels'
          onChange={onLabelsChange}
          selected={selectedSet?.labels || []}
          onClose={() => toggleModal(false)}
          isOpen={isOpen}
        />
      </div>

      <div className='set-field__container'>
        <div className='set-field-label'>Item Allocation</div>
        <div className="set-field">
          <div>{`${selectedSet?.batchItemsLength || 0} batch controlled item(s)`}</div>
          {!!selectedSet.kit && !!selectedSet?.billOfMaterial && selectedSet.position !== setPositionOptions.ALLOCATED.value && (
            <div
              className="pointer"
              style={{ color: tenantColor }}
              onClick={() => history.push(`${routes.SETS}/${selectedSet.id}${routes.BATCH_CONTROL}`)}
            >
              Replenish
            </div>
          )}
        </div>
      </div>

      {selectedSet.position === setPositionOptions?.CONSIGNED?.value && (
        <div className='set-field__container'>
          <div className='set-field-label'>Consignment Agreement (.pdf or .docx)</div>
          <FileUpload
            id='consignment-document'
            file={file}
            onUpload={setFile}
            placeholder={file?.name || selectedSet?.consignmentDocument?.fileName}
            downloadUrl={selectedSet?.consignmentDocument?.downloadUrl}
            accept='pdfAndDoc'
            actionLabel={selectedSet?.consignmentDocument?.downloadUrl ? 'Change' : 'Add'}
          />
        </div>
      )}

      {target && (
        <div className='set-field__container' style={{ position: 'relative' }}>
          <div className='d-flex space-between'>
            <div className='set-field-label'>{target.name}</div>
            <div
              className='font-bold'
              style={{ color: getColor(value) }}
            >
              {selectedSet?.targetTurns > 0 ? `${value}%` : '0%'}
            </div>
          </div>
          <div className='m-t-md m-b-lg'>
            <Progress
              value={value > 100 ? 100 : value}
              variant='determinate'
              bgcolor={getColor(value)}
            />
          </div>
          <Input
            value={target.note}
            disabled
            type='textarea'
          />
          {!!value && value >= 100 && (
            <div
              style={{ position: 'absolute', bottom: 12, right: 16, color: setPositionOptions.QUARANTINED.color }}
              className='pointer font-bold'
              onClick={() => toggleTargetModal(true)}
            >
              Reset Target
            </div>
          )}

          <ResetTargetModal
            open={targetModalOpen}
            onClose={() => toggleTargetModal(false)}
            onSubmit={onResetTargetClick}
            loading={loading}
            tenantId={tenantId}
            tenantColor={tenantColor}
            setId={selectedSet?.id}
          />
        </div>
      )}

      <div className='quarantine-form__actions'>
        {[setPositionOptions?.QUARANTINED?.value, setPositionOptions?.CONSIGNED?.value].includes(selectedSet.position) && (
          <>
            <div />
            <Button
              type='submit'
              text='Make Available'
              onClick={onAvailableClick}
              background={setPositionOptions.AVAILABLE.color}
              disabled={!!selectedSet?.caseAllocation}
            />
          </>
        )}
        {![setPositionOptions?.QUARANTINED?.value, setPositionOptions?.CONSIGNED?.value].includes(selectedSet.position) && (
          <>
            <Button
              type='submit'
              disabled={selectedSet?.position !== setPositionOptions.AVAILABLE.value}
              text='Consign'
              onClick={onConsignClick}
              background={setPositionOptions.CONSIGNED.color}
            />
            <Button
              type='submit'
              disabled={selectedSet?.position !== setPositionOptions.AVAILABLE.value}
              text='Quarantine'
              onClick={onQuarantineClick}
              background={setPositionOptions.QUARANTINED.color}
            />
          </>
        )}
      </div>
    </div>
  )
};

export default QuarantineForm;
