// Sign up actions
export const SET_FORM_STATE = 'SET_FORM_STATE';
export const RESET_FORM = 'RESET_FORM';

// Current user actions
export const LOAD_USER_DATA = 'LOAD_USER_DATA';
export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const SIGN_OUT = 'SIGN_OUT';

// Tenant actions
export const LOAD_TENANT_DATA = 'LOAD_TENANT_DATA';
export const SET_TENANT = 'SET_TENANT';
export const SET_TENANT_STORAGE = 'SET_TENANT_STORAGE';
export const SET_TENANTS = 'SET_TENANTS';

// Users actions
export const GET_USERS = 'GET_USERS';
export const SET_USERS = 'SET_USERS';
export const SET_CONNECTED_USERS = 'SET_CONNECTED_USERS';

// Groups actions
export const GET_GROUPS = 'GET_GROUPS';
export const SET_GROUPS = 'SET_GROUPS';

// Procedures actions
export const GET_PROCEDURES = 'GET_PROCEDURES';
export const SET_PROCEDURES = 'SET_PROCEDURES'
export const SET_PROCEDURE_OPTIONS = 'SET_PROCEDURE_OPTIONS';

// Products actions
export const GET_PRODUCTS = 'GET_PRODUCTS';
export const SET_PRODUCTS = 'SET_PRODUCTS';

// Hospitals actions
export const GET_HOSPITALS = 'GET_HOSPITALS';
export const SET_HOSPITALS = 'SET_HOSPITALS';

// Kits actions
export const GET_KITS = 'GET_KITS';
export const SET_KITS = 'SET_KITS';
export const GET_SECTIONS = 'GET_SECTIONS';
export const SET_SECTIONS = 'SET_SECTIONS';
export const SET_MANUFACTURERS = 'SET_MANUFACTURERS';

// Surgeons
export const GET_SURGEONS = 'GET_SURGEONS';
export const SET_SURGEONS = 'SET_SURGEONS';
export const SET_QUALIFICATIONS = 'SET_QUALIFICATIONS';

// Cases
export const GET_CASES = 'GET_CASES';
export const SET_CASES = 'SET_CASES';
export const TOGGLE_SHIPPED = 'TOGGLE_SHIPPED';
export const TOGGLE_RETURNED = 'TOGGLE_RETURNED';
export const TOGGLE_CLOSED = 'TOGGLE_CLOSED';
export const TOGGLE_CANCELED = 'TOGGLE_CANCELED';
export const TOGGLE_CONSIGNMENT = 'TOGGLE_CONSIGNMENT';
export const TOGGLE_LOAN = 'TOGGLE_LOAN';
export const SET_FILTERS = 'SET_FILTERS';
export const SET_STATUS_FILTERS = 'SET_STATUS_FILTERS';
export const SET_USAGE_FILTERS = 'SET_USAGE_FILTERS';
export const SET_ORDER = 'SET_ORDER';
export const SET_ORDER_BY = 'SET_ORDER_BY';
export const SET_PAGE = 'SET_PAGE';
export const SET_ROWS_PER_PAGE = 'SET_ROWS_PER_PAGE';
export const SET_SEARCH = 'SET_SEARCH';

// Case
export const GET_CASE = 'GET_CASE';
export const SET_CASE = 'SET_CASE';
export const GET_CASE_NOTES = 'GET_CASE_NOTES';
export const SET_CASE_NOTES = 'SET_CASE_NOTES';
export const SET_CASE_USAGE_NOTES = 'SET_CASE_USAGE_NOTES';
export const GET_CASE_USAGE = 'GET_CASE_USAGE';
export const SET_CASE_USAGE = 'SET_CASE_USAGE';
export const GET_CASE_DOCUMENTS = 'GET_CASE_DOCUMENTS';
export const SET_CASE_DOCUMENTS = 'SET_CASE_DOCUMENTS';
export const GET_CASE_SETS_ALLOCATION = 'GET_CASE_SETS_ALLOCATION';
export const SET_CASE_SETS_ALLOCATION = 'SET_CASE_SETS_ALLOCATION';
export const SET_OVERDUE_SETS_NUMBER = 'SET_OVERDUE_SETS_NUMBER';
export const GET_CASE_ACTIVITY = 'GET_CASE_ACTIVITY';
export const SET_CASE_ACTIVITY = 'SET_CASE_ACTIVITY';
export const GET_CASE_FLOW = 'GET_CASE_FLOW';
export const SET_CASE_FLOW = 'SET_CASE_FLOW';
export const GET_CASE_CHECKLISTS = 'GET_CASE_CHECKLISTS';
export const SET_CASE_CHECKLISTS = 'SET_CASE_CHECKLISTS';
export const CLEAR_CASE = 'CLEAR_CASE';
export const SET_VIEW = 'SET_VIEW';
export const SET_STEP = 'SET_STEP';
export const SET_CASE_FORMS = 'SET_CASE_FORMS';
export const SET_CASE_PROFORMA = 'SET_CASE_PROFORMA';

// Shipping Page
export const GET_SHIPPING_CASES = 'GET_SHIPPING_CASES';
export const SET_SHIPPING_CASES = 'SET_SHIPPING_CASES';
export const SET_SHIPPING_ORDER = 'SET_SHIPPING_ORDER';
export const SET_SHIPPING_ORDER_BY = 'SET_SHIPPING_ORDER_BY';
export const SET_SHIPPING_PAGE = 'SET_SHIPPING_PAGE';
export const SET_SHIPPING_ROWS_PER_PAGE = 'SET_SHIPPING_ROWS_PER_PAGE';
export const SET_SHIPPING_SEARCH = 'SET_SHIPPING_SEARCH';
export const SET_SHIPPING_ACTIVE_TAB = 'SET_SHIPPING_ACTIVE_TAB';

// Usage Page
export const GET_USAGE_CASES = 'GET_USAGE_CASES';
export const SET_USAGE_CASES = 'SET_USAGE_CASES';
export const SET_USAGE_ORDER = 'SET_USAGE_ORDER';
export const SET_USAGE_ORDER_BY = 'SET_USAGE_ORDER_BY';
export const SET_USAGE_PAGE = 'SET_USAGE_PAGE';
export const SET_USAGE_ROWS_PER_PAGE = 'SET_USAGE_ROWS_PER_PAGE';
export const SET_USAGE_SEARCH = 'SET_USAGE_SEARCH';
export const SET_USAGE_ACTIVE_STATUSES = 'SET_USAGE_ACTIVE_STATUSES';

// Drive
export const GET_DRIVE_FOLDERS = 'GET_DRIVE_FOLDERS';
export const SET_DRIVE_FOLDERS = 'SET_DRIVE_FOLDERS';

// Tasks
export const GET_TASKS = 'GET_TASKS';
export const SET_TASKS = 'SET_TASKS';

// Sets
export const GET_SETS = 'GET_SETS';
export const SET_SETS = 'SET_SETS';
export const SET_QUARANTINED_SETS = 'SET_QUARANTINED_SETS';
export const SET_LABELS = 'SET_LABELS';
export const UPDATE_SET_ALLOCATION = 'UPDATE_SET_ALLOCATION';

// Counts
export const GET_COUNTS = 'GET_COUNTS';
export const SET_COUNTS = 'SET_COUNTS';

// Count
export const GET_COUNT = 'GET_COUNT';
export const SET_COUNT = 'SET_COUNT';
export const GET_COUNT_NOTES = 'GET_COUNT_NOTES';
export const SET_COUNT_NOTES = 'SET_COUNT_NOTES';
export const GET_COUNT_SCANS = 'GET_COUNT_SCANS';
export const SET_COUNT_SCANS = 'SET_COUNT_SCANS';
export const CLEAR_COUNT = 'CLEAR_COUNT';

// Notifications
export const GET_NOTIFICATIONS = 'GET_NOTIFICATIONS';
export const SET_NOTIFICATIONS = 'SET_NOTIFICATIONS';
export const SHOW_NOTIFICATION = 'SHOW_NOTIFICATION';
export const CLOSE_NOTIFICATION = 'CLOSE_NOTIFICATION';
export const SET_LAST_DOC_TIMESTAMP = 'SET_LAST_DOC_TIMESTAMP';

// GTIN reference
export const GET_GTIN_REFERENCE = 'GET_GTIN_REFERENCE';
export const SET_GTIN_REFERENCE = 'SET_GTIN_REFERENCE';

// Flows actions
export const GET_FLOWS = 'GET_FLOWS';
export const SET_FLOWS = 'SET_FLOWS';

// Items actions
export const GET_ITEMS = 'GET_ITEMS';
export const SET_ITEMS = 'SET_ITEMS';
export const CREATE_ITEM = 'CREATE_ITEM';
export const UPDATE_ITEM = 'UPDATE_ITEM';
export const UPLOAD_ITEMS = 'UPLOAD_ITEMS';
export const BULK_UPDATE_ITEMS = 'BULK_UPDATE_ITEMS';

// Repeating counts
export const GET_REPEATING_COUNTS = 'GET_REPEATING_COUNTS';
export const SET_REPEATING_COUNTS = 'SET_REPEATING_COUNTS';

// Forms actions
export const GET_FORMS = 'GET_FORMS';
export const SET_FORMS = 'SET_FORMS';
export const SET_NEW_FORMS_NUMBER = 'SET_NEW_FORMS_NUMBER';

// Territories actions
export const GET_TERRITORIES = 'GET_TERRITORIES';
export const SET_TERRITORIES = 'SET_TERRITORIES';

// Tags actions
export const GET_TAGS = 'GET_TAGS';
export const SET_TAGS = 'SET_TAGS';

// Kit preferences actions
export const GET_KIT_PREFERENCES = 'GET_KIT_PREFERENCES';
export const SET_KIT_PREFERENCES = 'SET_KIT_PREFERENCES';